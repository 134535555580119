import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import FilterListTwoToneIcon from "@mui/icons-material/FilterListTwoTone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import * as actions from '../../../Redux/actions';


import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import {
  GET_FILTER_OPTIONS_CARD_DASHBOARD,
  GET_GRAPH_CARD_DASHBOARD_DATA,
  GET_CARD_TRANSACTIONS_SUMMARY,
  GET_ERROR_DETAILS_CARD,
  GET_CARD_TRANSACTION_DASHBOARD,
} from "../../../../config/endpoints";
import { call, logoutAfterError } from "../../../../config/axios";
import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";
import CardDashboardFilter from "./CardDashboardFilter";
import Chart from "./Chart";
import CardStatus from "./CardStatus";
import TransactionSummary from "./DashboardTabs";
import { Button } from "@mui/material";
import { cardDashboardStatusGroup } from "../../global/constant";

const tabMapping = [
  {
    name: "Aggregate",
    value: 1,
  },
  {
    name: "Routing Service",
    value: 2,
  },
  {
    name: "Merchant",
    value: 3,
  },
  {
    name: "Acquirer",
    value: 4,
  },
  {
    name: "Card Scheme",
    value: 5,
  },
  {
    name: "Country",
    value: 6,
  },
  {
    name: "Traffic",
    value: 7,
  },
];

const CardDashboard = (props) => {
  const base_url = useSelector((state) => state.config.api_url);
  const email = localDb.getVal("email");

  const [filterShow, setFilterShow] = useState(false);
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
  );
  const [toDate, setToDate] = useState(
    moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
  );
  const [mainTab, setMainTab] = React.useState(0);
  const [summaryTab, setSummaryTab] = React.useState(0);
  const [routingServices, setRoutingServices] = useState([]);
  const [selectedRoutingServices, setSelectedRoutingServices] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchants, setSelectedMerchants] = useState([]);
  const [acquirers, setAcquirers] = useState([]);
  const [selectedAcquirers, setSelectedAcquirers] = useState([]);
  const [cardSchemas, setCardSchemas] = useState([]);
  const [selectedCardSchemas, setSelectedCardSchemas] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [statusesGroup, setStatusesGroup] = useState(cardDashboardStatusGroup);
  const [selectedStatusesGroup, setSelectedStatusesGroup] = useState([]);
  const [errors, setErrors] = useState([]);
  const [selectedErrors, setSelectedErrors] = useState([]);
  const [dateSelected, setDateSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [graphData, setGraphData] = useState({
    dataSets: [],
  });
  const [routingServicesData, setRoutingServicesData] = useState([]);
  const [aggregateSummaryData, setAggrehateSummaryData] = useState({
    averageTransactionValue: "3.8398058252427183",
    maxTransactionValue: "42",
    minTransactionValue: "0.01",
  });
  const [merchantSummaryData, setMerchantSummaryData] = useState([]);
  const [cardSchemeSummaryData, setCardSchemeSummaryData] = useState([]);
  const [countrySummaryData, setCountrySummaryData] = useState([]);
  const [acquirerSummaryData, setAcquirerSummaryData] = useState([]);
  const [errorDetails, setErrorDetails] = useState([]);
  const [dataTransaction, setDataTransaction] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [trafficSummaryData, setTrafficSummaryData] = useState([]);
  const [total, setTotal] = useState(0);
  const defaultStatuses = ["Completed", "In process", "Rejected"];
  const dispatch = useDispatch()
  const fetchData = async () => { 
    try {
      setLoading(true);
      const res = await call(
        {
          ...GET_FILTER_OPTIONS_CARD_DASHBOARD,
          url: base_url + GET_FILTER_OPTIONS_CARD_DASHBOARD.url,
        },
        { email, paymentProviderId : localStorage.getItem("paymentProviderId") || null },
      );
      const data = res.data;
      setRoutingServices(data?.routingId || []);
      setAcquirers(data?.acquirer || []);
      setMerchants(data?.merchant || []);
      setCardSchemas(data?.cardScheme || []);
      setCountries(data?.billingCountry || []);
      setStatuses(data?.status || []);
      setErrors(data?.error || []);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error fetching filter options", err);
      logoutAfterError(err);
    }
  };

  const getGraphData = async () => {
    try {
      const payload = {merchant:
        selectedMerchants?.length > 0
          ? selectedMerchants?.map((merchant) => merchant?._id)
          : "All",
      routingService:
        selectedRoutingServices?.length > 0
          ? selectedRoutingServices?.map((service) => service?.routingId)
          : "All",
      acquirer: selectedAcquirers?.length > 0 ? selectedAcquirers : "All",
      paymentProviderId : localDb?.getVal('paymentProviderId') || null,
      cardScheme:
        selectedCardSchemas?.length > 0 ? selectedCardSchemas : "All",
      country: selectedCountries?.length > 0 ? selectedCountries : "All",
      status: selectedStatuses?.length > 0 ? selectedStatuses : "All",
      error: selectedErrors?.length > 0 ? selectedErrors : "All",
      startDate: fromDate,
      endDate: toDate,
      statusGroup : selectedStatusesGroup?.length > 0 ?  selectedStatusesGroup : "All",
      }
      const res = await call(
        {
          ...GET_GRAPH_CARD_DASHBOARD_DATA,
          url: base_url + GET_GRAPH_CARD_DASHBOARD_DATA.url,
        },
        { email, ...payload },
      );
      setGraphData(res.data);
    } catch (error) {
      console.error("Error fetching graph data", error);
    }
  };

  const getSummary = async () => {
    try {
      setLoading(true);
      const payload = {
        tab: tabMapping[summaryTab].name,
        merchant:
          selectedMerchants?.length > 0
            ? selectedMerchants.map((merchant) => merchant._id)
            : "All",
        paymentProviderId : localStorage.getItem('paymentProviderId') || null,
        routingService:
          selectedRoutingServices.length > 0
            ? selectedRoutingServices.map((service) => service.routingId)
            : "All",
        acquirer: selectedAcquirers.length > 0 ? selectedAcquirers : "All",
        cardScheme:
          selectedCardSchemas.length > 0 ? selectedCardSchemas : "All",
        country: selectedCountries.length > 0 ? selectedCountries : "All",
        status: selectedStatuses.length > 0 ? selectedStatuses : "All",
        error: selectedErrors.length > 0 ? selectedErrors : "All",
        startDate: fromDate,
        endDate: toDate,
        statusGroup : selectedStatusesGroup.length > 0 ?  selectedStatusesGroup : "All",
      };
      const response = await call(
        {
          ...GET_CARD_TRANSACTIONS_SUMMARY,
          url: base_url + GET_CARD_TRANSACTIONS_SUMMARY.url,
        },
        { email, ...payload },
      );
      setLoading(false);
      const { data } = response;
      const tabSummary = {
        "Aggregate": setAggrehateSummaryData,
        "Merchant": setMerchantSummaryData,
        "Routing Service": setRoutingServicesData,
        "Acquirer": setAcquirerSummaryData,
        "Card Scheme": setCardSchemeSummaryData,
        "Country": setCountrySummaryData,
        "Traffic": setTrafficSummaryData,
      };
      const setTabSummaryFunction = tabSummary[tabMapping[summaryTab]?.name];
      if(tabMapping[summaryTab].name === "Aggregate"){
        setTabSummaryFunction(data);
      }
      else if (setTabSummaryFunction) {
        setTabSummaryFunction(data?.dataSets);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching summary", error);
    }
  };

  const getTransactionData = async () => {
    try {
      setLoading(true);
      const payload = {
        tab: tabMapping[summaryTab].name,
        merchant:
          selectedMerchants?.length > 0
            ? selectedMerchants.map((merchant) => merchant._id)
            : "All",
        routingService:
          selectedRoutingServices.length > 0
            ? selectedRoutingServices.map((service) => service.routingId)
            : "All",
        acquirer: selectedAcquirers.length > 0 ? selectedAcquirers : "All",
        paymentProviderId : localStorage.getItem('paymentProviderId') || null,
        cardScheme:
          selectedCardSchemas.length > 0 ? selectedCardSchemas : "All",
        country: selectedCountries.length > 0 ? selectedCountries : "All",
        status: selectedStatuses.length > 0 ? selectedStatuses : "All",
        error: selectedErrors.length > 0 ? selectedErrors : "All",
        startDate: fromDate,
        endDate: toDate,
        statusGroup : selectedStatusesGroup.length > 0 ?  selectedStatusesGroup : "All",
      };
      const response = await call(
        {
          ...GET_CARD_TRANSACTION_DASHBOARD,
          url: `${base_url + GET_CARD_TRANSACTION_DASHBOARD.url}?page=${page}&limit=${10}`,
        },
        { email, ...payload },
      );
      setLoading(false);
      setDataTransaction(response?.data?.transactions);
      setTotal(response?.data?.count)
    } catch (error) {
      setLoading(false);
      console.error("Error fetching summary", error);
    }
  };

  const getErrorDetails = async (req, res) => {
    try {
      setLoading(true);
      const payload = {
        tab: tabMapping[summaryTab].name,
        paymentProviderId : localStorage.getItem('paymentProviderId') || null,
        merchant:
          selectedMerchants?.length > 0
            ? selectedMerchants.map((merchant) => merchant._id)
            : "All",
        routingService:
          selectedRoutingServices.length > 0
            ? selectedRoutingServices.map((service) => service.routingId)
            : "All",
        acquirer: selectedAcquirers.length > 0 ? selectedAcquirers : "All",
        cardScheme:
          selectedCardSchemas.length > 0 ? selectedCardSchemas : "All",
        country: selectedCountries.length > 0 ? selectedCountries : "All",
        status: selectedStatuses.length > 0 ? selectedStatuses : "All",
        error: selectedErrors.length > 0 ? selectedErrors : "All",
        startDate: fromDate,
        endDate: toDate,
        statusGroup : selectedStatusesGroup.length > 0 ?  selectedStatusesGroup : "All",
      };
      const response = await call(
        {
          ...GET_ERROR_DETAILS_CARD,
          url: base_url + GET_ERROR_DETAILS_CARD.url,
        },
        { email, ...payload },
      );
      setLoading(false);
      setErrorDetails(response.data);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching summary", error);
    }
  };

  const generateFilterURL = (service, tabType) => {
    dispatch(actions.setSelectedMethod(["card","Card"]))
    dispatch(actions.setSelectedType(["Deposit", "deposit"]))
    if (fromDate)  dispatch(actions.setFromDate(fromDate))
    if (toDate) dispatch(actions.setToDate(toDate))
    if (selectedAcquirers.length > 0) dispatch(actions.setSelectedAcquirer(selectedAcquirers))
    if (selectedCountries.length > 0) dispatch(actions.setSelectedIssuerCountryList(selectedCountries))
    if (selectedStatuses.length > 0) dispatch(actions.setSelectedStatusList(selectedStatuses))
    if (selectedStatusesGroup.length > 0) dispatch(actions.setSelectedStatusGroupList(selectedStatusesGroup))
    return `/transactions`;
  };
  const baseUrl = "/transactions";
  const initialParams = {
    method: "card",
    statusgroup: 
    selectedStatusesGroup?.length > 0
    ? JSON.stringify(
      selectedStatusesGroup?.map((service) => service),
      )
    : statusesGroup,
    fromDate: moment(fromDate),
    toDate: moment(toDate),
    routingService: selectedRoutingServices?.length > 0
    ? JSON.stringify(
      selectedRoutingServices?.map((service) => service?.routingId),
      )
    : "All",
    merchantName: selectedMerchants?.length > 0
    ? JSON.stringify(
        selectedMerchants?.map((service) => ({merchantId: service?.merchantId})),
      )
    : "All",
    acquirer: selectedAcquirers?.length > 0
    ? JSON.stringify(selectedAcquirers?.map((service) => service))
    : "All",
    cardScheme: selectedCardSchemas?.length > 0
    ? JSON.stringify(selectedCardSchemas?.map((service) => service))
    : "All",
    issuerCountry: selectedCountries?.length > 0
    ? JSON.stringify(
        selectedCountries?.map((service) => ({ name: service })),
      )
    : "All",
    errorDetails: selectedErrors?.length > 0
    ? JSON.stringify(selectedErrors?.map((service) => service))
    : "All"
  };
  
  const updateQueryParams = (newParams) => {
    const updatedParams = { ...initialParams, ...newParams };
  
    const queryString = Object.keys(updatedParams)
      .map(key => `${(key)}=${(updatedParams[key])}`)
      .join('&');
  
    return `${baseUrl}?${queryString}`;
  };
  
  useEffect(() => {
    fetchData();
    getGraphData();
  }, []);
  useEffect(() => {
    if (mainTab === 1) {
      getErrorDetails();
    } else if (mainTab === 0) {
      getSummary();
    } else {
      getTransactionData();
    }
  }, [summaryTab, mainTab]);

  useMemo(()=>{
    getTransactionData();
  },[page])

  const handleSave = () => {
    getGraphData();
    getSummary();
    if (mainTab === 1) {
      getErrorDetails();
    }
  };

  const handleFilterDropDown = () => {
    setFilterShow(!filterShow);
  };
  const dataSets = graphData?.dataSets && graphData?.dataSets.length > 0 && graphData?.dataSets || defaultStatuses.map(status => ({ status, total: 0 }));
  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
        <div className="card_dashboard_index">
          <div className="card_dashboard_index__row1 mb-4">
            <div className="card_dashboard_index__left">
              <div className="card_dashboard_index__title">Dashboard</div>
            </div>
            <Button
              variant="contained"
              onClick={handleFilterDropDown}
              startIcon={<FilterListTwoToneIcon className="text-black" />}
              endIcon={
                filterShow ? (
                  <KeyboardArrowUpIcon className="text-black" />
                ) : (
                  <KeyboardArrowDownIcon className="text-black" />
                )
              }
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
                backgroundColor: "#F2F4F7",
                textTransform: "none",
                fontSize: "1rem", // text-xs equivalent in MUI
                '&:hover': {
                  backgroundColor: '#DDE0E4', // Hover color
                },
              }}
            >
              <div className="flex items-center gap-2">
                <div className="text-black">Filters</div>
              </div>
            </Button>
          </div>

          {filterShow && (
            <div className="card_dashboard_index__row2">
              <CardDashboardFilter
                routingServices={routingServices}
                selectedRoutingServices={selectedRoutingServices}
                setSelectedRoutingServices={setSelectedRoutingServices}
                merchants={merchants}
                selectedMerchants={selectedMerchants}
                setSelectedMerchants={setSelectedMerchants}
                acquirers={acquirers}
                selectedAcquirers={selectedAcquirers}
                setSelectedAcquirers={setSelectedAcquirers}
                cardSchemas={cardSchemas}
                selectedCardSchemas={selectedCardSchemas}
                setSelectedCardSchemas={setSelectedCardSchemas}
                countries={countries}
                selectedCountries={selectedCountries}
                setSelectedCountries={setSelectedCountries}
                statuses={statuses}
                selectedStatuses={selectedStatuses}
                setSelectedStatuses={setSelectedStatuses}
                errors={errors}
                statusGroups={statusesGroup}
                setStatusGroups={setStatusesGroup}
                selectedStatusGroups={selectedStatusesGroup}
                setSelectedStatusGroups={setSelectedStatusesGroup}
                selectedErrors={selectedErrors}
                setSelectedErrors={setSelectedErrors}
                fromDate={moment(fromDate)}
                toDate={moment(toDate)}
                setFromDate={setFromDate}
                setToDate={setToDate}
                setDateSelected={setDateSelected}
                dateSelected={dateSelected}
                handleSave={handleSave}
                generateFilterURL={generateFilterURL}
              />
            </div>
          )}

          <div className="card_dashboard_index__row3 mb-4 flex-col gap-4 md:flex-row">
            <div className="card_dashboard_index__stats__text flex h-full w-full flex-col md:w-[30%]">
              {dataSets &&
                dataSets.map((data, i) => (
                  <CardStatus
                    fromDate={moment(fromDate)}
                    toDate={moment(toDate)}
                    key={i}
                    percentage={data?.percentage}
                    total={data?.total}
                    status={data?.status}
                    totalSum={data?.totalSum}
                    updateQueryParams={updateQueryParams}
                  />
                ))}
            </div>
            <div className="card_dashboard_index__stats__graph w-full md:w-[70%]">
              <Chart mockData={graphData} />
            </div>
          </div>
          <TransactionSummary
            aggregateSummaryData={aggregateSummaryData}
            setAggrehateSummaryData={setAggrehateSummaryData}
            mainTab={mainTab}
            setMainTab={setMainTab}
            summaryTab={summaryTab}
            setSummaryTab={setSummaryTab}
            routingServicesData={routingServicesData}
            setRoutingServicesData={setRoutingServicesData}
            merchantSummaryData={merchantSummaryData}
            setMerchantSummaryData={setMerchantSummaryData}
            cardSchemeSummaryData={cardSchemeSummaryData}
            trafficSummaryData={trafficSummaryData}
            setTrafficSummaryData={setTrafficSummaryData}
            setCardSchemeSummaryData={setCardSchemeSummaryData}
            acquirerSummaryData={acquirerSummaryData}
            setAcquirerSummaryData={setAcquirerSummaryData}
            setCountrySummaryData={setCountrySummaryData}
            countrySummaryData={countrySummaryData}
            errorDetails={errorDetails}
            dataTransaction={dataTransaction}
            //
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={total}
            setTotalCount={setTotal}
            generateFilterURL={generateFilterURL}
            updateQueryParams={updateQueryParams}
          />
        </div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
    </div>
  );
};

export default connect(null)(CardDashboard);
