import React from "react";
import moment from "moment";
import { Table } from "reactstrap";
import EditIcon from '@mui/icons-material/Edit';
import { isViewPermissionValid } from "../../../common/CommonUtils";

const PaymentProviderUserTable = ({
  data,
  setDeletingId,
  handleShowMore,
  showMoreButton,
  handleEditUser,
  setEditData,
}) => {
  return (
    <div className="TransactionTableMain">
      {data.length > 0 ? (
        <Table responsive>
          <tbody className="TransactionTable_BODY merchant_table_text_align">
            <tr className="TransactionTable_BODY_Header">
            <th className="TransactionTable_heading">
                Providers{" "}
              </th>
              <th className="TransactionTable_heading">
                Full Name{" "}
              </th>
              <th className="TransactionTable_heading">
                Email{" "}
              </th>
              <th className="TransactionTable_heading">
              Provider Contact Information{" "}
              </th>
              <th className="TransactionTable_heading">
                Created Date{" "}
              </th>
              <th
                className="TransactionTable_heading"
              >Actions{" "}</th>
            </tr>
            {data.map((TData, i) => {
              return (
                <SingleTransactionTableRow
                  data={TData}
                  key={i}
                  setDeletingId={setDeletingId}
                  handleEditUser={handleEditUser}
                  setEditData={setEditData}
                />
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="No_Transactions_Found" data-e2e="No_Transactions_Found">
          No Data Found
        </div>
      )}
      {showMoreButton ? (
        <div className="Show_more_wrap">
          <div
            className="Show_more"
            data-e2e="showMore"
            onClick={() => {
              handleShowMore();
            }}
          >
            Show More
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PaymentProviderUserTable;

const SingleTransactionTableRow = ({
  data,
  setDeletingId,
  handleEditUser,
  setEditData,
}) => {
  return (
    <tr className="CP_TableRow housekeeping-table-row">
      <td className="transactionsTable_item">
        {(`${data && data.parentUser && data.parentUser.firstName} ${data && data.parentUser && data.parentUser.lastName}`) || "N/A"}
      </td>
      <td className="transactionsTable_item">
      {(`${data && data.firstName} ${data && data.lastName}`) || "N/A"}
      </td>
      <td className="transactionsTable_item">
      {(data && data.email) || "N/A"}
      </td>
      <td className="transactionsTable_item">
        {(data && data.parentUser && data.parentUser.email) || "N/A"}
      </td>
      <td className="transactionsTable_item">
        {data && data.createdAt
        ? moment(data.createdAt).format("DD/MM/YYYY - HH:mm")
        : "N/A"}
      </td>
      <td className="transactionsTable_item" >
        <EditIcon
          fontSize="large"
          color="action"
          data-e2e="edit-button"
          className={`${isViewPermissionValid? "disabled": ""} cursor-pointer pr-3`}
          onClick={() => isViewPermissionValid? null: (
            setEditData(data),
            handleEditUser()
          )}
        />
        <i
          className={`${isViewPermissionValid? "disabled": ""} fa fa-trash deleteB2cUserIcon cursor-pointer`}
          aria-hidden="true"
          onClick={() => isViewPermissionValid? null: setDeletingId(data?._id)}
        ></i>
      </td>
    </tr>
  );
};
