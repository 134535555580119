import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { call, logoutAfterError } from '../../../../config/axios';
import {
  ADD_NOTES,
  UPDATE_NOTES,
  UPDATE_TRANSACTION,
  VIEW_SINGLE_TRANSACTION,
} from '../../../../config/endpoints';
import localDb from '../../../../localDb';
import Loading from '../../../common/Loading';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import TransactionView from './TransactionView';
import { isViewPermissionValid } from '../../../common/CommonUtils';


const change_transaction = '/cryptonpay/crytoicons/change_transaction.png';
const cross = '/cryptonpay/crytoicons/cross.png';
const tick_green = '/cryptonpay/crytoicons/tick_green.png';
const closeImage = '/cryptonpay/crytoicons/close.png';
const editNote = '/cryptonpay/crytoicons/edit_note.svg';
const pencilIcon = '/cryptonpay/crytoicons/pencil.svg';

function TransactionDetails() {
  const [id, setId] = useState('');
  const [transId, setTransId] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [msg, setMsg] = useState('');
  const [transctionType, setTranasactionType] = useState('')
  const [toggleDropDown, setToggleDropDown] = useState(false);
  const [modalFlag, setModalFlag] = useState(false);
  const [reprocessingModal, setReprocessingModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const api_url = useSelector((state) => state.config.api_url);
  const sub_name = localDb.getVal('sub_name');
  const [loading, setLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [notes, setNotes] = useState([]);
  const [newNotes, setNewNotes] = useState('');
  const [addNote, setAddNote] = useState(false);
  const [updateNote, setUpdateNote] = useState('');
  const [updateNoteId, setUpdateNoteId] = useState('');
  const [updateNoteModal, setUpdateNoteModal] = useState(false);
  const [existingNote, setExistingNote] = useState(false);
  const [notesAdded, setNotesAdded] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [updateSuccessAlert, setUpdateSuccessAlert] = useState(false);
  const sub_name_last = `Transaction ID: ${id}`;
  const [showUpdateStatusForWithdrawal, setshowUpdateStatusForWithdrawal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const withdrawalchangeStatusList = [
    "WithdrawalFlow:ApprovedForProcessing",
    "WalletManagementService:WithdrawalCompleted",
    "WithdrawalFlow:Rejected",
    "WalletManagementService:WithdrawalRejectionCompleted"
  ]
  const changeStatusList = [
    "DepositFlow:AwaitingDeposit",
    "DepositMonitor:MonitoringMemPool",
    "DepositMonitor:DepositReceivedToMemPool",
    "DepositMonitor:RejectDeposit",
    "DepositMonitor:DepositManualApproved",
    "DepositMonitor:DepositManualRejected",
    "DepositMonitor:DepositCompleted",
    "DepositMonitor:DepositManuallyCompleted",
    "DepositMonitor:TimedOutMonitoringMemPool",
    "DepositFlow:Abandoned",
    "DepositFlow:Cancelled"
  ]
  const email = localStorage.getItem("email");
  const statusesToCheck = [
    "WalletManagementService:WithdrawalCompleted",
    "WalletManagementService:SendToRecipientWallet",
    "WalletManagementService:WithdrawalConfirmed"
  ];

  const handleId = (id) => {
    setId(id);
  };
  useEffect(() => {
    if (localStorage.getItem("setMsg") === "true") {
      setShowMsg(true);
    } else {
      setShowMsg(false);
    }
    setTimeout(() => {
      setShowMsg(false);
      localStorage.setItem("setMsg", false);
    }, 2000);
    if (status && !["WalletManagementService:WithdrawalCompleted", "WalletManagementService:WithdrawalRejectionCompleted"]
      .includes(status)) {
      setshowUpdateStatusForWithdrawal(true)
    }
  }, [status])

  useEffect(() => {
    if (notes.length > 0) {
      setNotesAdded(true)
    }
  }
    , [notes])

  const changeTransactionStatus = (status) => {
    setToggleDropDown(false)
    setStatus(status);
    setModalFlag(true)
  }

  const handleChange = (e) => {
    setMsg(e.target.value);
  };

  const handleOpenAddNote = () => {
    setAddNote(true);
  };

  const handleCloseAddNote = () => {
    setAddNote(false);
  };

  const handleCloseUpdateNote = () => {
    setUpdateNoteModal(false);
  };

  const handleOpenExistingNote = () => {
    setExistingNote(true);
  };

  const handleCloseExistingNote = () => {
    setExistingNote(false);
  };

  const closeMsg = () => {
    setShowMsg(false)
    localStorage.setItem("setMsg", false);
  }
  const updateTransactionStatus = async () => {
     let transaction;
     await call(
      {
        ...VIEW_SINGLE_TRANSACTION,
        url: api_url + VIEW_SINGLE_TRANSACTION.url,
      },
  { email: email, transactionId: transId }
    ).then((res) => {
        if (res.status === 200) {
            transaction = res.data.transaction;
        }
     });

    const containsStatus = transaction.status.history.some((status) => statusesToCheck.includes(status.code));
    if(containsStatus){
      setModalFlag(false);
      setShowConfirmModal(true);
      return
    }
    if(!isProcessing && transaction?.transactionState?.approvedForProcessing){
      setReprocessingModal(true);
      setIsProcessing(true);
      return
    }
    if (msg == "") {
      setError("Comment should be required");
      return
    } else {
      try {
        setLoading(true);
        const res = await call(
          {
            ...UPDATE_TRANSACTION,
            url: api_url + UPDATE_TRANSACTION.url,
          },
          { email, transactionId: transId, message: msg, status }
        )
        setLoading(false);
        if (res.status === 200) {
          setLoading(false);
          setModalFlag(false);
          setStatus("");
          localStorage.setItem("setMsg", true);
          window.location.reload();
        }
      } catch (err) {
        setLoading(false);
        console.log(err.message, 'TransactionDetails > updateTransactionStatus > err');
        logoutAfterError(err)
      }
    }
  };

  const updateConfirmedTransactionStatus = async () => {
    if (msg == "") {
      setError("Comment should be required");
      return
    } else {
      try {
        setLoading(true);
        const res = await call(
          {
            ...UPDATE_TRANSACTION,
            url: api_url + UPDATE_TRANSACTION.url,
          },
          { email, transactionId: transId, message: msg, status, isConfirmed: true }
        )
        setLoading(false);
        if (res.status === 200) {
          setLoading(false);
          setShowConfirmModal(false)
          setStatus("");
          localStorage.setItem("setMsg", true);
          window.location.reload();
        }
      } catch (err) {
        setLoading(false);
        console.log(err.message, 'TransactionDetails > updateTransactionStatus > err');
        logoutAfterError(err)
      }
    }
  }

  const addNotes = async () => {
    if (newNotes == "") {
      setError("Notes should be required");
      return
    } else {
      let notes = { note: newNotes, createdDate: new Date() }
      setLoading(true);
      await call(
        {
          ...ADD_NOTES,
          url: api_url + ADD_NOTES.url,
        },
        { email: localStorage.getItem("email"), transactionId: transId, notes }
      )
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            setLoading(false);
            setAddNote(false);
            setNotesAdded(true);
            setNewNotes("");
            setSuccessAlert(true);
            setTimeout(() => {
              window.location.reload();
            }, 2000);

          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message, 'TransactionDetails > addNotes > err');
          logoutAfterError(err)
        });
    }
  };

  const updateNotes = async () => {
    if (updateNote == "") {
      setError("Notes should be required");
      return
    } else {
      let notes = { note: updateNote }
      setLoading(true);
      await call(
        {
          ...UPDATE_NOTES,
          url: api_url + UPDATE_NOTES.url,
        },
        { email: localStorage.getItem("email"), transactionId: transId, updateNoteId, notes }
      )
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            setLoading(false);
            setUpdateNoteModal(false);
            setNotesAdded(true);
            setUpdateSuccessAlert(true)
            setTimeout(() => {
              window.location.reload();
            }, 2000);

          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message, 'TransactionDetails > updateNotes > err');
          logoutAfterError(err)
        });
    }
  };

  return (
    <div className="pageContainer">

      <HeaderAndFooterLayout>
				<div className="">
					<CommonHeader
						name={sub_name_last}
						backButton={true}
						view="transactions"
						fullId={useParams().id}
						setAddNote={setAddNote}
						addNote={addNote}
						copyButton={true}
					/>
				

				
					{successAlert &&
						<Stack className="position-absolute successAlert" spacing={2}>
							<Alert icon={<img src={`${image_base_url}${tick_green}`} alt="tick_green" style={{ width: '32px' }} />} onClose={() => { setSuccessAlert(false) }} className='MuiAlert-root'>Transaction comment has been added.</Alert>
						</Stack>}
					{updateSuccessAlert &&
						<Stack className="position-absolute successAlert" spacing={2}>
							<Alert icon={<img src={`${image_base_url}${tick_green}`} alt="tick_green" style={{ width: '32px' }} />} onClose={() => { setUpdateSuccessAlert(false) }} className='MuiAlert-root'>Transaction comment has been updated.</Alert>
						</Stack>}
					{transctionType === "deposit" &&
						<div className='status_drop_down'>
							<div className={`status_drop_down_box ${isViewPermissionValid? "disabled": ""}`} onClick={() => isViewPermissionValid? null: setToggleDropDown(toggleDropDown ? false : true)}>
								<div className='status_drop_down_content'>
									{status}
								</div>
								<div className='status_drop_down_arrow_box'>
									<i className="fa fa-chevron-down status_arrow" data-e2e="status-icon" />
								</div>
							</div>
							{toggleDropDown && <div className='status_drop_down_list'>
								{changeStatusList.map((stats, index) => {
									return <div data-e2e={stats} className={"status_drop_down_text"} key={index} onClick={() => changeTransactionStatus(stats)}>{stats}</div>
								})}
							</div>}
						</div>}
					{transctionType === "withdrawal" && showUpdateStatusForWithdrawal &&
						<div className='status_drop_down'>
							<div className={`status_drop_down_box ${isViewPermissionValid? "disabled": ""}`} onClick={() => isViewPermissionValid? null: setToggleDropDown(toggleDropDown ? false : true)}>
								<div className='status_drop_down_content'>
									{status}
								</div>
								<div className='status_drop_down_arrow_box'>
									<i className="fa fa-chevron-down status_arrow" data-e2e="status" />
								</div>
							</div>
							{toggleDropDown && <div className='status_drop_down_list'>
								{withdrawalchangeStatusList.map((stats, index) => {
									return <div data-e2e={stats} className={"status_drop_down_text"} key={index} onClick={() => changeTransactionStatus(stats)}>{stats}</div>
								})}
							</div>}
						</div>}
					{showMsg &&
						<div className='status_drop_down'>
							<div className='status_msg'>
								<img src={`${image_base_url}${tick_green}`} className="status_msg_tick_green" alt="logo" />
								<div className='status_msg_content'>Transaction Status has been changed successfully!</div>
								<img src={`${image_base_url}${cross}`} className="status_msg_cross" alt="logo" onClick={() => closeMsg()} />
							</div>
						</div>}
					{modalFlag && <div>
						<Modal show={true} className="change_status_modal">
							<Modal.Body className="change_status_body" >
								<div className='change_trans_header mt-4'>Change Transaction Status</div>
								<div className='change_trans_sure_want mt-4'>Are you sure you want to change transaction status? </div>
								<div className='change_trans_status_lable mt-4'>Status</div>
								<div className='change_trans_status_value mt-2'>{status}</div>
								<textarea
									data-e2e="add-note..."
									placeholder='Add note..'
									value={msg}
									name='message'
									className='change_trans_note mt-4'
									cols="40"
									rows="5"
									onChange={(e) => handleChange(e)} />
								{error != '' && <div className='add_note_error'>{error}</div>}
								{/* <input row='4' type="text"  placeholder='Add note..' name='message' /> */}
								<div className='change_trans_button mt-4' onClick={() => updateTransactionStatus()} data-e2e="change-btn">Change</div>
								<div className='change_trans_go_back mt-4' onClick={() => setModalFlag(false)} data-e2e="go-back-btn">Go Back</div>
							</Modal.Body>
						</Modal>
						{loading && <Loading />}
					</div>}
            {(showConfirmModal || reprocessingModal)  && <React.Fragment>
              <Modal show={true} className="change_status_modal">
                <Modal.Body className="change_status_body" >
                  <div className='change_trans_header mt-4'>Withdrawal has already been paid - are you sure you want to reprocess?</div>
                  <div className='change_trans_status_lable mt-4'>Status</div>
                  <div className='change_trans_status_value mt-2'>{status}</div>
                  <textarea
                      data-e2e="add-note..."
                      placeholder='Add note..'
                      value={msg}
                      name='message'
                      className='change_trans_note mt-4'
                      cols="40"
                      rows="5"
                      onChange={(e) => handleChange(e)} />
                  {error != '' && <div className='add_note_error'>{error}</div>}
                  <div className='change_trans_button mt-4' onClick={() => updateConfirmedTransactionStatus()} data-e2e="change-btn">Confirm</div>
                  <div className='change_trans_go_back mt-4' onClick={() => setShowConfirmModal(false)} data-e2e="go-back-btn">Go Back</div>
                </Modal.Body>
              </Modal>
              {loading && <Loading />}
            </React.Fragment>}

						{notesAdded &&
							<div style={{ maxWidth: "88%", margin: "auto" }} className="d-flex justify-content-end pt-4">
								<button onClick={handleOpenExistingNote} className="add-note-btn">
									<span className="mr-1"><img src={`${image_base_url}${editNote}`} alt="editNote" style={{ width: "24px" }} /></span>
									<span className="add-note-btn-text">Notes</span>
								</button>
								<button onClick={handleOpenAddNote} className="add-note-btn ml-2" style={{ width: "53px" }}>
									<i className="fa fa-plus eventLogItemExpandewr" />
								</button>
							</div>
						}
						<TransactionView handleId={handleId} setTransId={setTransId} setStatus={setStatus} setNotes={setNotes} setTranasactionType={setTranasactionType} view="transactions" />
				</div>
        


        {/* Modal for Add note */}
        <Dialog
          open={addNote}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="add_notes_mobile"
        >
          <div className="create_account_container" style={{ width: '520px' }}>
            <div className="create_account_header">
              <div
                className="filter_close_button"
                onClick={handleCloseAddNote}
              >
                <img data-e2e="close-icon" src={`${image_base_url}${closeImage}`} alt="closeImage" />
              </div>
              <img
                src={`${image_base_url}/homeb2c/add_note_logo.svg`}
                alt="image"
                className="d-block m-auto note_image"
              />
              <div className="create_account_heading">Transaction Note</div>
            </div>

            <div className="create_account_form">
              <div style={{ margin: '20px 0px' }} >
                <div >
                  <textarea name="" id="" cols="30" rows="10" className='add-note-input' data-e2e="add-note-box" placeholder='Add note...'
                    onChange={(e) => {
                      setNewNotes(e.target.value);
                    }}
                  />
                  {error != '' && <div className='add_note_error'>{error}</div>}
                  <div className='d-flex justify-content-center pt-4'>
                    <button type="button" onClick={() => addNotes()} className="btn btn-primary btn-md rounded-pill add-btn" data-e2e="add-new-btn"                   >
                      ADD
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>

        {/* Modal for Update  note */}
        <Dialog
          open={updateNoteModal}
          onClose={handleCloseUpdateNote}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="create_account_container" style={{ width: '520px' }}>
            <div className="create_account_header">
              <div onClick={() => {
                setExistingNote(true)
                setUpdateNoteModal(false)
              }}>
                <ArrowBackIcon size={24} />
              </div>
              <div className="create_account_heading">Transaction Note</div>
              <div
                className="filter_close_button"
                onClick={handleCloseUpdateNote}
              >
                <img src={`${image_base_url}${closeImage}`} alt="closeImage" />
              </div>
            </div>

            <div className="create_account_form">
              <div style={{ margin: '20px 0px' }} >
                <div >
                  <textarea name="" id="" cols="30" rows="10" className='add-note-input' placeholder='Add note...'
                    onChange={(e) => {
                      setUpdateNote(e.target.value);
                    }}
                    value={updateNote}
                  />
                  <div className='d-flex justify-content-center pt-4'>
                    <button type="button" onClick={() => updateNotes()} className="btn btn-primary btn-md rounded-pill add-btn" data-e2e="update-btn"                   >
                      UPDATE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>

        {/* Modal for existing  note */}
        <Dialog
          open={existingNote}
          onClose={handleCloseExistingNote}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="create_account_container" style={{ width: '600px' }}>
            <div className="create_account_header">
              <div></div>
              <div className="create_account_heading">Transaction Note</div>
              <div
                className="filter_close_button"
                onClick={handleCloseExistingNote}
              >
                <img src={`${image_base_url}${closeImage}`} alt="closeImage" />
              </div>
            </div>

            <div className="create_account_form">
              <div style={{ margin: '20px 0px' }} >
                <div className='' >
                  <div className='existing-note transaction_view_sub_Scroll'>
                    {
                      notes.length > 0 ?
                        notes.map((e, i) => {
                          return (
                            <div className='row mt-2 ml-2 mr-2 border-bottom pb-2' key={i}>
                              <div className="col-3 p-0" style={{ color: '#009CFF' }}> {e.createdDate ? moment(e.createdDate).format("DD/MM/YYYY") : 'N/A'} :</div>
                              <div className="col-8 p-0">{e.note}</div>
                              <div onClick={() => {
                                setExistingNote(false)
                                setUpdateNoteModal(true)
                                setUpdateNote(e.note)
                                setUpdateNoteId(e._id)
                              }} className="col-1 text-center p-0 m-0" ><img src={`${image_base_url}${pencilIcon}`} alt="pencilIcon" /></div>
                            </div>
                          )
                        }
                        ) :
                        <div className='row mt-2 ml-2 mr-2 border-bottom pb-2'>
                          <div className="col-9 p-0">No notes are there</div>
                        </div>
                    }

                  </div>

                  <div className='d-flex justify-content-center pt-4'>
                    <button type="button" onClick={handleCloseExistingNote} className="btn btn-primary btn-md rounded-pill add-btn" data-e2e="add-new-btn"                   >
                      DONE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>


        
      </HeaderAndFooterLayout>
    </div>
  );
}

export default TransactionDetails;


