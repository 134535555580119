import React from "react";
import { Modal } from "react-bootstrap";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";
const saveIcon = "/cryptonpay/crytoicons/approve.png";

const DeletingAlert = (props) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  return (
    <div className="merchant_dailog_screen">
      <Dialog
        open={props.deletingId ? true : false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
      >
        <div>
          <Modal
            show={true}
            className="ClearAlertModal deleteAlertModal ClearAlertModals"
            onHide={() => {
              props.onHide();
            }}
          >
            <Modal.Body className="ClearAlertModalBody">
              <div className="ClearAlertModalIconWrap">
                <img
                  src={`${image_base_url}${
                    props.enable ? saveIcon : deleteIcon
                  }`}
                  alt="resetIcon"
                  className="ClearAlertModalIcon"
                />
              </div>
              <div className="ClearAlertModalTitle">
                {props.saveEnable && props.enable
                  ? "Enable Trading"
                  : props.saveEnable && !props.enable
                  ? "Disable Trading"
                  : "Are you sure?"}
              </div>
              <div className="ClearAlertModalDescription">{props.msg}</div>
              <div
                className="ClearAlertModalClearBtn ClearAlertModalDeleteBtn"
                style={{ background: props.enable ? "#77AB37" : "#E02020" }}
                onClick={() => {
                  props.onDeleteClickHandler();
                  props.onHide();
                }}
                data-e2e="clear"
              >
                {props.saveEnable && props.enable
                  ? "Enable"
                  : props.saveEnable && !props.enable
                  ? "Disable"
                  : "Delete"}
              </div>
              <div
                className="ClearAlertModalGoBackBtn"
                onClick={() => {
                  props.onHide();
                }}
              >
                Go Back
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Dialog>
    </div>
  );
};

export default DeletingAlert;
