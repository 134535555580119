import React, { useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../../../common/Loading";
import LabelInput from "../../../../../common/LabelInput";

const closeImage = "/cryptonpay/crytoicons/close.png";

function AddOrEditIntegration({
  setAddNew,
  handleCloseAddNew,
  currentAcquirerName,
  editOpted,
}) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const base_url = useSelector((state) => state.config.api_url);
  //   const email = localDb.getVal("email");
  const [loading, setLoading] = useState(false);
  const [addAcquirer, setAddAcquirer] = useState(currentAcquirerName || "");
  const [errorMessage, setErrorMessage] = useState(false);

  const handleSaveDetails = async () => {
    if (!addAcquirer) {
      setErrorMessage(true);
      return;
    }
    setLoading(false);
    setAddNew(false);
  };

  return (
    <div className="filter_model_container">
      <div className="filter_model">
        <div className="font-large font-weight-bold text-lg">{`${
          editOpted ? "Edit" : "Add"
        } State`}</div>
        <div
          className="filter_close_button"
          data-e2e="filter-close-button"
          onClick={() => {
            handleCloseAddNew();
          }}
        >
          <img
            src={`${image_base_url}${closeImage}`}
            alt="closeImage"
            data-e2e="close-icon"
          />
        </div>
      </div>
      <LabelInput
        label="Integration Name"
        placeholder="Integration Type Name"
        value={addAcquirer}
        setterValue={setAddAcquirer}
      />
      {errorMessage && (
        <div className="my-2 w-full text-left text-red-600">
          {"Please fill all details"}
        </div>
      )}
      <div className="d-flex justify-content-center pt-4">
        <button
          type="button"
          onClick={() => {
            handleSaveDetails();
          }}
          className="btn btn-primary btn-md rounded-pill add-btn w-100"
        >
          {editOpted ? "Update" : "Add"}
        </button>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default AddOrEditIntegration;
