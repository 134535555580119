import React, { useState } from "react";

const Sidebar = ({ tabNames, onSelectTab, tabWithSubTabsNames }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onClickTab = (value) => {
    onSelectTab(value);
    setSelectedTab(value);
  };

  return (
    <div className="mx-auto max-w-xl">
      <aside className="w-52">
        <div className="overflow-y-auto rounded-xl bg-gray-100 px-3 py-4">
          <ul className="space-y-2">
            {tabNames?.map((value, index) => {
              return (
                <li
                  className={`flex cursor-pointer items-center rounded border-b p-2 hover:bg-gray-200 ${selectedTab === value?.value ? "bg-blue-200" : ""}`}
                  onClick={() => onClickTab(value?.value)}
                  key={index}
                >
                  <img
                    src={value.image}
                    alt="test"
                    width="25"
                    height="25"
                    className="pr-2 opacity-60"
                  />
                  <p data-e2e={value?.name}
                    className={`flex items-center text-base font-normal ${selectedTab === value?.value ? "text-blue-500" : "text-gray-500"}`}
                  >
                    {value?.name}
                  </p>
                </li>
              );
            })}
            {tabWithSubTabsNames?.map((value, index) => {
              return (
                <li key={index}>
                  <button
                    type="button"
                    className="flex w-full p-2 items-center rounded-lg text-base font-normal text-gray-500 transition duration-75 hover:bg-gray-200"
                    aria-controls="dropdown-example"
                    data-collapse-toggle="dropdown-example"
                    onClick={toggleDropdown}
                  >
                    <img
                      src={value?.image}
                      alt="test"
                      width="25"
                      height="25"
                      className="pr-2 opacity-60"
                    />
                    <span
                      className="flex-1 whitespace-nowrap text-left"
                      sidebar-toggle-item
                    >
                      {value?.name}
                    </span>
                    <svg
                      className={`h-6 w-6 ${isDropdownOpen ? "rotate-180 transform" : ""}`}
                      sidebar-toggle-item
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      data-e2e="balance-drop-down"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                  <ul
                    id="dropdown-example"
                    className={`space-y-2 py-2 ${isDropdownOpen ? "" : "hidden"}`}
                  >
                    {value?.subTabs?.map((value, index) => {
                      return (
                        <li className="cursor-pointer border-b-2 p-2 pl-3 hover:bg-gray-200" onClick={() => onClickTab(value?.value)} key={index}>
                          <p data-e2e={value?.name}
                            className={`font-normal flex items-center text-sm ${selectedTab === value?.value ? "text-blue-500" : "text-gray-500"}`}
                          >
                            {value?.name}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
