import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Label } from "reactstrap";
import FormInput from "../../../../../common/FormInput";

function AddOrEditAcquirer({
  handleCloseAddNew,
  currentAcquirerName,
  editOpted,
  aquireNameData,
}) {
  const [acquirerName, setAcquirerName] = useState(
    currentAcquirerName?.acquirerName || "",
  );
  const [tokenType, setTokenType] = useState(
    currentAcquirerName?.tokenType || "",
  );
  const [siteReferenceName, setSiteReferenceName] = useState(
    currentAcquirerName?.siteReferenceName || "",
  );
  const [username, setUsername] = useState(currentAcquirerName?.username || "");
  const [jwtUsername, setJwtUsername] = useState(
    currentAcquirerName?.jwtUsername || "",
  );
  const [jwtPassword, setJwtPassword] = useState(
    currentAcquirerName?.jwtPassword || "",
  );
  const [livestatus, setLivestatus] = useState(
    currentAcquirerName?.livestatus || "",
  );
  const [notes, setNotes] = useState(currentAcquirerName?.notes || "");
  const [pwd, setPwd] = useState(currentAcquirerName?.pwd || "");
  const [selectedAcquirer, setSelectedAcquirer] = useState("");

  const [integrationTypeid, setIntegrationTypeid] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (event) => {
    const data = JSON.parse(event.target.value);
    setAcquirerName(data.acquireName);
    setIntegrationTypeid(data._id);
    setSelectedAcquirer(event.target.value);
  };
  const handleAquirer = (e) => {
    // No api so console the value
    console.log("data is", {
      selectedAcquirer,
      acquirerName,
      integrationTypeid,
    });
    handleCloseAddNew();
  };
  return (
    <div className="acquirerConfigDialog">
      <div className="myAccoutDetailsMain">
        <div className="myAccoutDetailsCard">
          <div className="d-flex justify-content-between">
            <h5 className="myAccoutOptModalHeading card-title text-center">
              {`${editOpted ? "Edit" : "Add"} Acquirer`}
            </h5>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleCloseAddNew();
              }}
            >
              <ArrowBackIcon size={24} />
            </div>
          </div>
          <form>
            <FormInput
              label="Acquirer Name"
              name={acquirerName}
              value={acquirerName}
              placeholder="Acquirer Name"
              setterValue={setAcquirerName}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <div className="form-group myAccoutOptModal_form_group">
              <label
                className="myAccoutOptModalLable"
                htmlFor="exampleFormControlSelect3"
              >
                Integration Type
              </label>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedAcquirer}
                displayEmpty
                onChange={(event) => {
                  handleChange(event);
                }}
                className="from-control inputdropdown"
              >
                <MenuItem disabled value="">
                  <em>Select Integration Type</em>
                </MenuItem>
                {aquireNameData?.map((item, i) => (
                  <MenuItem
                    key={i + item?.acquireName}
                    value={JSON.stringify(item)}
                    name={item?.acquireName}
                  >
                    {item?.acquireName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <FormInput
              label="Token Type"
              name={tokenType}
              value={tokenType}
              placeholder="Token Type"
              setterValue={setTokenType}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <FormInput
              label="Site Reference Name"
              name={siteReferenceName}
              value={siteReferenceName}
              placeholder="Site Reference Name"
              setterValue={setSiteReferenceName}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <FormInput
              label="User Name"
              name={username}
              value={username}
              placeholder="User Name"
              setterValue={setUsername}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <FormInput
              label="Pwd"
              name={pwd}
              value={pwd}
              placeholder="pwd"
              setterValue={setPwd}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <FormInput
              label="Jwt Username"
              name={jwtUsername}
              value={jwtUsername}
              placeholder="Jwt Username"
              setterValue={setJwtUsername}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <FormInput
              label="Jwt Password"
              name={jwtUsername}
              value={jwtPassword}
              placeholder="Jwt Password"
              setterValue={setJwtPassword}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <FormInput
              label="Live Status"
              name={livestatus}
              value={livestatus}
              placeholder="Live Status"
              setterValue={setLivestatus}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
            <FormInput
              label="Notes"
              name={notes}
              value={notes}
              placeholder="Notes"
              setterValue={setNotes}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />

            <div className="d-flex justify-content-center">
              <div
                className="submit"
                onClick={() => {
                  handleAquirer();
                }}
              >
                {editOpted ? "Edit" : "Add"}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddOrEditAcquirer;
