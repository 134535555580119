import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { Modal } from "react-bootstrap";
const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";

const DeleteIntegration = (props) => {
  const [loading, setLoading] = useState(false);
  const onDeleteClickHandler = async () => {
    if (props?.deletingId) {
      props?.onHide();
      setLoading(false);
    }
    return;
  };
  return (
    <div className="merchant_dailog_screen">
      <Dialog
        open={props?.deletingId ? true : false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
      >
        <div>
          <Modal
            show={props?.deletingId ? true : false}
            className="ClearAlertModal deleteAlertModal ClearAlertModals"
            onHide={() => {
              props?.onHide();
              props?.setOpenDeleteIcon(false);
              props?.setShowModel(false);
              props?.setDeletingId("");
            }}
          >
            <Modal.Body className="ClearAlertModalBody">
              <div className="ClearAlertModalIconWrap">
                <img
                  src={`${props?.imageBaseURL}${deleteIcon}`}
                  alt="resetIcon"
                  className="ClearAlertModalIcon"
                  onClick={() => {
                    props?.onHide();
                  }}
                />
              </div>
              <div className="ClearAlertModalTitle">Are you sure?</div>
              <div className="ClearAlertModalDescription">{`Are you sure you want to delete ${props?.deletingId}? This action cannot be undone.`}</div>
              <div
                className="ClearAlertModalClearBtn ClearAlertModalDeleteBtn"
                style={{ background: "#E02020" }}
                onClick={() => {
                  onDeleteClickHandler();
                }}
                data-e2e="clear"
              >
                Delete
              </div>
              <div
                className="ClearAlertModalGoBackBtn"
                onClick={() => {
                  props?.onHide();
                }}
              >
                Go Back
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Dialog>
    </div>
  );
};
export default DeleteIntegration;
