import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const CPMultiCountryCode = ({
    codeId,
    dataE2eLabel,
    field,
    items,
    selectedItems,
    ItemComponent,
    setShowDropDown,
    containerStyle = {},
  }) => {
    const baseURL = useSelector((state) => state.config.api_url);
    const { id } = useParams();
  
    const handleAddNewAquirer = async () => {
      try {
        let payload = {
          id: id,
          codeId: codeId?._id,
        };
        if (field === "cardSchemeData") {
          payload.cardSchemeList = selectedItems;
        } else if (field === "AllCurrenciesData") {
          payload.allowedCurrenciesList = selectedItems;
        } else if (field === "AllIpAddressTypesData") {
          payload.ipAddressTypes = selectedItems;
        } else if (field === "AllMandatoryFieldsData") {
          payload.mandatoryFieldsList = selectedItems;
        } else {
          payload.cardCountryCode = selectedItems;
        }
      } catch (err) {
        console.error(err);
      }
    };
  
    const uniqueItems = new Set(selectedItems?.map(item => (field === "AllIpAddressTypesData" ? item : item?.name)));
    const sortedItems = [
      ...selectedItems,
      ...items?.filter(item => !uniqueItems?.has(field === "AllIpAddressTypesData" ? item : item?.name)),
    ];
  
    return (
      <div
        style={containerStyle}
        className="FilterModalCurrencyDropdownlist rulesTab relative w-full"
      >
        <div className="sticky w-full">
          <div
            className="w-full"
            style={{ backgroundColor: "white", borderRadius: "5px" }}
          >
            <div
              className="FM_currencyDrop_All"
              onClick={() => {
                handleAddNewAquirer();
                setShowDropDown(false);
              }}
            >
              <span className="FM_currencyDrop_AllSelector">
                {selectedItems?.length === items?.length || selectedItems?.length === 0
                  ? "Select options"
                  : `${selectedItems.length} Selected`}
              </span>
              <span className="FilterModalInputDropUpIconWrap">
                <i
                  className="fa fa-chevron-up FilterModalInputDropUpIcon"
                  data-e2e={`${dataE2eLabel}contract-icon`}
                />
              </span>
            </div>
          </div>
        </div>
  
        {sortedItems?.map((item, index) => (
          <ItemComponent item={item} key={index} />
        ))}
      </div>
    );
  };
  
export default CPMultiCountryCode