import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { isViewPermissionValid } from "../../../../../../client/common/CommonUtils";
import AddOrEditIntegration from "./AddorEditIntegration";
import { useState } from "react";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DeleteIntegration from "./DeleteIntegration";

const IntegrationType = ({ integrationType }) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [addNew, setAddNew] = useState(false);
  const [editData, setEditData] = useState([]);
  const [editOpted, setEditOpted] = useState(false);
  const [openAddOrEditIcon, setOpenAddOrEditIcon] = useState(false);
  const [openDeleteIcon, setOpenDeleteIcon] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [currentAcquirerName, setCurrentAcquirerName] = useState("");
  const [deletingId, setDeletingId] = useState("");
  const handleCloseAddNew = () => {
    setAddNew(false);
    setEditOpted(false);
  };
  const handleOpenAddNew = () => {
    setAddNew(true);
    setEditOpted(false);
    setOpenAddOrEditIcon(true);
  };
  const openEditDialog = (item) => {
    setCurrentAcquirerName(item?.acquireName);
    setEditOpted(true);
    setAddNew(true);
    setOpenAddOrEditIcon(true);
  };

  const openDeleteDialog = (item) => {
    setDeletingId(item?._id);
    setOpenDeleteIcon(true);
    setShowModel(true);
  };

  return (
    <div className="TabContainer">
      <div className="h-full bg-[#F9FAFB]">
        <h2 className="heading">Integration</h2>
        <div>
          {integrationType?.map((item, index) => (
            <div
              key={index}
              className="flex w-full max-w-[500px] items-center justify-between gap-2 px-2 py-2"
            >
              <div>{item?.acquireName}</div>

              <div className="flex min-w-[100px] max-w-[200px] items-center justify-between px-1 py-2">
                <EditIcon
                  className={`text-blue-500 ${isViewPermissionValid ? "disabled" : ""}`}
                  fontSize="small"
                  onClick={() =>
                    isViewPermissionValid ? null : openEditDialog(item)
                  }
                />

                <DeleteIcon
                  className={`text-danger ${isViewPermissionValid ? "disabled" : ""}`}
                  onClick={() =>
                    isViewPermissionValid ? null : openDeleteDialog(item)
                  }
                />
              </div>
            </div>
          ))}
          <button
            onClick={() => (isViewPermissionValid ? null : handleOpenAddNew())}
            className={`btnAddService ${isViewPermissionValid ? "disabled" : ""}`}
          >
            Add Integration
          </button>
        </div>
      </div>
      {/* edit and add acquirer */}
      {openAddOrEditIcon && (
        <Dialog
          open={addNew}
          onClose={() => {
            handleCloseAddNew();
            setEditData([]);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AddOrEditIntegration
            handleCloseAddNew={() => handleCloseAddNew()}
            editOpted={editOpted}
            currentAcquirerName={currentAcquirerName}
            setAddNew={setAddNew}
            setOpenAddOrEditIcon={setOpenAddOrEditIcon}
          />
        </Dialog>
      )}
      {/* delete acquirer */}
      {openDeleteIcon && (
        <DeleteIntegration
          imageBaseURL={image_base_url}
          deletingId={deletingId}
          msg=""
          onHide={() => {
            setShowModel(false);
            setDeletingId("");
            setOpenDeleteIcon(false);
          }}
          setOpenDeleteIcon={setOpenDeleteIcon}
          showModel={showModel}
          setShowModel={setShowModel}
        />
      )}
    </div>
  );
};

export default IntegrationType;
