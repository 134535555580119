import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSelector } from "react-redux";
import { call } from "../../../../../config/axios";
import { DELETE_BLOCKED_EMAIL } from "../../../../../config/endpoints";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loading from "../../../../common/Loading";

import localDb from "../../../../../localDb";
import ConfirmDialog from "./DeleteConfirmDialog";
import AddBlockedEmail from "./AddBlockedEmail";
import { isViewPermissionValid } from "../../../../common/CommonUtils";
import Pagination from "@mui/material/Pagination";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses?.head}`]: {
    backgroundColor: theme?.palette?.common?.black,
    color: theme?.palette?.common?.white,
  },
  [`&.${tableCellClasses?.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme?.palette?.action?.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const BlockEmailList = (props) => {
  const [addEmailBlocker, setAddEmailBlocker] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [deleteEmail, setDeleteEmail] = useState(null);
  const baseUrl = useSelector((state) => state.config.api_url);
  const [total, setTotal] = useState(0);
  const limit = 5;
  const [page, setPage] = useState(1);
  const [values, setValues] = useState(props?.acquireService?.blockedEmailList);
  const [dummyArray, setDummyArray] = useState(values);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  let newArr = props?.acquireService?.blockedEmailList;
  const searchicon = "/cryptonpay/crytoicons/searchicon1.png";
  const [searchedText, setSearchedText] = useState("");
  const [loading, setLoading] = useState(false);
  const handleDeleteFormBlockList = async (value) => {
    setLoading(true);
    let payload = {
      email: localDb.getVal("email"),
      blockedEmail: value,
      acquireId: props?.acquireService?._id,
    };
    try {
      const response = await call(
        {
          ...DELETE_BLOCKED_EMAIL,
          url: baseUrl + DELETE_BLOCKED_EMAIL.url,
        },
        payload,
      );
      if (response) {
        window.location.reload();
      }
      setLoading(false);
    } catch (err) {
      console.log("Error in handleDeleteFormBlockList", err);
      setLoading(false);
    }
  };

  const filterEmails = (e) => {
    setSearchedText(e);
    const filteredArray = newArr.filter((value) => {
      return value.includes(e);
    });
    setDummyArray(filteredArray);
  };
  const handlePageChange = (event, value) => {
    setPage(value);
    filterProps(value, limit);
  };
  const filterProps = (value, limit) => {
    const end = limit * value;
    const start = (value - 1) * limit;

    setValues(dummyArray.slice(start, end));
  };
  useEffect(() => {
    setTotal(props?.acquireService?.blockedEmailList?.length);
    filterProps(1, limit);
  }, []);
  useEffect(() => {
    setTotal(dummyArray.length);
    filterProps(1, limit);
  }, [dummyArray]);

  const totalPages = Math.ceil(total / limit);
  return (
    <div className="TabContainer">
      {loading && <Loading />}
      {props?.acquireService ? (
        <div>
          <div>
            <div className="pt-3">
              <div className="transactionFilter">
                <input
                  type="text"
                  className="transactionFilter__input"
                  value={searchedText}
                  name="searchText"
                  id="searchText"
                  data-e2e="search-textbox"
                  onChange={(e) => {
                    filterEmails(e.target.value);
                    setPage(1);
                  }}
                  placeholder="Search Email Id"
                />
                <img
                  src={`${image_base_url}${searchicon}`}
                  alt="searchicon"
                  className="transactionFilter__icon"
                  data-e2e="searchIcon"
                />
              </div>
              <TableContainer component={Paper} className="mt-3">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">
                        Blocked Email List
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <div>
                          <button
                            type="button"
                            onClick={() =>
                              isViewPermissionValid
                                ? null
                                : setAddEmailBlocker(true)
                            }
                            className={`icon ${isViewPermissionValid ? "disabled" : ""}`}
                          >
                            <AddIcon />
                          </button>
                        </div>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {values &&
                    values.map((email, i) => (
                      <TableBody>
                        <StyledTableRow key={i}>
                          <StyledTableCell align="left">
                            {email || "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <div
                              onClick={() => {
                                setDeleteEmail(email);
                                setConfirmDialog(true);
                              }}
                              className="delete-icon"
                            >
                              <DeleteOutlineIcon />
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    ))}
                  {values.length < 0 && (
                    <TableBody>
                      <StyledTableRow key={i}>
                        <StyledTableCell align="left">
                          {"nodata"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <div
                            onClick={() => {
                              setDeleteEmail(email);
                              setConfirmDialog(true);
                            }}
                            className="delete-icon"
                          >
                            <DeleteOutlineIcon />
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <div className="mt-4 flex items-center justify-center">
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full text-center">No Data</div>
      )}
      <AddBlockedEmail
        acquireId={props?.acquireService?._id}
        open={addEmailBlocker}
        setOpen={setAddEmailBlocker}
        emailList={newArr}
      />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        deleteEmail={deleteEmail}
        handleDeleteFormBlockList={handleDeleteFormBlockList}
      />
    </div>
  );
};
export default BlockEmailList;
