import DateRangePicker2 from "react-bootstrap-daterangepicker";
import moment from "moment/moment";
import React from "react";
import {useSelector} from "react-redux";
const dateImage = "/cryptonpay/crytoicons/date.png";

function DateFilterInput (props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);

  return (
    <div className="FilterModalInputGroup">
      <div className="FilterModalInputLabel">Date Range</div>
      <div className="transactionsFilter-date-ranger">
        <DateRangePicker2
          onCallback={props.onChangeDate}
          initialSettings={{
            startDate: moment(props.fromDate),
            endDate: moment(props.toDate),
          }}
        >
          <div
            className="FilterModalInputDropdown"
            data-e2e="FilterModalInputDropdown"
          >
            <img
              src={`${image_base_url}${dateImage}`}
              alt="dateImage"
              className="FilterModalInputDropdownDateImage"
            />
            <div className="FilterModalInputDropdownDateTextWrap">
								<span data-e2e="start-date">{`${props.dateSelected
                  ? moment(props.fromDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
                    'D MMMM'
                  )
                  : 'Start Date'
                }`}</span>
              <span className={"filter_modal_date_picker_to_date"}>{` To `}</span>
              <span className={"filter_modal_date_picker_to_date_picker"} data-e2e="end-date">{`${props.dateSelected
                ? moment(props.toDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
                  'D MMMM'
                )
                : 'End Date'
              }`}</span>
            </div>
          </div>
        </DateRangePicker2>
      </div>
    </div>
  )
}

export default DateFilterInput;