import React, { useState, useEffect } from 'react';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import CommonHeader from '../CommonPages/CommonHeader';
import Loading from '../../../common/Loading';
import PaymentProviderListTable from './PaymentProviderListTable';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import {
  DELETE_PAYMENT_PROVIDER_USER,
  GET_PAYMENT_PROVIDER_USER_LIST,
} from '../../../../config/endpoints';
import localDb from '../../../../localDb';
import { call } from '../../../../config/axios';
import CreateUser from "./CreateUser";

const deleteIcon = '/cryptonpay/crytoicons/deleteicon.png';

function PaymentProviderUserTable() {
  const email = localDb.getVal('email');
  const base_url = useSelector((state) => state.config.api_url);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [deletingId, setDeletingId] = useState(null);
  const [addUpdatesuccess, setAddUpdateSuccess] = useState(false);
  const [editWallet, setEditWallet] = useState(false);
  const [editData, setEditData] = useState([]);

  const handleAddUpdateSuccess = () => {
    setPage(1);
    setAddUpdateSuccess((prev) => !prev);
  };
  const handleEditUser = () => {
    setAddNew(true);
    setEditWallet(true);
  };

  const handleCloseAddNew = () => {
    setAddNew(false);
  };

  const name = 'Payment Provider User List';
  const deleteUser = async () => {
    setLoading(true);
    try {
      const res = await call({
        ...DELETE_PAYMENT_PROVIDER_USER,
        url: base_url + DELETE_PAYMENT_PROVIDER_USER.url,
      }, {
        email: email,
        id: deletingId,
      });
      
      setLoading(false);
      
      if (res.status === 200) {
        setDeletingId(null);
        handleAddUpdateSuccess();
      }
    } catch (err) {
      setLoading(false);
      console.log(err.message, 'err');
    }
  };
  

  const getPaymentProviderUserList = async (page) => {
    let pageNo = 1;
    if (page) {
      pageNo = page;
    }
  
    let payload = {
      email: email,
      page: pageNo,
    };
  
    try {
      setLoading(true);
      const res = await call({
        ...GET_PAYMENT_PROVIDER_USER_LIST,
        url: base_url + GET_PAYMENT_PROVIDER_USER_LIST.url,
      }, payload);
  
      if (res.status === 200) {
        if (pageNo === 1) {
          setData(res.data.providerUserList);
          setTotalCount(res.data.totalCount);
        } else {
          let list = data.concat(res.data.providerUserList);
          setData(list);
          setTotalCount(res.data.totalCount);
        }
      }
  
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message, 'err');
    }
  };

  useEffect(() => {
    getPaymentProviderUserList();
  }, [addUpdatesuccess]);

  return (
    <div className="pageContainer merchant_page">
      <HeaderAndFooterLayout>
        <CommonHeader name={name} />
        <Dialog
          open={addNew}
          onClose={() => {
            handleCloseAddNew();
            setEditData([]);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CreateUser
            handleCloseAddNew={() => handleCloseAddNew()}
            handleAddUpdateSuccess={handleAddUpdateSuccess}
            editWallet={editWallet}
            editData={editData}
            setEditData={setEditData}
            editPaymentProvideUser={true}
            getPaymentProviderUserList={()=>getPaymentProviderUserList()}
          />
        </Dialog>
        <div>
          <PaymentProviderListTable
            data={data}
            setDeletingId={setDeletingId}
            setEditData={setEditData}
            handleShowMore={() => {
              let newPage = page + 1;
              setPage(newPage);
              getPaymentProviderUserList(newPage);
            }}
            showMoreButton={totalCount && data && data.length < totalCount}
            handleEditUser={handleEditUser}
          />
        </div>
      </HeaderAndFooterLayout>
      {loading && <Loading />}
      <DeletingAlert
        deletingId={deletingId ? deletingId : null}
        onHide={() => {
          setDeletingId(null);
        }}
        image_base_url={image_base_url}
        msg="Are you sure you want to delete this User? This action cannot be undone."
        onDeleteClickHandler={() => {
          deleteUser();
        }}
      />
    </div>
  );
}

export default PaymentProviderUserTable;

const DeletingAlert = (props) => {
  return (
    <div className="merchant_dailog_screen">
      <Dialog
        open={props.deletingId ? true : false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'md'}
      >
        <div>
          <Modal
            show={true}
            className="ClearAlertModal deleteAlertModal ClearAlertModals"
            onHide={() => {
              props.onHide();
            }}
          >
            <Modal.Body className="ClearAlertModalBody">
              <div className="ClearAlertModalIconWrap">
                <img
                  src={`${props.image_base_url}${deleteIcon}`}
                  onClick={() => {
                    props.onHide();
                  }}
                  alt="resetIcon"
                  className="ClearAlertModalIcon"
                />
              </div>
              <div className="ClearAlertModalTitle">Are you sure?</div>
              <div className="ClearAlertModalDescription">{props.msg}</div>
              <div
                className="ClearAlertModalClearBtn ClearAlertModalDeleteBtn"
                style={{ background: '#E02020' }}
                onClick={() => {
                  props.onDeleteClickHandler();
                }}
                data-e2e="clear"
              >
                Delete
              </div>
              <div
                className="ClearAlertModalGoBackBtn"
                onClick={() => {
                  props.onHide();
                }}
              >
                Go Back
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Dialog>
    </div>
  );
};
