import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState, useRef } from "react";
import { Modal } from 'react-bootstrap';
import { HiOutlineMenuAlt1 } from 'react-icons/hi';
import { MdKeyboardArrowRight, MdOutlineClose } from 'react-icons/md';
import { useDispatch, useSelector } from "react-redux";
import MediaQuery from 'react-responsive';
import { Link, useNavigate } from "react-router-dom";
import localDb from "../../../../localDb";
import { setHeader } from "../../../Redux/actions/commonAction";
import ChangePassword from "../../Pages/B2C/ChangePassword";
import { checkImageUrl } from "../../../common/CheckImageUrl";

const StyledMenu = styled((props) => (
  <Menu
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          width: 10,
          height: 10,
          right: "50%",
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
      },
    }}
    transformOrigin={{ horizontal: "center", vertical: "top" }}
    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
    {...props}
  />
))(({ theme }) => ({}));


const StyledMobileMenu = styled((props) => (
  <div
    className="hamburgermenu_sub_content_wrap"
    PaperProps={{
      elevation: 0,
      sx: {
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
      },
    }}
    transformOrigin={{ horizontal: "center", vertical: "top" }}
    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
    {...props}
  />
))(({ theme }) => ({}));

const techSupport = [
  {
    path: "/real-time-exchange-rates",
    name: "Realtime Exchange Rates",
  },
  {
    path: "/daily-exchange-rates",
    name: "Daily Exchage Rates",
  },
  {
    path: "/error-logs",
    name: "ErrorLogs",
  },
  {
    path: "/sweep-logs",
    name: "SweepLogs",
  },
  {
    path: "/deposit-hash",
    name: "DepositHash",
  },
  {
    path: "/house-keeping-transactions",
    name: "Housekeeping Transactions",
  },
  {
    path: "/house-keeping-alerts",
    name: "Housekeeping Alerts",
  },
  {
    path: "/house-keeping-wallet-balances",
    name: "Housekeeping wallet balances",
  },
  {
    path: "/audit-trail",
    name: "Audit Trail",
  },
];

const globalSettings = [
  {
    path: "/blockchain-settings",
    name: "Blockchain settings",
  },
  {
    path: "/price-management",
    name: "Price management",
  },
  // {
  //   path: "/approved-withdrawal-address",
  //   name: "Approved withdrawal address",
  // },
  {
    path: "/card-acquirer-routing",
    name: "Card Acquirer Routing Service",
  },
  {
    path: "/merchant-settings",
    name: "Merchant Settings",
  },
  {
    path: "/acquirer-config",
    name: "Acquirer Config",
  },
  // {
  //   path: "/client-trans-config",
  //   name: "Transaction type Config list",
  // }
];

const account = [
  {
    name: "Change Password",
  },
  {
    name: "Logout",
  },
];

let merchantMenuList = [
  {
    path: "/all-payment-providers",
    name: "All Payment Providers",
  },
  {
    path: "/merchants",
    name: "All Merchants",
  },
  // {
  //   path: "/clients",
  //   name: "All Clients",
  // },
  // {
  //   path: "/client-transactions",
  //   name: "Client Transactions",
  // },
  // {
  //   path: "/client-dashboard",
  //   name: "Client Dashboard",
  // },
  {
    path: "/admin-dashboard",
    name: "Dashboard",
  },
  {
    path: "/card-dashboard",
    name : "Card Dashboard",
  },
  {
    path: "/transactions",
    name: "Merchant User Transactions",
  },
  {
    path: "/merchant-transactions",
    name: "Merchant Transactions",
  },
  {
    path: "/wallets",
    name: "Merchant User Wallets",
  },
  {
    path: "/centralised-wallets",
    name: "Centralised Wallets",
  },
  {
    path: "/merchant-wallets",
    name: "Merchant Wallets",
  },
  {
    path: "/merchant-send-receive-fees",
    name: "Merchant Send and Receive",
  },
  {
    path: "/payment-providers-sweep-config",
    name: "Sweep Config",
  },
  {
    path: "/sweep-logs",
    name: "SweepLogs",
  },
  {
    path: "/payment-provider-users",
    name: "Payment Provider Users",
  },
  {
    path: "/merchant-whitelist-cards",
    name: "Merchant whitelist cards",
  },
  {
    path: "/merchant-email-whitelist",
    name: "Merchant Email Whitelist",
  },
  {
    path: "/custom-country-mapping",
    name: "Custom Country Mapping",
  },
  {
    path: "/traffic-maskedpan-whitelist",
    name: "Traffic Masked Pan whitelist",
  },
];

let usersMenuList = [
  {
    path: "/b2c-users",
    name: "All Users",
  },
  {
    path: "/b2c-user-transactions",
    name: "User Transactions",
  },
  {
    path: "/b2c-user-wallets",
    name: "User Wallets",
  },
  {
    path: "/b2c-user-spread-level",
    name: "Spread Level",
  },
  {
    path: "/minimum-conversion-amount",
    name: "Minimum Conversion Amount",
  },
  {
    path: "/send-receive-fees",
    name: "Send and Receive",
  },
  {
    path: "/risk-level-limits",
    name: "Risk Level Limits",
  },
  {
    path: "/user-settings",
    name: "User Settings",
  },
];

function Header() {
  let pathname = window && window.location && window.location.pathname && window.location.hostname
  pathname = pathname &&  pathname.split('.')
  const activatedMenuList = useSelector((state) => state.common.header);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState("/merchants");
  const dispatch = useDispatch();
  const activatedMenu = useSelector((state) => state.common.header);
  const [merchantAnchor, setMerchantDropDown] = useState(null);
  const [b2cUserAnchor, setB2CDropDown] = useState(null);
  const [opacityChange, setOpacityChange] = useState(false);
  const adminRole = localDb.getVal("adminRole");
  const headerColor = sessionStorage.getItem("headerLightBgColour")
  const domainId = sessionStorage.getItem('domainId');
  const paymentProviderId = sessionStorage.getItem('paymentProviderId');
  const paymentProviderName = sessionStorage.getItem('paymentProviderName')
  const [profileDropdownOpen, setProfileDropDown] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const handleCloseChangePasswordModal = () =>
    setShowChangePasswordModal(false);
  const handleShowChangePasswordModal = () => {
    setShowChangePasswordModal(true)
    setProfileDropDown(!profileDropdownOpen);
  };

  const merchantDropDown = Boolean(merchantAnchor);
  const b2cUserDropDown = Boolean(b2cUserAnchor);

  const openHamburgerMenu = <HiOutlineMenuAlt1 className="hamburger-menu" size='30px' onClick={() => setHamburgermenu(!hamburgermenu)} />
  const closeHamburgerMenu = <MdOutlineClose className="hamburger-menu" size='30px' onClick={() => setHamburgermenu(!hamburgermenu)} />

  const handleOpenMerchantDropDown = (event) => {
    setB2CDropDown(null);
    setExchangeRateDropDown(null);
    setMerchantDropDown(event.currentTarget);
    setHousekeepingDropDown(null);
    setExchangeSubnav(false);
    setAccountsSubnav(false);
    setHousekeepSubnav(false);
    setUserSubnav(false);

    setMerchantSubnav(!merchantSubnav)
  };

  const handleCloseMerchantDropDown = () => {
    setMerchantDropDown(null);
    setExchangeRateDropDown(null);
  };

  const handleOpenUsersDropDown = (event) => {
    setExchangeRateDropDown(null);
    setMerchantDropDown(null);
    setB2CDropDown(event.currentTarget);
    setHousekeepingDropDown(null);
    setAccountDropDown(null);
    setMerchantSubnav(false);
    setExchangeSubnav(false);
    setAccountsSubnav(false);
    setHousekeepSubnav(false);

    setUserSubnav(!userSubnav);
  };

  const handleCloseUsersDropDown = () => {
    setExchangeRateDropDown(null);
    setB2CDropDown(null);
  };

  const [userSubnav, setUserSubnav] = useState(false);
  const [merchantSubnav, setMerchantSubnav] = useState(false);
  const [exchangeSubnav, setExchangeSubnav] = useState(false);
  const [housekeepSubnav, setHousekeepSubnav] = useState(false);
  const [accountsSubnav, setAccountsSubnav] = useState(false);
  const [showLogoDarkImage, setShowLogoDarkImage] = useState(false)

  const [exchangeAnchor, setExchangeRateDropDown] = useState(null);
  const [housekeepingAnchor, setHousekeepingDropDown] = useState(null);

  const [hamburgermenu, setHamburgermenu] = useState(false);
  const [accountAnchor, setAccountDropDown] = useState(null);

  const exchangeDropDown = Boolean(exchangeAnchor);
  const houseKeepingDropdown = Boolean(housekeepingAnchor);
  const accountDropdown = Boolean(accountAnchor);

  const handleOpenExchangeDropDown = (event) => {
    setHousekeepingDropDown(null);
    setMerchantDropDown(null);
    setB2CDropDown(null);
    setExchangeRateDropDown(event.currentTarget);
    setMerchantSubnav(false);
    setAccountsSubnav(false);
    setHousekeepSubnav(false);
    setUserSubnav(false);

    setExchangeSubnav(!exchangeSubnav);
  };
  const handleCloseExchangeDropDown = () => {
    setExchangeRateDropDown(null);
  };

  const handleOpenHousekeepingDropDown = (event) => {
    setHousekeepingDropDown(event.currentTarget);
    setMerchantDropDown(null);
    setB2CDropDown(null);
    setExchangeRateDropDown(null);
    setMerchantSubnav(false);
    setExchangeSubnav(false);
    setAccountsSubnav(false);
    setUserSubnav(false);

    setHousekeepSubnav(!housekeepSubnav);
  };

  const handleOpenAccountDropDown = (event) => {
    setAccountDropDown(event.currentTarget);
    setHousekeepingDropDown(null);
    setMerchantDropDown(null);
    setB2CDropDown(null);
    setExchangeRateDropDown(null);
    setMerchantSubnav(false);
    setExchangeSubnav(false);
    setHousekeepSubnav(false);
    setUserSubnav(false);

    setAccountsSubnav(!accountsSubnav);
  };

  const handleCloseHousekeepingDropDown = () => {
    setHousekeepingDropDown(null);
  };

  const handleLogout = () => {
    localDb.clear();
    navigate("/merchants");
    window.location.reload();
  };

  const handleActiveClass = (name) => {
    dispatch(setHeader(name));
    setActiveMenu(name);
    if (localDb.getVal("adminRole") == "SuperAdmin" && localDb.getVal("paymentProviderId")) {
      localDb.remove("paymentProviderId");
      if (name === "All Merchants") {
        window.location.reload()
      }
    }
  };
  const handleHomePage = () => {
    dispatch(setHeader("All Merchants"));
  };
  const getImageUrl = async () => {
    return await checkImageUrl(`${image_base_url}/payment-provider/${paymentProviderId}/${domainId !== null ? domainId : ""}/logodark.png`)
  }
  useEffect(() => {
    setActiveMenu(activatedMenu);
    const validUrl = getImageUrl();
    if (validUrl) {
      setShowLogoDarkImage(true)
    } else {
      setShowLogoDarkImage(false)
    }
  }, [activatedMenu, showLogoDarkImage]);


  useEffect(() => {
    let isMounted = true;

    const handleScroll = () => {
      if (window.scrollY > 100) {
        setOpacityChange(true);
      } else {
        setOpacityChange(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    if (localDb.getVal("adminRole") !== "SuperAdmin") {
      merchantMenuList = merchantMenuList.filter((obj) => {
        return obj.path !== "/merchant-send-receive-fees" && obj.path !== "/all-payment-providers"
      })
      usersMenuList = usersMenuList.filter(obj => {
        return obj.path === "/b2c-users" || obj.path === "/b2c-user-transactions" || obj.path === "/b2c-user-wallets"
      })
    }

    return () => {
      isMounted = false;
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <div className={`header ${opacityChange ? "scroll-header" : ""}`}>
      <MediaQuery minWidth={768}>
        <div style={headerColor && headerColor !== null ? { backgroundColor: headerColor } : {}} className={`header_body ${pathname && pathname[0] === "admin" ? "header-banner-bg-prod" : "header-banner-bg-dev"}`}>
          <div className="crypton-admin">
            {paymentProviderName && paymentProviderName !== 'null' && domainId && domainId !== "null" && showLogoDarkImage && <img style={{ maxHeight: "50px" }} src={`${image_base_url}/payment-provider/${paymentProviderId}/${domainId !== null ? domainId : ""}/logodark.png`} alt="" />}
            {domainId && domainId === "null" && !showLogoDarkImage && <Link to="/merchants" className="admin_logo">
              <div
                className="logo-header"
                onClick={() => {
                  handleHomePage();
                }}
              >
                <img
                  className="main-logo-dimensions"
                  src={`${image_base_url}/homeb2c/main-logo-admin.svg`}
                  alt="CryptonAdmin"
                />
                <div className="main-header-text">
                  CRYPTON<span className="header-blue">ADMIN</span>
                </div>
              </div>
            </Link>}
          </div>
          <div className="nav__menu" id="nav-menu">
            <ul className="nav__list">
                <React.Fragment>
                  <div
                    className="button-container-flex"
                    onClick={handleOpenUsersDropDown}
                  >
                    <button
                      className={`${b2cUserDropDown
                        ? "navItemActive navItemWrapA"
                        : "navItemWrapA"
                        } nav-btn-right`}
                      id="demo-customized-button"
                      aria-controls={
                        b2cUserDropDown ? "demo-customized-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={b2cUserDropDown ? "true" : undefined}
                      variant="contained"
                      disableelevation="true"
                      data-e2e="b2c-user-tab"
                    >
                      B2C Users
                    </button>
                    <i
                      className={`fa fa-chevron-${b2cUserAnchor ? "up" : "down"
                        } FilterModalInputDropUpIcon`}
                    />
                  </div>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={b2cUserAnchor}
                    open={b2cUserDropDown}
                    onClose={handleCloseUsersDropDown}
                  >
                    {usersMenuList &&
                      usersMenuList.map((menu, index) => {
                        return (
                          <MenuItem
                            onClick={handleCloseMerchantDropDown}
                            disableRipple
                            key={index}
                            className={`${activatedMenuList &&
                              menu.name.toLowerCase() ===
                              activatedMenuList.toLowerCase()
                              ? "active-menu-link"
                              : ""
                              }`}
                          >
                            <Link
                              to={menu.path}
                              onClick={() => handleActiveClass(menu.name)}
                              data-e2e={menu.name.toLowerCase().replace(' ', '-')}
                            >
                              {menu.name}
                            </Link>
                          </MenuItem>
                        );
                      })}
                  </StyledMenu>
                </React.Fragment>
              <div
                className="button-container-flex"
                onClick={handleOpenMerchantDropDown}
              >
                <button
                  className={`${merchantDropDown
                    ? "navItemActive navItemWrapA"
                    : "navItemWrapA"
                    } nav-btn-right `}
                  id="demo-customized-button"
                  aria-controls={
                    merchantDropDown ? "demo-customized-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={merchantDropDown ? "true" : undefined}
                  variant="contained"
                  disableelevation="true"
                  data-e2e="merchants-tab"
                >
                  Merchants
                </button>
                <i
                  className={`fa fa-chevron-${merchantAnchor ? "up" : "down"
                    } FilterModalInputDropUpIcon`}
                />
              </div>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={merchantAnchor}
                open={merchantDropDown}
                onClose={handleCloseMerchantDropDown}
              >
                {merchantMenuList &&
                  merchantMenuList.map((menu, index) => {
                    return (
                      <MenuItem
                        onClick={handleCloseMerchantDropDown}
                        disableRipple
                        key={index}
                        className={`${activatedMenuList &&
                          menu.name.toLowerCase() ===
                          activatedMenuList.toLowerCase()
                          ? "active-menu-link"
                          : ""
                          }`}
                      >
                        <Link
                          to={menu.path}
                          onClick={() => handleActiveClass(menu.name)}
                          data-e2e={menu.name.toLowerCase().replace(' ', '-')}
                        >
                          {menu.name}
                        </Link>
                      </MenuItem>
                    );
                  })}
              </StyledMenu>

              {["SuperAdmin"].includes(adminRole) &&
                <React.Fragment>

                  <div
                    className="button-container-flex"
                    onClick={handleOpenExchangeDropDown}
                  >
                    <button
                      className={`${exchangeDropDown
                        ? "navItemActive navItemWrapA"
                        : "navItemWrapA"
                        } ${activatedMenuList === "Realtime Exchange Rates" ||
                          activatedMenuList === "Daily Exchage Rates"
                          ? "menu-highlighted"
                          : ""
                        } nav-btn-right `}
                      id="demo-customized-button"
                      aria-controls={
                        exchangeDropDown ? "demo-customized-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={exchangeDropDown ? "true" : undefined}
                      variant="contained"
                      disableelevation="true"
                      data-e2e="global-settings-tab"
                    >
                      Global Settings
                    </button>
                    <i
                      className={`fa fa-chevron-${exchangeAnchor ? "up" : "down"
                        } FilterModalInputDropUpIcon`}
                    />
                  </div>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={exchangeAnchor}
                    open={exchangeDropDown}
                    onClose={handleCloseExchangeDropDown}
                  >
                    {globalSettings &&
                      globalSettings.map((menu, index) => {
                        return (
                          <MenuItem
                            onClick={handleCloseHousekeepingDropDown}
                            disableRipple
                            key={index}
                            className={`${activatedMenuList &&
                              menu.name.toLowerCase() ===
                              activatedMenuList.toLowerCase()
                              ? "active-menu-link"
                              : ""
                              }`}
                          >
                            <Link
                              to={menu.path}
                              onClick={() => handleActiveClass(menu.name)}
                              data-e2e={menu.name.toLowerCase().replaceAll(' ', '-')}
                            >
                              {menu.name}
                            </Link>
                          </MenuItem>
                        );
                      })}
                  </StyledMenu>
                  <div
                    className="button-container-flex"
                    onClick={handleOpenHousekeepingDropDown}
                  >
                    <button
                      className={`${houseKeepingDropdown
                        ? "navItemActive navItemWrapA"
                        : "navItemWrapA"
                        } ${activatedMenuList === "Wallet Balances" ||
                          activatedMenuList === "All Transactions"
                          ? "menu-highlighted"
                          : ""
                        } nav-btn-right`}
                      id="demo-customized-button"
                      aria-controls={
                        houseKeepingDropdown ? "demo-customized-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={houseKeepingDropdown ? "true" : undefined}
                      variant="contained"
                      disableelevation="true"
                      data-e2e="tech-support-tab"
                    >
                      Tech Support
                    </button>
                    <i
                      className={`fa fa-chevron-${housekeepingAnchor ? "up" : "down"
                        } FilterModalInputDropUpIcon`}
                    />
                  </div>

                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={housekeepingAnchor}
                    open={houseKeepingDropdown}
                    onClose={handleCloseHousekeepingDropDown}
                  >
                    {techSupport &&
                      techSupport.map((menu, index) => {
                        return (
                          <MenuItem
                            onClick={handleCloseExchangeDropDown}
                            disableRipple
                            key={index}
                            className={`${activatedMenuList &&
                              menu.name
                                .toLowerCase()
                                .includes(activatedMenuList.toLowerCase())
                              ? "active-menu-link"
                              : ""
                              }`}
                          >
                            <Link
                              to={menu.path}
                              onClick={() => handleActiveClass(menu.name)}
                              data-e2e={menu.name.toLowerCase().replaceAll(' ', '-')}
                            >
                              {menu.name}
                            </Link>
                          </MenuItem>
                        );
                      })}
                  </StyledMenu>
                </React.Fragment>
              }
              <div
                className="button-container-flex"
                onClick={handleOpenAccountDropDown}
              >
                <button
                  className={`${accountDropdown
                    ? "navItemActive navItemWrapA"
                    : "navItemWrapA"
                    } ${activatedMenuList === "Wallet Balances" ||
                      activatedMenuList === "All Transactions"
                      ? "menu-highlighted"
                      : ""
                    } nav-btn-right`}
                  id="demo-customized-button"
                  aria-controls={
                    accountDropdown ? "demo-customized-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={accountDropdown ? "true" : undefined}
                  variant="contained"
                  disableelevation="true"
                  data-e2e="account-tab"
                >
                  {`Account`}
                </button>
                <i
                  className={`fa fa-chevron-${accountAnchor ? "up" : "down"
                    } FilterModalInputDropUpIcon`}
                />
              </div>

              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={accountAnchor}
                open={accountDropdown}
                onClose={() => {
                  setAccountDropDown(null);
                }}
              >
                {account &&
                  account.map((menu, index) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          setAccountDropDown(null);
                          if (menu.name === "Change Password") {
                            handleShowChangePasswordModal();
                          } else if (menu.name === "Logout") {
                            handleLogout();
                          }
                        }}
                        disableRipple
                        key={index}
                        className={`${activatedMenuList &&
                          menu.name
                            .toLowerCase()
                            .includes(activatedMenuList.toLowerCase())
                          ? "active-menu-link"
                          : ""
                          }`}
                        data-e2e={`${menu.name
                          .toLowerCase()
                          .replace(" ", "-")}`}
                      >
                        {menu.name}
                      </MenuItem>
                    );
                  })}
              </StyledMenu>
            </ul>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={767} >
        <div style={headerColor && headerColor !== null ? { backgroundColor: headerColor } : {}} className={`header_body_mobile ${hamburgermenu && "text-dark bg-white hamburgermenu_open"} ${pathname && pathname[0] === "admin" ? "header-banner-bg-prod" : "header-banner-bg-dev"}`}>
          <div className="crypton-admin">
            {paymentProviderName && paymentProviderName !== 'null' && domainId && domainId !== "null" && showLogoDarkImage && <img style={{ maxHeight: "50px" }} src={`${image_base_url}/payment-provider/${paymentProviderId}/${domainId !== null ? domainId : ""}/logodark.png`} alt="" />}
            {domainId && domainId === "null" && !showLogoDarkImage && <Link to="/merchants" className="admin_logo">
              <div
                className="logo-header"
                onClick={() => {
                  handleHomePage();
                }}
              >
                <img
                  className="main-logo-dimensions"
                  src={`${image_base_url}/homeb2c/main-logo-admin.svg`}
                  alt="CryptonAdmin"
                />
                <div className="main-header-text">
                  CRYPTON<span className="header-blue">ADMIN</span>
                </div>
              </div>
            </Link>}
          </div>
          {hamburgermenu ? closeHamburgerMenu : openHamburgerMenu}
        </div>

        {hamburgermenu &&
          <div className="hamburgermenu_modal">
            <Modal show={hamburgermenu} className="button-container-flex-mobile">
              <div className="hamburgermenu_container">
                <ul className="nav-list-mobile">
                    <React.Fragment>
                      <div
                        className="button-mobile-flex"
                        onClick={handleOpenUsersDropDown}
                      >
                        <button
                        >
                          B2C Users
                        </button>
                        <i
                          className={`fa fa-chevron-${userSubnav ? "up" : "down"
                            } FilterModalInputDropUpIcon header_dropdown_arrow`}
                        />
                      </div>
                      {userSubnav && <StyledMobileMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={b2cUserAnchor}
                        open={b2cUserDropDown}
                        onClose={handleCloseUsersDropDown}
                      >
                        {usersMenuList &&
                          usersMenuList.map((menu, index) => {
                            return (
                              <MenuItem
                                style={{ width: '100%' }}
                                onClick={handleCloseMerchantDropDown}
                                disableRipple
                                key={index}
                                className={`${activatedMenuList &&
                                  menu.name
                                    .toLowerCase()
                                    .includes(activatedMenuList.toLowerCase())
                                  ? "active-menu-link"
                                  : ""
                                  }`}
                              >
                                <Link
                                  to={menu.path}
                                  onClick={() => handleActiveClass(menu.name)}
                                  data-e2e={menu.name.toLowerCase().replace(' ', '-')}
                                  style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                  <div >
                                    {menu.name}
                                  </div>
                                  <div className="mr-3">
                                    <MdKeyboardArrowRight color="#667085" size='22' />
                                  </div>
                                </Link>
                              </MenuItem>
                            );
                          })}
                      </StyledMobileMenu>}
                    </React.Fragment>
                  <div
                    className="button-mobile-flex"
                    onClick={handleOpenMerchantDropDown}
                  >
                    <button>
                      Merchants
                    </button>
                    <i
                      className={`fa fa-chevron-${merchantSubnav ? "up" : "down"
                        } FilterModalInputDropUpIcon header_dropdown_arrow`}
                    />
                  </div>
                  {merchantSubnav && <StyledMobileMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={merchantAnchor}
                    open={merchantDropDown}
                    onClose={handleCloseMerchantDropDown}
                  >
                    {merchantMenuList &&
                      merchantMenuList.map((menu, index) => {
                        return (
                          <MenuItem
                            style={{ width: '100%' }}
                            onClick={handleCloseMerchantDropDown}
                            disableRipple
                            key={index}
                            className={`${activatedMenuList &&
                              menu.name
                                .toLowerCase()
                                .includes(activatedMenuList.toLowerCase())
                              ? "active-menu-link"
                              : ""
                              }`}
                          >
                            <Link
                              to={menu.path}
                              onClick={() => handleActiveClass(menu.name)}
                              data-e2e={menu.name.toLowerCase().replace(' ', '-')}
                              style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            >
                              <div>
                                {menu.name}
                              </div>
                              <div className="mr-3">
                                <MdKeyboardArrowRight color="#667085" size='22' />
                              </div>
                            </Link>
                          </MenuItem>
                        );
                      })}
                  </StyledMobileMenu>}

                  {adminRole === "SuperAdmin" &&
                    <React.Fragment>
                      <div
                        className="button-mobile-flex"
                        onClick={handleOpenExchangeDropDown}
                      >
                        <button>
                          Global Settings
                        </button>
                        <i
                          className={`fa fa-chevron-${exchangeSubnav ? "up" : "down"
                            } FilterModalInputDropUpIcon header_dropdown_arrow`}
                        />
                      </div>
                      {exchangeSubnav && <StyledMobileMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={exchangeAnchor}
                        open={exchangeDropDown}
                        onClose={handleCloseExchangeDropDown}
                      >
                        {globalSettings &&
                          globalSettings.map((menu, index) => {
                            return (
                              <MenuItem
                                style={{ width: '100%' }}
                                onClick={handleCloseHousekeepingDropDown}
                                disableRipple
                                key={index}
                                className={`${activatedMenuList &&
                                  menu.name.toLowerCase() ===
                                  activatedMenuList.toLowerCase()
                                  ? "active-menu-link"
                                  : ""
                                  }`}
                              >
                                <Link
                                  to={menu.path}
                                  onClick={() => handleActiveClass(menu.name)}
                                  data-e2e={menu.name.toLowerCase().replaceAll(' ', '-')}
                                  style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                  <div>
                                    {menu.name}
                                  </div>
                                  <div className="mr-3">
                                    <MdKeyboardArrowRight color="#667085" size='22' />
                                  </div>
                                </Link>
                              </MenuItem>
                            );
                          })}
                      </StyledMobileMenu>}

                      <div
                        className="button-mobile-flex"
                        onClick={handleOpenHousekeepingDropDown}
                      >
                        <button>
                          Tech Support
                        </button>
                        <i
                          className={`fa fa-chevron-${housekeepSubnav ? "up" : "down"
                            } FilterModalInputDropUpIcon header_dropdown_arrow`}
                        />
                      </div>
                      {housekeepSubnav && <StyledMobileMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={housekeepingAnchor}
                        open={houseKeepingDropdown}
                        onClose={handleCloseHousekeepingDropDown}
                      >
                        {techSupport &&
                          techSupport.map((menu, index) => {
                            return (
                              <MenuItem
                                style={{ width: '100%' }}
                                onClick={handleCloseExchangeDropDown}
                                disableRipple
                                key={index}
                                className={`${activatedMenuList &&
                                  menu.name
                                    .toLowerCase()
                                    .includes(activatedMenuList.toLowerCase())
                                  ? "active-menu-link"
                                  : ""
                                  }`}
                              >
                                <Link
                                  to={menu.path}
                                  onClick={() => handleActiveClass(menu.name)}
                                  data-e2e={menu.name.toLowerCase().replaceAll(' ', '-')}
                                  style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                  <div>
                                    {menu.name}
                                  </div>
                                  <div className="mr-3">
                                    <MdKeyboardArrowRight color="#667085" size='22' />
                                  </div>
                                </Link>
                              </MenuItem>
                            );
                          })}
                      </StyledMobileMenu>}
                    </React.Fragment>
                  }

                  <div
                    className="button-mobile-flex"
                    onClick={handleOpenAccountDropDown}
                  >
                    <button
                      data-e2e="account-tab"
                    >
                      {`Account`}
                    </button>
                    <i
                      className={`fa fa-chevron-${accountsSubnav ? "up" : "down"
                        } FilterModalInputDropUpIcon header_dropdown_arrow`}
                    />
                  </div>

                  {accountsSubnav && <StyledMobileMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={accountAnchor}
                    open={accountDropdown}
                    onClose={() => {
                      setAccountDropDown(null);
                    }}
                  >
                    {account &&
                      account.map((menu, index) => {
                        return (
                          <MenuItem
                            style={{ width: '100%', display: 'flex', flexDirection: 'row', color: 'black' }}
                            onClick={() => {
                              setAccountDropDown(null);
                              if (menu.name === "Change Password") {
                                handleShowChangePasswordModal();
                              } else if (menu.name === "Logout") {
                                handleLogout();
                              }
                            }}
                            disableRipple
                            key={index}
                            className={`${activatedMenuList &&
                              menu.name
                                .toLowerCase()
                                .includes(activatedMenuList.toLowerCase())
                              ? "active-menu-link"
                              : ""
                              }`}
                            data-e2e={`${menu.name
                              .toLowerCase()
                              .replace(" ", "-")}`}
                          >
                            <div
                              style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            >
                              <div>
                                {menu.name}
                              </div>
                              <div className="mr-3">
                                <MdKeyboardArrowRight color="#667085" size='22' />
                              </div>

                            </div>
                          </MenuItem>
                        );
                      })}
                  </StyledMobileMenu>}

                </ul>
              </div>
            </Modal>
          </div>}
      </MediaQuery>

      {showChangePasswordModal && (
        <ChangePassword
          open={showChangePasswordModal}
          onClose={handleCloseChangePasswordModal}
          handleLogout={handleLogout}
        />
      )}
    </div>
  );
}

export default Header;