import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCountryCodesFromDb } from "../../../../global/helpers";
import IOSSwitch from "../IOSSwitch";
import TabButton from "../../../../../common/TabButton";
import Loading from "../../../../../common/Loading";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";
import constant from "../../../../global/constant.js";
import CPMultiCountryCode from "./CPMultiCountryCode.js";
import CPMultiMerchantCode from "./CPMultiMerchantCode.js";
import ItemComponent from "./ItemComponent.js";
const selectedCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_filled.png";
const emptyCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_empty.png";

function RulesTab(props) {
  const { acquireService } = props;
  const cardSchemeData = [
    { name: "Visa" },
    { name: "Mastercard" },
    { name: "American Express" },
    { name: "UnionPay" },
    { name: "JCB" },
    { name: "Diners" },
  ];
  let AllCurrenciesData = [{ name: "USD" }, { name: "EUR" }, { name: "GBP" }];
  let AllMandatoryFieldsData = [
    { name: "ipAddress" },
    { name: "payerAddress" },
    { name: "payerState" },
    { name: "payerCountry" },
    { name: "payerCity" },
    { name: "payerZip" },
    { name: "payerPhone" },
    { name: "merchantUserEmail" },
    { name: "merchantTransactionId" },
    { name: "browser_timezone" },
    { name: "browser_color_depth" },
    { name: "browser_language" },
    { name: "browser_screen_height" },
    { name: "browser_screen_width" },
    { name: "os" },
    { name: "browserUserAgent" },
  ];
  const baseURL = useSelector((state) => state.config.api_url);
  const imageBaseURL = useSelector((state) => state.config.image_base_url);
  const [tabs, setTabs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [blockedCountryDropDown, setBlockedCountryDropDown] = useState(false);
  const [blockedCardSchemeDropDown, setBlockedCardSchemeDropDown] =
    useState(false);
  const [allowedCurrenciesDropDown, setAllowedCurrenciesDropDown] =
    useState(false);
  const [ipAddressTypesDropDown, setIpAddressTypesDropDown] = useState(false);
  const [mandatoryFieldsDropDown, setMandatoryFieldsDropDown] = useState(false);
  const [blockedMerchentCodeDropDown, setBlockedMerchentCodeDropDown] =
    useState(false);

  const [selectedCardCountryCode, setSelectedCardCountryCode] = useState([]);
  const [selectedMerchantCode, setSelectedMerchantCode] = useState([]);
  const [selectedCardScheme, setSelectedCardScheme] = useState([]);
  const [selectedAllowedCurrencies, setSelectedAllowedCurrencies] = useState(
    [],
  );
  const [selectedIpAddressTypes, setSelectedIpAddressTypes] = useState([]);
  const [selectedMandatoryFields, setSelectedMandatoryFields] = useState([]);
  const [countryCodesData, setCountryCodesData] = useState([]);
  const [merchentCodesData, setMerchentCodesData] = useState([]);
  const onClickSwitch = (e, item) => {
    const payload = {
      isCountryCodeActive: !e?.target?.checked ? false : true,
      codeId: item?._id,
      uniqueId: acquireService?.uniqueId,
    };
    handletoggleActiveCountryCode(payload);
  };

  const handleTabSwitch = (index) => {
    setTabs(index);
  };

  const handletoggleActiveCountryCode = async (payload) => {
    // need api call
  };
  const fetchData = async () => {
    const countryCode = await getCountryCodesFromDb(baseURL);
    setCountryCodesData(countryCode);
    setMerchentCodesData(countryCode);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleSwitchMandatoryFields = (e, item) => {
    isViewPermissionValid
      ? null
      : () => {
          const payload = {
            isMandatoryFieldsListActive: !e?.target?.checked ? false : true,
            codeId: item?._id,
            uniqueId: acquireService?.uniqueId,
          };
          handletoggleActiveCountryCode(payload);
        };
  };
  const toggleHandler = (currentArray, item, newArray, filterName) => {
    if (
      currentArray?.some((code) => code?.[filterName] === item?.[filterName])
    ) {
      let newList = currentArray?.filter(
        (code) => code?.[filterName] !== item?.[filterName],
      );
      newArray(newList);
    } else {
      let newList = [...currentArray];
      newList.push(item);
      newArray(newList);
    }
  };
  const toggleCardCountry = (item) => {
    return toggleHandler(
      selectedCardCountryCode,
      item,
      setSelectedCardCountryCode,
      "code",
    );
  };
  const toggleCardScheme = (item) => {
    return toggleHandler(
      selectedCardScheme,
      item,
      setSelectedCardScheme,
      "name",
    );
  };
  const toggleAllowedCurrency = (item) => {
    return toggleHandler(
      selectedAllowedCurrencies,
      item,
      setSelectedAllowedCurrencies,
      "name",
    );
  };
  const toggleMerchantCode = (item) => {
    return toggleHandler(
      selectedMerchantCode,
      item,
      setSelectedMerchantCode,
      "code",
    );
  };
  const toggleMandatoryFields = (item) => {
    return toggleHandler(
      selectedMandatoryFields,
      item,
      setSelectedMandatoryFields,
      "name",
    );
  };

  const toggleIpAddressTypes = (item) => {
    if (selectedIpAddressTypes?.some((type) => type === item)) {
      const newList = selectedIpAddressTypes?.filter((type) => type !== item);
      setSelectedIpAddressTypes(newList);
    } else {
      const newList = [...selectedIpAddressTypes];
      newList.push(item);
      setSelectedIpAddressTypes(newList);
    }
  };

  return (
    <div className="TabContainer d-flex flex-column">
      {loading && <Loading />}
      <>
        {acquireService ? (
          <div className="acquireServiceScroll">
            {acquireService?.routingService?.map((tab, index) => (
              <TabButton
                key={index}
                activeTab={tabs}
                handleTabSwitch={handleTabSwitch}
                tab={tab}
                index={index}
              />
            ))}
          </div>
        ) : (
          <div className="w-full text-center">
            No Acquirers found ... Something went wrong
          </div>
        )}
      </>
      <>
        {acquireService?.routingService?.length !== 0 ? (
          <>
            {acquireService?.routingService?.map((item, index) => {
              return (
                <div key={index} className="w-full">
                  {tabs === index ? (
                    <>
                      <div className="border-b">
                        <h3 className="px-2 py-4 text-lg">
                          {item?.integrationType?.acquireName}
                        </h3>
                      </div>
                      <div className="rulesTab">
                        <div className="w-80">
                          <div className="text-[16px] font-medium">
                            Blocked BIN countries
                          </div>
                          <div className="text-[12px] text-gray-700">
                            Select the countries that should be blocked from
                            BINN deposit
                          </div>
                        </div>
                        <div
                          className={`mx-6 ${isViewPermissionValid ? "disabled" : ""}`}
                        >
                          <IOSSwitch
                            defaultChecked={item.rules.isCountryCodeActive}
                            onClickSwitch
                            onClick={(e) =>
                              isViewPermissionValid
                                ? null
                                : onClickSwitch(e, item)
                            }
                          />
                        </div>
                        <div className="ml-8 w-2/5">
                          <div className="mb-2 text-[14px] ">Location</div>
                          <div
                            className={`FilterModalInputDropdown ${isViewPermissionValid ? "disabled" : ""}`}
                            onClick={() =>
                              isViewPermissionValid
                                ? null
                                : setBlockedCountryDropDown(true)
                            }
                          >
                            <div
                              className="FilterModalInputText"
                              data-e2e="crypto-currency-expand"
                            >
                              {countryCodesData?.length ===
                                selectedCardCountryCode?.length ||
                              selectedCardCountryCode?.length === 0
                                ? "Select options"
                                : `${selectedCardCountryCode?.length} Selected`}
                            </div>
                            <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                          </div>

                          {blockedCountryDropDown ? (
                            <CPMultiCountryCode
                              codeId={item}
                              dataE2eLabel="crypto-urrency-"
                              items={countryCodesData}
                              field="cardCountryCode"
                              selectedItems={selectedCardCountryCode}
                              setShowDropDown={setBlockedCountryDropDown}
                              setSelectedItems={(items) => {
                                setSelectedCardCountryCode(items || []);
                              }}
                              ItemComponent={({ item: code }) => {
                                return (
                                  <ItemComponent
                                    toggleAllowed={toggleCardCountry}
                                    item={code}
                                    selectedArray={selectedCardCountryCode}
                                  />
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="rulesTab">
                        <div className="w-80">
                          <div className="text-[16px] ">
                            Blocked user countries
                          </div>
                          <div className="text-[12px] text-gray-700">
                            Select the countries that should be blocked from
                            BINN deposit
                          </div>
                        </div>
                        <div
                          className={`mx-6 ${isViewPermissionValid ? "disabled" : ""}`}
                        >
                          <IOSSwitch
                            defaultChecked={
                              item?.rules?.isMerchantCountryCodeActive
                            }
                            onClick={(e) =>
                              isViewPermissionValid
                                ? null
                                : (() => {
                                    const payload = {
                                      isMerchantCountryCodeActive: !e?.target
                                        ?.checked
                                        ? false
                                        : true,
                                      codeId: item?._id,
                                      uniqueId: acquireService?.uniqueId,
                                    };
                                    handletoggleActiveCountryCode(payload);
                                  })()
                            }
                          />
                        </div>
                        <div className="ml-8 w-2/5">
                          <div className="mb-2 text-[14px] ">Location</div>
                          <div
                            className={`FilterModalInputDropdown ${isViewPermissionValid ? "disabled" : ""}`}
                            onClick={() =>
                              isViewPermissionValid
                                ? null
                                : setBlockedMerchentCodeDropDown(true)
                            }
                          >
                            <div
                              className="FilterModalInputText"
                              data-e2e="crypto-currency-expand"
                            >
                              {countryCodesData?.length ===
                                selectedMerchantCode?.length ||
                              selectedMerchantCode?.length === 0
                                ? "Select options"
                                : `${selectedMerchantCode?.length} Selected`}
                            </div>
                            <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                          </div>

                          {blockedMerchentCodeDropDown ? (
                            <CPMultiMerchantCode
                              codeId={item}
                              dataE2eLabel="crypto-urrency-"
                              items={merchentCodesData}
                              field="merchantCountryCode"
                              selectedItems={selectedMerchantCode}
                              setShowDropDown={setBlockedMerchentCodeDropDown}
                              setSelectedItems={(items) => {
                                setSelectedMerchantCode(items || []);
                              }}
                              ItemComponent={({ item: code }) => {
                                return (
                                  <ItemComponent
                                    toggleAllowed={toggleMerchantCode}
                                    item={code}
                                    selectedArray={selectedMerchantCode}
                                  />
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>

                      <div className="rulesTab">
                        <div className="w-80">
                          <div className="text-[16px] font-medium">
                            Blocked Card scheme
                          </div>
                          <div className="text-[12px] text-gray-700">
                            Select the Card scheme that should be blocked from
                            BINN deposit
                          </div>
                        </div>
                        <div
                          className={`mx-6 ${isViewPermissionValid ? "disabled" : ""}`}
                        >
                          <IOSSwitch
                            defaultChecked={item?.rules?.isCardSchemeListActive}
                            onClick={(e) =>
                              isViewPermissionValid
                                ? null
                                : (() => {
                                    const payload = {
                                      isCardSchemeListActive: !e?.target
                                        ?.checked
                                        ? false
                                        : true,
                                      codeId: item?._id,
                                      uniqueId: acquireService?.uniqueId,
                                    };
                                    handletoggleActiveCountryCode(payload);
                                  })()
                            }
                          />
                        </div>
                        <div className="ml-8 w-2/5">
                          <div className="mb-2 text-[14px] ">Card scheme</div>
                          <div
                            className={`FilterModalInputDropdown ${isViewPermissionValid ? "disabled" : ""}`}
                            onClick={() =>
                              isViewPermissionValid
                                ? null
                                : setBlockedCardSchemeDropDown(true)
                            }
                          >
                            <div
                              className="FilterModalInputText"
                              data-e2e="crypto-currency-expand"
                            >
                              {cardSchemeData?.length ===
                                selectedCardScheme?.length ||
                              selectedCardScheme?.length === 0
                                ? "Select options"
                                : `${selectedCardScheme?.length} Selected`}
                            </div>
                            <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                          </div>

                          {blockedCardSchemeDropDown ? (
                            <CPMultiCountryCode
                              codeId={item}
                              dataE2eLabel="crypto-urrency-"
                              items={cardSchemeData}
                              field="cardSchemeData"
                              selectedItems={selectedCardScheme}
                              setShowDropDown={setBlockedCardSchemeDropDown}
                              setSelectedItems={(items) => {
                                setSelectedCardScheme(items || []);
                              }}
                              ItemComponent={({ item }) => {
                                return (
                                  <ItemComponent
                                    toggleAllowed={toggleCardScheme}
                                    item={item}
                                    selectedArray={selectedCardScheme}
                                  />
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>

                      <div className="rulesTab">
                        <div className="w-80">
                          <div className="text-[16px] font-medium">
                            Allowed Currencies
                          </div>
                          <div className="text-[12px] text-gray-700">
                            Select the allowed currencies for the acquirer.
                          </div>
                        </div>
                        <div
                          className={`mx-6 ${isViewPermissionValid ? "disabled" : ""}`}
                        >
                          <IOSSwitch
                            defaultChecked={
                              item?.rules?.isAllowedCurrenciesListActive
                            }
                            onClick={(e) =>
                              isViewPermissionValid
                                ? null
                                : (() => {
                                    const payload = {
                                      isAllowedCurrenciesListActive: !e?.target
                                        ?.checked
                                        ? false
                                        : true,
                                      codeId: item?._id,
                                      uniqueId: acquireService?.uniqueId,
                                    };
                                    handletoggleActiveCountryCode(payload);
                                  })()
                            }
                          />
                        </div>
                        <div className="ml-8 w-2/5">
                          <div className="mb-2 text-[14px] ">Currencies</div>
                          <div
                            className={`FilterModalInputDropdown ${isViewPermissionValid ? "disabled" : ""}`}
                            onClick={() =>
                              isViewPermissionValid
                                ? null
                                : setAllowedCurrenciesDropDown(true)
                            }
                          >
                            <div
                              className="FilterModalInputText"
                              data-e2e="crypto-currency-expand"
                            >
                              {AllCurrenciesData?.length ===
                                selectedAllowedCurrencies?.length ||
                              selectedAllowedCurrencies?.length === 0
                                ? "Select options"
                                : `${selectedAllowedCurrencies.length} Selected`}
                            </div>
                            <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                          </div>

                          {allowedCurrenciesDropDown ? (
                            <CPMultiCountryCode
                              codeId={item}
                              dataE2eLabel="crypto-urrency-"
                              items={AllCurrenciesData}
                              field="AllCurrenciesData"
                              selectedItems={selectedAllowedCurrencies}
                              setShowDropDown={setAllowedCurrenciesDropDown}
                              setSelectedItems={(items) => {
                                setSelectedAllowedCurrencies(items || []);
                              }}
                              ItemComponent={({ item: item }) => {
                                return (
                                  <ItemComponent
                                    toggleAllowed={toggleAllowedCurrency}
                                    item={item}
                                    selectedArray={selectedAllowedCurrencies}
                                  />
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="rulesTab">
                        <div className="w-80">
                          <div className="text-[16px] font-medium">
                            Ip Address Types
                          </div>
                          <div className="text-[12px] text-gray-700">
                            Select the IP Address types for the acquirer. (By
                            default both types will be checked)
                          </div>
                        </div>
                        <div className={`mx-6`}>
                          <IOSSwitch />
                        </div>
                        <div className="ml-8 w-2/5">
                          <div className="mb-2 text-[14px] ">
                            Ip Address Types
                          </div>
                          <div
                            className={`FilterModalInputDropdown ${isViewPermissionValid ? "disabled" : ""}`}
                            onClick={() =>
                              isViewPermissionValid
                                ? null
                                : setIpAddressTypesDropDown(true)
                            }
                          >
                            <div
                              className="FilterModalInputText"
                              data-e2e="crypto-currency-expand"
                            >
                              {constant?.AllIpAddressTypesData?.length ===
                                selectedIpAddressTypes?.length ||
                              selectedIpAddressTypes?.length === 0
                                ? "Select options"
                                : `${selectedIpAddressTypes.length} Selected`}
                            </div>
                            <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                          </div>

                          {ipAddressTypesDropDown ? (
                            <CPMultiCountryCode
                              codeId={item}
                              dataE2eLabel="crypto-urrency-"
                              items={constant.AllIpAddressTypesData}
                              field="AllIpAddressTypesData"
                              selectedItems={selectedIpAddressTypes}
                              setShowDropDown={setIpAddressTypesDropDown}
                              setSelectedItems={(items) => {
                                setSelectedIpAddressTypes(items || []);
                              }}
                              ItemComponent={({ item: item }) => {
                                return (
                                  <ItemComponent
                                  toggleAllowed={toggleIpAddressTypes}
                                  item={item}
                                  selectedArray={selectedIpAddressTypes}
                                />
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="rulesTab">
                        <div className="namcColumn w-80">
                          <div className="text-[16px] font-medium">
                            Mandatory Fields
                          </div>
                          <div className="text-[12px] text-gray-700">
                            Select the all mandatory fields for the acquirer.
                          </div>
                        </div>
                        <div
                          className={`switchColumn mx-6 ${isViewPermissionValid ? "disabled" : ""}`}
                        >
                          <IOSSwitch
                            defaultChecked={
                              item.rules.isMandatoryFieldsListActive
                            }
                            onClick={(e) =>
                              handleSwitchMandatoryFields(e, item)
                            }
                          />
                        </div>
                        <div className="ml-8 w-2/5">
                          <div className="mb-2 text-[14px]">Fields</div>
                          <div
                            className={`FilterModalInputDropdown ${isViewPermissionValid ? "disabled" : ""}`}
                            onClick={() =>
                              isViewPermissionValid
                                ? null
                                : setMandatoryFieldsDropDown(true)
                            }
                          >
                            <div
                              className="FilterModalInputText"
                              data-e2e="crypto-currency-expand"
                            >
                              {AllMandatoryFieldsData?.length ===
                                selectedMandatoryFields?.length ||
                              selectedMandatoryFields?.length === 0
                                ? "Select options"
                                : `${selectedMandatoryFields?.length} Selected`}
                            </div>
                            <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                          </div>

                          {mandatoryFieldsDropDown ? (
                            <CPMultiCountryCode
                              codeId={item}
                              items={AllMandatoryFieldsData}
                              field="AllMandatoryFieldsData"
                              selectedItems={selectedMandatoryFields}
                              setShowDropDown={setMandatoryFieldsDropDown}
                              setSelectedItems={(items) => {
                                setSelectedMandatoryFields(items || []);
                              }}
                              ItemComponent={({ item: item }) => {
                                return (
                                  <ItemComponent
                                    toggleAllowed={toggleMandatoryFields}
                                    item={item}
                                    selectedArray={selectedMandatoryFields}
                                  />
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              );
            })}
          </>
        ) : (
          <div className="p-4 text-center">No Data</div>
        )}
      </>
    </div>
  );
}

export default RulesTab;
