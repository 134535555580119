import Dialog from "@mui/material/Dialog";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from '../../../Redux/actions';
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { call, logoutAfterError } from "../../../../config/axios";
import {
  GET_TRANSACTIONS,
  GET_CRYPTOS,
  GET_ALL_MERCHANTS_LIST,
  GET_TRANSACTIONS_SUMMARY,
  GET_ALL_PAYMENT_PROVIDERS,
  GET_TRANSACTION_STATUS_CODES,
  GET_INTEGRATION_TYPE,
  GET_COUNTRY_CODE_FORM_DB
} from "../../../../config/endpoints";
import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";
import { setHeader } from "../../../Redux/actions/commonAction";
import HeaderAndFooterLayout from "../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../CommonPages/CommonHeader";
import DownloadCsv from "../CommonPages/DownloadCsv";
import TransactionFilters from "./TransactionFilters";
import TransactionTable from "./TransactionTable";
import { NUMBER_RECENT_DAYS_FILTER } from "./helpers";
import useDebounce from "../../global/useDebounce";
const searchicon = "/cryptonpay/crytoicons/searchicon1.png";
const filterIcon = "/cryptonpay/filter_icon.svg";
const downloadIcon = "/cryptonpay/download_icon.svg";

function Transactions(props) {
  const { search } = useLocation();
  const searchParams = queryString.parse(search);
  const { time } = searchParams
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal("email");
  const base_url = useSelector((state) => state.config.api_url);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [transactionFitlers, setTransactionFilters] = useState(false);
  const [downloadCsv, setDownloadCsv] = useState(false);
  const [ignoredFirstCallForDashboardCall, setIgnoredFirstCallForDashboardCall] = useState(false);
  const [ignoredFirstCallForDashboardCall2, setIgnoredFirstCallForDashboardCall2] = useState(false);
  const [exchangeRates, setExchangeRates] = useState([]);
  const [searchFlag, setSearchFlag] = useState(false);
  const [checkErrorDetailsExists, setCheckErrorDetailsExists] = useState(true);
  const [housekeeperList, setHousekeeperList] = useState([
    "Matched",
    "Unmatched"
  ]);
  const [selectedHousekeeperList, setSelectedHousekeeperList] = useState([
    "Matched",
    "Unmatched"
  ]);
  const statusGroupList = [
    "Success",
    "Processing",
    "Require Approval",
    "Pending",
    "Rejected",
    "Cancelled",
    "Abandoned",
    "Timed Out",];
  const types = [
    "deposit",
    "withdrawal",
    "Transfer",
    "Admin-Deposit",
    "MerchantWalletBalanceUpdate",
    "SweepTransfer"
  ];

  const methods = [
    "crypto",
    "card",
    "wallet",
  ]
  const fiatCurrencies = [
    {
      icon: "/cryptonpay/crytoicons/euro.png",
      name: "Euro",
      symbol: "EUR",
    },
    {
      icon: "/cryptonpay/crytoicons/usa.png",
      name: "US Dollar",
      symbol: "USD",
    },
    {
      icon: "/cryptonpay/crytoicons/uk.png",
      name: "British Pound",
      symbol: "GBP",
    },
  ];
  const [save, setSave] = useState(false);
  const networkList = ["mainnet", "testnet"];
  const [totalNumberOfTransactions, setTotalNumberOfTransactions] = useState(0);
  const [totalUsdAmount, setTotalUsdAmount] = useState(0);
  const [sumOfEachCrypto, setsumOfEachCrypto] = useState([]);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [trafficMaskedPan, settrafficMaskedPan] = useState();
  const [merchantTransactionId, setMerchantTransactionId] = useState("");
  const [acquirerTransactionReference, setAcquirerTransactionReference] = useState("");

  const handleTransactionFilters = () => {
    setTransactionFilters(false);
  };

  const handleSave = () => {
    setSave(!save);
    setCheckErrorDetailsExists(false);
  };
  const resetFilters = () => {
    props.setSearchText("");
    props.setOrderBy("desc");
    props.setSortBy("status.updated");
    setPage(1);
    props.setDateSelected(false);
    setTotalCount(0);
    props.setSelectedStatusList([]);
    props.setSelectedStatusGroupList([]);
    props.setFromDate([
      moment().subtract(6, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    ]);
    props.setToDate([moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ")]);
    props.setSelectedCryptos([]);
    props.setSelectedIssuerCountryList([]);
    props.setSelectedFiatCurrency([]);
    props.setSelectedNetworkList(["mainnet", "testnet"]);
    props.setSelectedType([]);
    props.setSelectedAcquirer([]);
    props.setSelectedMethod([]);
    props.setSelectedMerchantName([]);
    props.setMinFiatAmount(0);
    props.setMaxFiatAmount(0);
    props.changeIsContainsNote(false);
    props.setSelectedPaymentProvider([])
    settrafficMaskedPan()
    setMerchantTransactionId("");
    setSave(!save)
    setCheckErrorDetailsExists(false);
  };
  const setTime = () => {
    if (time && !props?.dateSelected) {
      props.setDateSelected(true)
      props.setFromDate(timedata(searchParams))
      props.setToDate(moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"))
    }
    else if( searchParams?.fromDate && searchParams?.toDate && !props?.dateSelected) {
      props.setDateSelected(true)
      props.setFromDate(moment(searchParams?.fromDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
      props.setToDate(moment(searchParams?.toDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
    }
  }


  const getTransactions = async (page, isDownloadCsv = false) => {
    let pageNo = 1;
    let allMode = false;

    if (page) {
      pageNo = page;
    }

    if (page === "all") {
      allMode = true;
    }

    let payload = {
      email: email,
      ...(localDb.getVal("paymentProviderId") && {
        paymentProviderId: localDb.getVal("paymentProviderId"),
      }),
      transactionId: props.searchText.toString(),
      walletAddress: props.searchText.toString(),
      trafficType: props.searchText.toString(),
      merchantUserId: props.searchText.toString(),
      merchantTransactionId: merchantTransactionId?.toString(),
      acquirerTransactionReference: acquirerTransactionReference?.toString(),
      merchantUserEmailAddress: props.searchText.toString(),
      merchant: props.searchText.toString(),
      orderBy: props.orderBy,
      sortBy: props.sortBy,
			fromDate: !searchFlag && moment().subtract(NUMBER_RECENT_DAYS_FILTER, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
			toDate: !searchFlag && moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      page: pageNo,
      CryptoCurrency:
        props.selectedCryptos.length === 0 ||
          props.selectedCryptos.length === props.cryptos.length
          ? "All"
          : props.selectedCryptos,
      network:
        props.selectedNetworkList.length === 0 ||
          props.selectedNetworkList.length === networkList.length
          ? "All"
          : props.selectedNetworkList,
      FiatCurrency:
        props.selectedFiatCurrency.length === 0 ||
          props.selectedFiatCurrency.length === fiatCurrencies.length
          ? "All"
          : props.selectedFiatCurrency,
      status:
        props.selectedStatusList.length === 0 ||
          props.selectedStatusList.length === props.statusList.length
          ? "All"
          : props.selectedStatusList,
      statusGroup:
        props.selectedStatusGroupList.length === 0 ||
          props.selectedStatusGroupList.length === statusGroupList.length
          ? "All"
          : props.selectedStatusGroupList,
      merchant_name:
        props.selectedMerchantName.length === 0 ||
          props.selectedMerchantName.length === props.merchantName.length
          ? "All"
          : props.selectedMerchantName,
      type:
        props.selectedType.length === 0 || props.selectedType.length === types.length
          ? "All"
          : props.selectedType,
      acquirer:
        props.selectedAcquirer.length === 0 || props.selectedAcquirer.length === props.acquirerNameData.length
          ? "All"
          : props.selectedAcquirer,
      method:
        !props.selectedMethod || props.selectedMethod.length === 0 || props.selectedMethod.length === methods.length
          ? "All"
          : props.selectedMethod,
      minFiatAmount: props.minFiatAmount === 0 ? "All" : props.minFiatAmount,
      maxFiatAmount: props.maxFiatAmount === 0 ? "All" : props.maxFiatAmount,
      isContainsNote: props.isContainsNote,
      ...(adminRole == "SuperAdmin" && {
        providers:
          props.selectedPaymentProvider.length === 0 ||
            props.selectedPaymentProvider.length === props.paymentProviderList.length
            ? "All"
            : props.selectedPaymentProvider,
      }),
       houseKeeper:
        selectedHousekeeperList.length === 0 ||
          selectedHousekeeperList.length === housekeeperList.length
          ? "All"
          : selectedHousekeeperList,
      issuerCountry:
      props?.selectedIssuerCountryList?.length === 0 ||
        props?.selectedIssuerCountryList?.length === props?.issuerCountryList?.length
        ? "All"
        : props?.selectedIssuerCountryList,
      trafficMaskedPan: trafficMaskedPan?.length === 0? "All": trafficMaskedPan,
    };

    if (props.dateSelected) {
      payload = {
        ...payload,
        fromDate: props.fromDate,
        toDate: props.toDate,
      };
    }
    if(searchParams?.routingService !== "All" && searchParams?.routingService?.length > 0){
      payload = {
        ...payload,
        routingService: JSON.parse(searchParams?.routingService)
      };
    }
    if(searchParams?.cardScheme !== "All" && searchParams?.cardScheme?.length > 0){
      payload = {
        ...payload,
        cardScheme: JSON.parse(searchParams?.cardScheme)
      };
    }
    if(searchParams?.acquirer !== "All" && searchParams?.acquirer?.length > 0) {
      payload = {
        ...payload,
        acquirer: JSON.parse(searchParams?.acquirer)
      };
    }
    if(searchParams?.issuerCountry !== "All" && searchParams?.issuerCountry?.length > 0) {
      payload = {
        ...payload,
        issuerCountry: JSON.parse(searchParams?.issuerCountry)
      };
    }
    if(searchParams?.errorDetails !== "All" && searchParams?.errorDetails?.length > 0) {
      payload = {
        ...payload,
        errorDetails: JSON.parse(searchParams?.errorDetails)
      };
    }
    if(checkErrorDetailsExists && searchParams?.errorDetailsType !== "All" && searchParams?.errorDetailsType?.length > 0) {
      payload = {
        ...payload,
        errorDetailsType: searchParams?.errorDetailsType
      };
    }
    if(searchParams?.trafficType) {
      payload = {
        ...payload,
        searchParamsTrafficType: JSON.parse(searchParams?.trafficType)
      };
    }
    if (allMode) {
      delete payload.page;
      payload = {
        ...payload,
        findAll: true,
      };
    }

    setTransactionLoading(true);
    await call(
      {
        ...GET_TRANSACTIONS,
        url: base_url + GET_TRANSACTIONS.url,
      },
      payload,
      {},
      isDownloadCsv
    )
      .then((res) => {
        if (res.status === 200) {
          if (allMode) {
            setAllData(res.data.transactionsList);
            setExchangeRates(res.data.exchangeRates)
            setTransactionLoading(false);
            return;
          }
          if (isMobile) {
            setData(res.data.transactionsList);
            setTotalCount(res.data.totalCount);
          } else {
            if (pageNo === 1) {
              setData(res.data.transactionsList);
              setTotalCount(res.data.totalCount);
            } else {
              setData(res.data.transactionsList);
              setTotalCount(res.data.totalCount);
            }
          }
          setTransactionLoading(false);
        }
      })
      .catch((err) => {
        setTransactionLoading(false);
        console.log(err.message, "Transactions > getTransactions > err", err);
        logoutAfterError(err)
      });
  };

  const name = "Transactions";

  const getMerchantList = async () => {
    setLoading(true);
    await call(
      {
        ...GET_ALL_MERCHANTS_LIST,
        url: base_url + GET_ALL_MERCHANTS_LIST.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          props.setMerchantName(res.data.merchants);
          props.setSelectedMerchantName(res.data.merchants);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message, "Transactions > getMerchantList > err", err);
        setLoading(false);
        logoutAfterError(err)
      });
  };
const getCountryCodesFromDb = async () => {
    setLoading(true);
    await call(
      {
        ...GET_COUNTRY_CODE_FORM_DB,
        url: base_url + GET_COUNTRY_CODE_FORM_DB.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res?.status === 200) {
          props.setIssuerCountryList(res?.data);
          props.setSelectedIssuerCountryList(res?.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err.message, "Transactions > getCountryCodesFromDb > err", err);
        setLoading(false);
        logoutAfterError(err)
      });
  }
  const getCryptos = async () => {
    setLoading(true);
    await call(
      {
        ...GET_CRYPTOS,
        url: base_url + GET_CRYPTOS.url,
      },
      { email: email }
    )
      .then((res) => {
        if (res.status === 200) {
          props.setCryptos(res.data.cryptos);

          let cryptos = res.data.cryptos.map((crypto) => {
            return crypto.symbol;
          });
          props.setSelectedCryptos(cryptos);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message, "Transactions > getCryptos > err", err);
        setLoading(false);
        logoutAfterError(err)
      });
  };

  const getAcquirerNames = async () => {
    setLoading(true);
    await call(
      {
        ...GET_INTEGRATION_TYPE,
        url: base_url + GET_INTEGRATION_TYPE.url,
      },
      { email: email }
    )
      .then((res) => {
        let list = []
        res.data.map(acq => {
          list.push(acq.acquireName)
        })
        props.setAcquirerNameData(list);
        props.setSelectedAcquirer(list);
      })
      .catch((err) => {
        console.log(err.message, "Transactions > getCryptos > err", err);
        setLoading(false);
        logoutAfterError(err)
      });
  };
  const getTransactionStatusCodes = async () => {
    setLoading(true);
    await call(
      {
        ...GET_TRANSACTION_STATUS_CODES,
        url: base_url + GET_TRANSACTION_STATUS_CODES.url,
      },
      { email: email, type: ["deposit", "withdrawal", "Transfer", "Admin-Deposit",] }
    )
      .then((res) => {
        if (res.status === 200) {
          props.setTransactionStatusCodes(res.data.transactionStatusCodes);
          let allStatus = {}
          res.data.transactionStatusCodes.map(Obj => {
            Obj.allStatusCodes.map(sts => {
              allStatus[sts] = sts
            })
          })
          props.setStatusList(Object.values(allStatus))
          if (searchParams.statusarry) {
            props.setSelectedStatusList(JSON.parse(searchParams.statusarry))
          } else if (!searchParams.status) {
            props.setSelectedStatusList(Object.values(allStatus))
          }
        }
      })
      .catch((err) => {
        console.log(err.message, "Transactions > getTransactionStatusCodes > err", err);
        logoutAfterError(err)
      });
  };

  const handleCloseDownloadCsv = () => {
    setDownloadCsv(false);
    getTransactions();
  };

  const handleDownloadTransaction = async () => {
    await getTransactions("all", true);
    setDownloadCsv(true);
  };

  const adminRole = localStorage.getItem("adminRole");
  useEffect(() => {
    if (searchParams.statusarry) {
      props.setSelectedStatusList(JSON.parse(searchParams.statusarry))
    }
    setTime()
    const initialStatusgroupList = statusgroup(searchParams, props.selectedStatusGroupList)
    const initialStatus = status(searchParams, props.selectedStatusList)
    if (initialStatus) {
      props.setSelectedStatusList(initialStatus)
    }
    props.setSelectedStatusGroupList(initialStatusgroupList);
    const initialMethod = getMethod(searchParams, props.selectedMethod);
    props.setSelectedMethod(initialMethod)
    const initialTrType = getTrType(searchParams, props.selectedType);
    props.setSelectedType(initialTrType)
    if (searchParams.paymentProvider) {
      props.setSelectedPaymentProvider(paymentProvider(searchParams, props.paymentProviderList))
    }
    if (searchParams.merchantName) { 
      props.setSelectedMerchantName(getmerchantName(searchParams, props.merchantName))
    }
    dispatch(setHeader("Merchant User Transactions"));
    if (props.cryptos.length === 0) {
      getCryptos();
    }
    if (props?.issuerCountryList?.length === 0) {
      getCountryCodesFromDb();
    }
    if (props.acquirerNameData.length === 0) {
      getAcquirerNames();
    }
    if (props.statusList.length === 0) {
      getTransactionStatusCodes();
    }
    if (props.merchantName.length === 0) {
      getMerchantList();
    }
    if (adminRole == "SuperAdmin" && props.paymentProviderList.length === 0) {
      getProviders();
    }
    if (Object.keys(searchParams).length > 0) {
      setSave(!save);
    }
  }, []);

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  const debouncedSearchText = useDebounce(props.searchText);
  
  useEffect(() => {
    if (Object.keys(searchParams).length === 0 || ignoredFirstCallForDashboardCall ) {
      getTransactions();
    }
    else{
      setIgnoredFirstCallForDashboardCall(true)
    }
    setTime()
  }, [debouncedSearchText, props.orderBy, props.sortBy, save, isMobile]);

  useEffect(() => {
    if (Object.keys(searchParams).length === 0 || ignoredFirstCallForDashboardCall2) {
      getTransactionSummary();
    }
    else{
      setIgnoredFirstCallForDashboardCall2(true)
    }
  }, [debouncedSearchText, save]);

  const getTransactionSummary = async () => {
    let payload = {
      ...(localDb.getVal("paymentProviderId") && {
        paymentProviderId: localDb.getVal("paymentProviderId"),
      }),
      email: email,
      transactionId: props.searchText.toString(),
      walletAddress: props.searchText.toString(),
      merchantUserId: props.searchText.toString(),
      trafficType: props?.searchText?.toString(),
      merchantTransactionId: merchantTransactionId?.toString(),
      acquirerTransactionReference: acquirerTransactionReference?.toString(),
      merchantUserEmailAddress: props.searchText.toString(),
      merchant: props.searchText.toString(),
			fromDate: !searchFlag && moment().subtract(NUMBER_RECENT_DAYS_FILTER, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
			toDate: !searchFlag && moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      CryptoCurrency:
        props.selectedCryptos.length === 0 ||
          props.selectedCryptos.length === props.cryptos.length
          ? "All"
          : props.selectedCryptos,
      network:
        props.selectedNetworkList.length === 0 ||
          props.selectedNetworkList.length === networkList.length
          ? "All"
          : props.selectedNetworkList,
      FiatCurrency:
        props.selectedFiatCurrency.length === 0 ||
          props.selectedFiatCurrency.length === fiatCurrencies.length
          ? "All"
          : props.selectedFiatCurrency,
      status:
        props.selectedStatusList.length === 0 ||
          props.selectedStatusList.length === props.statusList.length
          ? "All"
          : props.selectedStatusList,
      statusGroup:
        props.selectedStatusGroupList.length === 0 ||
          props.selectedStatusGroupList.length === statusGroupList.length
          ? "All"
          : props.selectedStatusGroupList,
      merchant_name:
        props.selectedMerchantName.length === 0 ||
          props.selectedMerchantName.length === props.merchantName.length
          ? "All"
          : props.selectedMerchantName,
      type:
        props.selectedType.length === 0 || props.selectedType.length === types.length
          ? "All"
          : props.selectedType,
      acquirer:
        props.selectedAcquirer.length === 0 || props.selectedAcquirer.length === props.acquirerNameData.length
          ? "All"
          : props.selectedAcquirer,
      method:
        !props.selectedMethod || props.selectedMethod.length === 0 || props.selectedMethod.length === methods.length
          ? "All"
          : props.selectedMethod,
      minFiatAmount: props.minFiatAmount === 0 ? "All" : props.minFiatAmount,
      maxFiatAmount: props.maxFiatAmount === 0 ? "All" : props.maxFiatAmount,
      ...(adminRole == "SuperAdmin" && {
        providers:
          props.selectedPaymentProvider.length === 0 ||
            props.selectedPaymentProvider.length === props.paymentProviderList.length
            ? "All"
            : props.selectedPaymentProvider,
      }),
      houseKeeper:
      selectedHousekeeperList.length === 0 ||
        selectedHousekeeperList.length === housekeeperList.length
        ? "All"
        : selectedHousekeeperList,
      issuerCountry:
      props?.selectedIssuerCountryList?.length === 0 ||
        props?.selectedIssuerCountryList?.length === props?.issuerCountryList?.length
        ? "All"
        : props?.selectedIssuerCountryList,
    };

    if (props.dateSelected) {
      payload = {
        ...payload,
        fromDate: props.fromDate,
        toDate: props.toDate,
      };
    }
    if(searchParams?.routingService !== "All" && searchParams?.routingService?.length > 0){
      payload = {
        ...payload,
        routingService: JSON.parse(searchParams?.routingService)
      };
    }
    if(searchParams?.cardScheme !== "All" && searchParams?.cardScheme?.length > 0){
      payload = {
        ...payload,
        cardScheme: JSON.parse(searchParams?.cardScheme)
      };
    }
    if(searchParams?.acquirer !== "All" && searchParams?.acquirer?.length > 0) {
      payload = {
        ...payload,
        acquirer: JSON.parse(searchParams?.acquirer)
      };
    }
    if(searchParams?.issuerCountry !== "All" && searchParams?.issuerCountry?.length > 0) {
      payload = {
        ...payload,
        issuerCountry: JSON.parse(searchParams?.issuerCountry)
      };
    }
    if(searchParams?.errorDetails !== "All" && searchParams?.errorDetails?.length > 0) {
      payload = {
        ...payload,
        errorDetails: JSON.parse(searchParams?.errorDetails)
      };
    }
    if(checkErrorDetailsExists && searchParams?.errorDetailsType !== "All" && searchParams?.errorDetailsType?.length > 0) {
      payload = {
        ...payload,
        errorDetailsType: searchParams?.errorDetailsType
      };
    }
    if(searchParams?.trafficType) {
      payload = {
        ...payload,
        searchParamsTrafficType: JSON.parse(searchParams?.trafficType)
      };
    }
    setLoading(true);
    await call(
      {
        ...GET_TRANSACTIONS_SUMMARY,
        url: base_url + GET_TRANSACTIONS_SUMMARY.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setTotalNumberOfTransactions(res.data.numberOfTransaction);
          setTotalUsdAmount(
            res.data.totalDepositAmount.length > 0
              ? res.data.totalDepositAmount[0].depositAmount
              : 0
          );
          setsumOfEachCrypto(res.data.sumOfCrypto);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(
          err.message,
          "Transactions > getTransactionSummary > err",
          err
        );
        logoutAfterError(err)
      });
  };

  const getProviders = async () => {
    let payload = {
      email,
      firstName: props.searchText,
    };
    setLoading(true);
    await call(
      {
        ...GET_ALL_PAYMENT_PROVIDERS,
        url: base_url + GET_ALL_PAYMENT_PROVIDERS.url,
      },
      payload
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          props.setPaymentProviderList(res.data.providerList);
          let providerId = [];
          if (res.data.providerList.length > 0) {
            providerId = res.data.providerList.map((e) => e._id);
          }
          if (searchParams.paymentProvider) {
            providerId = paymentProvider(searchParams)
          }
          props.setSelectedPaymentProvider(providerId);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message, "err");
        logoutAfterError(err)
      });
  };

  const resetSelectedStatus = (newTypeList) => {
    let allStatus = {}
    props.transactionStatusCodes.map(Obj => {
      if (newTypeList.includes(Obj.type)) {
        Obj.allStatusCodes.map(sts => {
          allStatus[sts] = sts
        })
      }
    })
    props.setStatusList(Object.values(allStatus))
    props.setSelectedStatusList(Object.values(allStatus));
  }
  return (
    <div className="pageContainer">
      <HeaderAndFooterLayout>
				<div className="">
					<CommonHeader name={name} />
					<div className="transactionFilter__allMerchants">
						<div className="transactionFilter">
							<input
								type="text"
								className="transactionFilter__input"
								value={props.searchText}
								name="searchText"
								data-e2e="search-textbox"
								onChange={(e) => {
                  e.target.value.trim() !== '' ? props.setSearchText(e.target.value):props.setSearchText('')
                }}
								placeholder="Search Merchant, Transaction ID, Merchant User ID or Merchant User Email"
							/>
							<img
								src={`${image_base_url}${searchicon}`}
								alt="searchicon"
								className="transactionFilter__icon"
								data-e2e="searchIcon"
								onClick={() => {
									getMerchantList();
								}}
							/>
						</div>

						<button type="button" data-e2e="filterBtn" className="transactionFilter__btn" onClick={() => {setTransactionFilters(true);}}>
							<img data-e2e="filterIcon" src={`${image_base_url}${filterIcon}`} alt="filterIcon" />
						</button>
						<button
              type="button"
              data-e2e="filterBtn"
              className={`transactionFilter__btn`}
              onClick={handleDownloadTransaction}
            >
              <img
                data-e2e="downloadIcon"
                src={`${image_base_url}${downloadIcon}`}
                alt="downloadIcon"
              />
            </button>
					</div>
					<div>
					<div className="transactionSummary">
						<p className="transactionSummary__title">Summary:</p>
						<p className="transactionSummary__text mb">
							Date Range  From:&nbsp;<span data-e2e="from-date" data-e2e-date1={props.dateSelected? moment(props.fromDate).format("YYYY-MM-DD") : moment().subtract(NUMBER_RECENT_DAYS_FILTER, "days").format("YYYY-MM-DD")}>{props.dateSelected? moment(props.fromDate).format("YYYY-MM-DD") : moment().subtract(NUMBER_RECENT_DAYS_FILTER, "days").format("YYYY-MM-DD")}</span>&nbsp; &nbsp;To:&nbsp;<span data-e2e="to-date" data-e2e-date2={props.dateSelected? moment(props.toDate).format("YYYY-MM-DD"):moment().format("YYYY-MM-DD")}>{props.dateSelected? moment(props.toDate).format("YYYY-MM-DD"):moment().format("YYYY-MM-DD")}</span>
            </p>
						<p className="transactionSummary__text mb">
							Number of transactions:&nbsp;<span data-e2e="no-of-transactions" data-e2e-tran={totalNumberOfTransactions}>{totalNumberOfTransactions}</span>&nbsp;|&nbsp;Net USD Equivalent:&nbsp;<span data-e2e="amount-usd" data-e2e-amount={parseFloat(totalUsdAmount).toFixed(2)}>{parseFloat(totalUsdAmount).toFixed(2)}</span>
						</p>
						<p className="transactionSummary__text">
							Sum by Crypto Amount:&nbsp;
							<span>
								{sumOfEachCrypto.length > 0 &&
									sumOfEachCrypto.map(
										(ele, index) =>
											ele._id !== null && (
												<span key={index}>
													{ele._id +
														": " +
														parseFloat(ele.sum).toFixed(4) +
														(index != sumOfEachCrypto.length - 1
															? "|"
															: "")}
												</span>
											)
									)}
							</span>
						</p>
					</div>

          <div>
            {/* Transaction table start */}
            <TransactionTable
              data={data}
              changeOrder={(sort) => {
                if (props.sortBy !== sort) {
                  props.setOrderBy("desc");
                  props.setSortBy(sort);
                } else {
                  props.setOrderBy(props.orderBy === "desc" ? "asc" : "desc");
                }
              }}
							setPage={(page) => {
								setPage(page)
								getTransactions(page)
							}}
              page={page}
              totalCount={totalCount}
            />
          </div>
        </div>
				</div>
				<Dialog
					open={transactionFitlers}
					onClose={handleTransactionFilters}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<TransactionFilters
						showAcquirerDropD={true}
						aquireNameData={props.acquirerNameData}
						merchantNameList={props.merchantName}
						isContainsNote={props.isContainsNote}
						changeIsContainsNote={() => {
							props.changeIsContainsNote(!props.isContainsNote);
						}}
						selectedMerchantName={props.selectedMerchantName}
						setSelectedMerchantName={props.setSelectedMerchantName}
						minFiatAmount={props.minFiatAmount}
						maxFiatAmount={props.maxFiatAmount}
						setMinFiatAmount={props.setMinFiatAmount}
						setMaxFiatAmount={props.setMaxFiatAmount}
						networkList={networkList}
						selectedNetworkList={props.selectedNetworkList}
						setSelectedNetworkList={props.setSelectedNetworkList}
						handleTransactionFilters={handleTransactionFilters}
						statusList={props.statusList}
						statusGroupList={statusGroupList}
						setStatusList={props.setStatusList}
						selectedStatusList={props.selectedStatusList}
						selectedStatusGroupList={props.selectedStatusGroupList}
						resetSelectedStatus={resetSelectedStatus}
						setSelectedStatusList={props.setSelectedStatusList}
						setSelectedStatusGroupList={props.setSelectedStatusGroupList}
						fromDate={moment(props.fromDate)}
						toDate={moment(props.toDate)}
						setFromDate={props.setFromDate}
						setToDate={props.setToDate}
						handleSave={handleSave}
						setDateSelected={props.setDateSelected}
						dateSelected={props.dateSelected}
						resetFilters={resetFilters}
						cryptos={props.cryptos}
						selectedCryptos={props.selectedCryptos}
						setSelectedCryptos={props.setSelectedCryptos}
						fiatCurrency={fiatCurrencies}
						selectedFiatCurrency={props.selectedFiatCurrency}
						setSelectedFiatCurrency={props.setSelectedFiatCurrency}
						type={types}
						selectedType={props.selectedType}
						selectedAcquirer={props.selectedAcquirer}
						setSelectedType={props.setSelectedType}
						setSelectedAcquirer={props.setSelectedAcquirer}
						method={methods}
						adminRole={adminRole}
						paymentProviderList={props.paymentProviderList}
						setPaymentProviderList={props.setPaymentProviderList}
						selectedPaymentProvider={props.selectedPaymentProvider}
						setSelectedPaymentProvider={props.setSelectedPaymentProvider}
						selectedMethod={props.selectedMethod}
						setSelectedMethod={props.setSelectedMethod}
            housekeeperList={housekeeperList}
            setHousekeeperList={setHousekeeperList}
            selectedHousekeeperList={selectedHousekeeperList}
            setSelectedHousekeeperList={setSelectedHousekeeperList}
            issuerCountryList={props?.issuerCountryList}
            selectedIssuerCountryList={props?.selectedIssuerCountryList}
            setSelectedIssuerCountryList={props?.setSelectedIssuerCountryList}
            settrafficMaskedPan={settrafficMaskedPan}
            merchantTransactionId={merchantTransactionId}
            setMerchantTransactionId ={setMerchantTransactionId}
            acquirerTransactionReference={acquirerTransactionReference}
            setAcquirerTransactionReference={setAcquirerTransactionReference}
					/>
				</Dialog>

				<Dialog
					open={downloadCsv}
					onClose={handleCloseDownloadCsv}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DownloadCsv
						handleCloseDownloadCsv={handleCloseDownloadCsv}
						data={allData}
            exchangeRates={exchangeRates}
						resetFilters={resetFilters}
						name="transactions"
						data-e2e="transactions"
					/>
				</Dialog>

        
      </HeaderAndFooterLayout>
      {(loading || transactionLoading) && <Loading />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    searchText: state.transaction.searchText,
    orderBy: state.transaction.orderBy,
    sortBy: state.transaction.sortBy,
    fromDate: state.transaction.fromDate,
    toDate: state.transaction.toDate,
    dateSelected: state.transaction.dateSelected,
    selectedStatusList: state.transaction.selectedStatusList,
    selectedStatusGroupList: state.transaction.selectedStatusGroupList,
    statusList: state.transaction.statusList,
    transactionStatusCodes: state.transaction.transactionStatusCodes,
    selectedMethod: state.transaction.selectedMethod,
    selectedType: state.transaction.selectedType,
    acquirerNameData: state.transaction.acquirerNameData,
    selectedAcquirer: state.transaction.selectedAcquirer,
    cryptos: state.transaction.cryptos,
    selectedCryptos: state.transaction.selectedCryptos,
    selectedFiatCurrency: state.transaction.selectedFiatCurrency,
    selectedNetworkList: state.transaction.selectedNetworkList,
    minFiatAmount: state.transaction.minFiatAmount,
    maxFiatAmount: state.transaction.maxFiatAmount,
    merchantName: state.transaction.merchantName,
    selectedMerchantName: state.transaction.selectedMerchantName,
    isContainsNote: state.transaction.isContainsNote,
    paymentProviderList: state.transaction.paymentProviderList,
    selectedPaymentProvider: state.transaction.selectedPaymentProvider,
    issuerCountryList: state?.transaction?.issuerCountryList,
    selectedIssuerCountryList: state?.transaction?.selectedIssuerCountryList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchText: (value) => dispatch(actions.setSearchText(value)),
    setOrderBy: (value) => dispatch(actions.setOrderBy(value)),
    setSortBy: (value) => dispatch(actions.setSortBy(value)),
    setFromDate: (value) => dispatch(actions.setFromDate(value)),
    setToDate: (value) => dispatch(actions.setToDate(value)),
    setDateSelected: (value) => dispatch(actions.setDateSelected(value)),
    setSelectedStatusList: (value) => dispatch(actions.setSelectedStatusList(value)),
    setSelectedStatusGroupList: (value) => dispatch(actions.setSelectedStatusGroupList(value)),
    setStatusList: (value) => dispatch(actions.setStatusList(value)),
    setTransactionStatusCodes: (value) => dispatch(actions.setTransactionStatusCodes(value)),
    setSelectedMethod: (value) => dispatch(actions.setSelectedMethod(value)),
    setSelectedType: (value) => dispatch(actions.setSelectedType(value)),
    setAcquirerNameData: (value) => dispatch(actions.setAcquirerNameData(value)),
    setSelectedAcquirer: (value) => dispatch(actions.setSelectedAcquirer(value)),
    setCryptos: (value) => dispatch(actions.setCryptos(value)),
    setSelectedCryptos: (value) => dispatch(actions.setSelectedCryptos(value)),
    setIssuerCountryList: (value) => dispatch(actions.setIssuerCountryList(value)),
    setSelectedIssuerCountryList: (value) => dispatch(actions.setSelectedIssuerCountryList(value)),
    setSelectedFiatCurrency: (value) => dispatch(actions.setSelectedFiatCurrency(value)),
    setSelectedNetworkList: (value) => dispatch(actions.setSelectedNetworkList(value)),
    setMinFiatAmount: (value) => dispatch(actions.setMinFiatAmount(value)),
    setMaxFiatAmount: (value) => dispatch(actions.setMaxFiatAmount(value)),
    setMerchantName: (value) => dispatch(actions.setMerchantName(value)),
    setSelectedMerchantName: (value) => dispatch(actions.setSelectedMerchantName(value)),
    changeIsContainsNote: (value) => dispatch(actions.changeIsContainsNote(value)),
    setPaymentProviderList: (value) => dispatch(actions.setPaymentProviderList(value)),
    setSelectedPaymentProvider: (value) => dispatch(actions.setSelectedPaymentProvider(value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);

const getTrType = (searchParams, defaultList) => {
  const { type } = searchParams
  if (searchParams && type) {
    const capitalMehtod = type.charAt(0).toUpperCase()+type.slice(1)
    return [type, capitalMehtod]
  } else {
    return defaultList
  }
}

const getMethod = (searchParams, defaultList) => {
  const { method } = searchParams
  if (searchParams && method && method !== "") {
    const capitalMehtod = method.charAt(0).toUpperCase()+method.slice(1)
    return [method, capitalMehtod]
  } else {
    return defaultList;
  }
}

const statusgroup = (searchParams, defaultList) => {
  const { statusgroup, exceptList } = searchParams
  if (searchParams && exceptList) {
    let statusarry = [
      "Success",
      "Processing",
      "Require Approval",
      "Pending",
      "Rejected",
      "Cancelled",
      "Abandoned",
      "Timed Out",]

      let exceptArray = exceptList?.split(',');
      statusarry = statusarry?.filter(status => !exceptArray?.includes(status));
      return statusarry;
  } 
  else if (searchParams && statusgroup) {
    let statusgrparry = statusgroup.split(',')
    return statusgrparry
  } else {
    return defaultList;
  }
}

const status = (searchParams, defaultList) => {
  if (searchParams.statusarry) {
    let array = JSON.parse(searchParams.statusarry)
    return array
  } else if (searchParams && searchParams.status) {
    return [searchParams.status]
  } else {
    return defaultList;
  }
}
const timedata = (searchParams) => {
  const { time } = searchParams
  if (time === "Last 30 Min") {
    return moment().subtract(30, "minutes").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  } else if (time === "Last 1 Hour") {
    return moment().subtract(60, "minutes").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  } else if (time === "Last 24 Hour") {
    return moment().subtract(1, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  } else if (time ==="Last 48 Hour"){
  return moment().subtract(2, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  }else if(time === "Last 7 Days") {
    return moment().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  }
}
const paymentProvider = (searchParams, defaultList) => {
  const { paymentProvider } = searchParams;
  const paymentProviderName = JSON.parse(paymentProvider)
  if (paymentProviderName.length > 0) {
    return paymentProviderName
  } else {
    return defaultList
  }
}
const getmerchantName = (searchParams, defaultList) => {
  const { merchantName } = searchParams;
  const merchantNameData = merchantName === "All" ? merchantName : JSON.parse(merchantName)
  if (merchantNameData.length > 0) {
    return merchantNameData
  } else {
    return defaultList
  }
}