import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
const CPMultiMerchantCode = ({
    codeId,
    dataE2eLabel,
    items,
    selectedItems,
    ItemComponent,
    setShowDropDown,
    containerStyle = {},
  }) => {
    const baseURL = useSelector((state) => state.config.api_url);
    const { id } = useParams();
  
    const handleAddNewAquirer = async () => {
      try {
        let payload = {
          id: id,
          codeId: codeId._id,
          merchantCountryCode: selectedItems,
        };
      } catch (err) {
        console.log(err);
      }
    };
  
    const onClickCurrencyDrop = () => {
      handleAddNewAquirer();
      setShowDropDown(false);
    };
  
    return (
      <div style={containerStyle} className="FilterModalCurrencyDropdownlist rulesTab">
        <div className="sticky w-full">
          <div className="modalDropdownList">
            <div
              className="FM_currencyDrop_All"
              onClick={() => {
                onClickCurrencyDrop();
              }}
            >
              <span className="FM_currencyDrop_AllSelector">
                {selectedItems.length === items.length ||
                selectedItems.length === 0
                  ? "Select options"
                  : `${selectedItems.length} Selected`}
              </span>
              <span className="FilterModalInputDropUpIconWrap">
                <i
                  className="fa fa-chevron-up FilterModalInputDropUpIcon"
                  data-e2e={`${dataE2eLabel}contract-icon`}
                />
              </span>
            </div>
          </div>
        </div>
        {items.map((item, index) => {
          return <ItemComponent item={item} key={index} />;
        })}
      </div>
    );
  };
export default CPMultiMerchantCode