export const integrationData = [
  {
    _id: "6426d3f485595d34f32dfd61",
    acquireName: "Golden Gate Payments",
    __v: 0,
  },
  {
    _id: "6436a4a8bff75f36663ec586",
    acquireName: "Axcess Payments",
    __v: 0,
  },
  {
    _id: "64882bd68596c32164a1ea8b",
    acquireName: "Brava Payments",
    __v: 0,
  },
  {
    _id: "6507f75eb50bc86b133e1dfc",
    acquireName: "AltPayNet",
    __v: 0,
  },
  {
    _id: "653f74437bbd025195e5f9e6",
    acquireName: "Pay.com",
    __v: 0,
  },
  {
    _id: "65e6b55cc8fe6fd5fbfcec84",
    acquireName: "WorldCard",
    __v: 0,
  },
  {
    _id: "660d0f839ee1ad90ffad2962",
    acquireName: "Fintech",
    __v: 0,
  },
  {
    _id: "664f142f1d8767fbb09b4fac",
    acquireName: "Baers Crest",
    __v: 0,
  },
  {
    _id: "66617cdc4f0a30b8586a3b78",
    acquireName: "SwitchFin Payments",
    __v: 0,
  },
  {
    _id: "66711af5ddc19351bf519665",
    acquireName: "PaymentEmpire",
    __v: 0,
  },
  {
    _id: "66727be7954e9cfe933c03e4",
    acquireName: "Trust Payments",
    __v: 0,
  },
  {
    _id: "66755e392e1539cd5cc70fb4",
    acquireName: "Nevo4",
    __v: 0,
  },
  {
    _id: "667ab550506d53c922036fbf",
    acquireName: "Nevo4_1",
  },
  {
    _id: "66c5c840c8b9e7ccd49a42d8",
    acquireName: "Truevo",
    __v: 0,
  },
];

export const AcquirerData = {
  "loadBalancing": {
      "maxTrafficFlag": true,
      "successRoutingFlag": true,
      "leaseCostRoutingFlag": true,
      "weightingFlag": false,
      "failoverFlag": false
  },
  "_id": "642535eefb465823cd680f93",
  "uniqueId": "0xc49f37142952a11577b9caec2da0d3f606eae0b88606fb1e8e8d796f5ed3d0a2",
  "createdDate": "2023-03-30T07:10:38.068Z",
  "acquireRoutingServiceName": "CryptonPay",
  "routingService": [
      {
          "rules": {
              "isCountryCodeActive": true,
              "isMerchantCountryCodeActive": true,
              "isCardSchemeListActive": true,
              "isAllowedCurrenciesListActive": true,
              "isMandatoryFieldsListActive": true,
              "cardSchemeList": [],
              "cardCountryCode": [],
              "merchantCountryCode": [
                  {
                      "_id": "64c7441282c8e86f700b8d71",
                      "name": "Albania",
                      "dial_code": "+355",
                      "code": "AL"
                  },
                  {
                      "_id": "64c7441282c8e86f700b8d81",
                      "name": "Barbados",
                      "dial_code": "+1 246",
                      "code": "BB"
                  }
              ],
              "allowedCurrenciesList": [
                  {
                      "name": "EUR"
                  },
                  {
                      "name": "USD"
                  }
              ],
              "mandatoryFieldsList": [
                  {
                      "name": "payerAddress"
                  },
                  {
                      "name": "payerState"
                  },
                  {
                      "name": "payerCountry"
                  },
                  {
                      "name": "payerCity"
                  },
                  {
                      "name": "payerZip"
                  },
                  {
                      "name": "payerPhone"
                  },
                  {
                      "name": "merchantUserEmail"
                  },
                  {
                      "name": "browserUserAgent"
                  }
              ],
              "acceptFtd": true,
              "ipAddressTypes": [
                  "ipv6",
                  "ipv4"
              ]
          },
          "isAcquireServiceActive": false,
          "_id": "65ea08a7412ab453fcdd2260",
          "acquirerName": "GoldenGatewayPayments1",
          "integrationType": {
              "_id": "6426d3f485595d34f32dfd61",
              "acquireName": "Golden Gate Payments",
              "__v": 0
          },
          "jwtPassword": "N/A",
          "jwtUsername": "N/A",
          "livestatus": "0",
          "notes": null,
          "pwd": "X@IZv*QHtm66DbgE",
          "siteReferenceName": "N/A",
          "tokenType": "2xUhFSsrYF5prDqA",
          "username": "client_l"
      },
      {
          "rules": {
              "ipAddressTypes": [],
              "isCountryCodeActive": true,
              "isMerchantCountryCodeActive": true,
              "isCardSchemeListActive": true,
              "isAllowedCurrenciesListActive": true,
              "isMandatoryFieldsListActive": true,
              "cardSchemeList": [],
              "cardCountryCode": [],
              "merchantCountryCode": [
                  {
                      "_id": "64c7441282c8e86f700b8d70",
                      "name": "Afghanistan",
                      "dial_code": "+93",
                      "code": "AF"
                  },
                  {
                      "_id": "64c7441282c8e86f700b8d71",
                      "name": "Albania",
                      "dial_code": "+355",
                      "code": "AL"
                  },
                  {
                      "_id": "64c7441282c8e86f700b8d72",
                      "name": "Algeria",
                      "dial_code": "+213",
                      "code": "DZ"
                  }
              ],
              "allowedCurrenciesList": [],
              "mandatoryFieldsList": [
                  {
                      "name": "payerCountry"
                  }
              ],
              "acceptFtd": true,
              "successTnxFTDToTrusted": 2
          },
          "isAcquireServiceActive": false,
          "_id": "65ea82965163bd9f72bf71c4",
          "integrationType": {
              "_id": "6436a4a8bff75f36663ec586",
              "acquireName": "Axcess Payments",
              "__v": 0
          },
          "acquirerName": "AxcessPayments1",
          "tokenType": "N/A",
          "siteReferenceName": "N/A",
          "username": "232025",
          "pwd": "bZV{cPEt5^U~6c%@",
          "jwtUsername": "N/A",
          "jwtPassword": "N/A",
          "livestatus": "0",
          "notes": null
      },
      {
          "rules": {
              "ipAddressTypes": [],
              "isCountryCodeActive": true,
              "isMerchantCountryCodeActive": true,
              "isCardSchemeListActive": true,
              "isAllowedCurrenciesListActive": true,
              "isMandatoryFieldsListActive": true,
              "cardSchemeList": [],
              "cardCountryCode": [
                  {
                      "_id": "64c7441282c8e86f700b8d9f",
                      "name": "Cuba",
                      "dial_code": "+53",
                      "code": "CU"
                  }
              ],
              "merchantCountryCode": [],
              "allowedCurrenciesList": [
                  {
                      "name": "GBP"
                  },
                  {
                      "name": "USD"
                  },
                  {
                      "name": "EUR"
                  }
              ],
              "mandatoryFieldsList": [
                  {
                      "name": "payerZip"
                  }
              ],
              "acceptFtd": true,
              "successTnxFTDToTrusted": 2
          },
          "isAcquireServiceActive": false,
          "_id": "65ea82dd5163bd9f72bf71c9",
          "integrationType": {
              "_id": "653f74437bbd025195e5f9e6",
              "acquireName": "Pay.com",
              "__v": 0
          },
          "acquirerName": "Pay.com1",
          "tokenType": "N/A",
          "siteReferenceName": "N/A",
          "username": "N/A",
          "pwd": "staging_HNLgDJ2zvzw9nSDeOd1tLeDcLy2oyTv7",
          "jwtUsername": "N/A",
          "jwtPassword": "N/A",
          "livestatus": "0",
          "notes": null
      },
      {
          "rules": {
              "ipAddressTypes": [],
              "isCountryCodeActive": true,
              "isMerchantCountryCodeActive": true,
              "isCardSchemeListActive": true,
              "isAllowedCurrenciesListActive": true,
              "isMandatoryFieldsListActive": true,
              "cardSchemeList": [],
              "cardCountryCode": [],
              "merchantCountryCode": [],
              "allowedCurrenciesList": [
                  {
                      "name": "GBP"
                  },
                  {
                      "name": "USD"
                  },
                  {
                      "name": "EUR"
                  }
              ],
              "mandatoryFieldsList": [],
              "acceptFtd": true,
              "successTnxFTDToTrusted": 5
          },
          "isAcquireServiceActive": false,
          "_id": "65ea8f785163bd9f72bf72db",
          "integrationType": {
              "_id": "65e6b55cc8fe6fd5fbfcec84",
              "acquireName": "WorldCard",
              "__v": 0
          },
          "acquirerName": "WorldCard1",
          "tokenType": "OGFjN2E0Yzk3NDlhYTExMTAxNzQ5YWYwYmU3MDAwZjR8Q2VGbW5CN2dQWg==",
          "siteReferenceName": "N/A",
          "username": "8ac7a4c889d736480189d97698e10287",
          "pwd": "D9822A573298AEDAA3613FBC6170B4D8EBA68E4BE9AFD7A0B6DC9296BCFFF3B4",
          "jwtUsername": "N/A",
          "jwtPassword": "N/A",
          "livestatus": "0",
          "notes": null
      },
      {
          "rules": {
              "ipAddressTypes": [],
              "isCountryCodeActive": true,
              "isMerchantCountryCodeActive": true,
              "isCardSchemeListActive": true,
              "isAllowedCurrenciesListActive": true,
              "isMandatoryFieldsListActive": true,
              "cardSchemeList": [],
              "cardCountryCode": [],
              "merchantCountryCode": [],
              "allowedCurrenciesList": [
                  {
                      "name": "USD"
                  },
                  {
                      "name": "EUR"
                  }
              ],
              "mandatoryFieldsList": [],
              "acceptFtd": true,
              "successTnxFTDToTrusted": 2
          },
          "isAcquireServiceActive": true,
          "_id": "65ea90225163bd9f72bf72ed",
          "integrationType": {
              "_id": "64882bd68596c32164a1ea8b",
              "acquireName": "Brava Payments",
              "__v": 0
          },
          "acquirerName": "BravaPayments1",
          "tokenType": "N/A",
          "siteReferenceName": "N/A",
          "username": "7b5833ae-2ae4-11ee-ac59-fecb31b92db6",
          "pwd": "cb4236a7e484e702dc237d1b19f5a30e",
          "jwtUsername": "N/A",
          "jwtPassword": "N/A",
          "livestatus": "0",
          "notes": null
      },
      {
          "rules": {
              "ipAddressTypes": [],
              "isCountryCodeActive": true,
              "isMerchantCountryCodeActive": true,
              "isCardSchemeListActive": true,
              "isAllowedCurrenciesListActive": true,
              "isMandatoryFieldsListActive": true,
              "cardSchemeList": [],
              "cardCountryCode": [],
              "merchantCountryCode": [],
              "allowedCurrenciesList": [
                  {
                      "name": "GBP"
                  },
                  {
                      "name": "USD"
                  },
                  {
                      "name": "EUR"
                  }
              ],
              "mandatoryFieldsList": [
                  {
                      "name": "browser_color_depth"
                  },
                  {
                      "name": "browser_language"
                  },
                  {
                      "name": "browser_screen_height"
                  },
                  {
                      "name": "browser_screen_width"
                  },
                  {
                      "name": "browser_timezone"
                  },
                  {
                      "name": "browserUserAgent"
                  },
                  {
                      "name": "merchantTransactionId"
                  },
                  {
                      "name": "merchantUserEmail"
                  },
                  {
                      "name": "os"
                  },
                  {
                      "name": "payerAddress"
                  },
                  {
                      "name": "payerCity"
                  },
                  {
                      "name": "payerCountry"
                  },
                  {
                      "name": "payerPhone"
                  },
                  {
                      "name": "payerState"
                  },
                  {
                      "name": "payerZip"
                  }
              ],
              "acceptFtd": true,
              "successTnxFTDToTrusted": 2
          },
          "isAcquireServiceActive": false,
          "_id": "65ea93375163bd9f72bf7397",
          "integrationType": {
              "_id": "6507f75eb50bc86b133e1dfc",
              "acquireName": "AltPayNet",
              "__v": 0
          },
          "acquirerName": "AltPayNet1",
          "tokenType": "N/A",
          "siteReferenceName": "N/A",
          "username": "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBALC37YAss5sk70BHO7vocqvwG4vUa34djBiWwc6VGgQFWxHWUFayXGGiEZ54jN2L3XFYmf6QmLJ21uiKnnGM0gsCAwEAAQ==",
          "pwd": "MIIBVgIBADANBgkqhkiG9w0BAQEFAASCAUAwggE8AgEAAkEAsLftgCyzmyTvQEc7u+hyq/Abi9Rrfh2MGJbBzpUaBAVbEdZQVrJcYaIRnniM3YvdcViZ/pCYsnbW6IqecYzSCwIDAQABAkEAl7mTBFVc73tKn1mJg9AThGPmgquijuk3e3UUeRWy/HdbRyO5EaieJkUy2Vt+URAHyxPA1uvp8vp5+0wWrSO0YQIhAPd5K5Ge5loe52cut3bW4s8BgZbUgl8/TybPEG7R4sONAiEAts6qtShLCTxc1B4yEIcKfOgUitKk57A+8PzcEUNG+fcCIQDexDJWCUWuRjdjiIgZ7+GHGgH/NhiNiL3FX4uZVlHcWQIgYhdXJknuqZAIR5HET3YGvKtTioIpFmFOBMXJ2EF/blUCIQC5FlZfirItQFSn1Fr4xAsyEXqxbDs+B4HGViXqHL+o1g==",
          "jwtUsername": "N/A",
          "jwtPassword": "N/A",
          "livestatus": "0",
          "notes": null
      },
      {
          "rules": {
              "isCountryCodeActive": true,
              "isMerchantCountryCodeActive": true,
              "isCardSchemeListActive": true,
              "isAllowedCurrenciesListActive": true,
              "isMandatoryFieldsListActive": true,
              "cardSchemeList": [
                  {
                      "name": "American Express"
                  }
              ],
              "cardCountryCode": [
                  {
                      "_id": "64c7441282c8e86f700b8e31",
                      "name": "Yemen",
                      "dial_code": "+967",
                      "code": "YE"
                  }
              ],
              "merchantCountryCode": [],
              "allowedCurrenciesList": [
                  {
                      "name": "EUR"
                  },
                  {
                      "name": "GBP"
                  },
                  {
                      "name": "USD"
                  }
              ],
              "mandatoryFieldsList": [
                  {
                      "name": "payerAddress"
                  },
                  {
                      "name": "merchantUserEmail"
                  },
                  {
                      "name": "payerPhone"
                  },
                  {
                      "name": "payerCity"
                  },
                  {
                      "name": "payerCountry"
                  },
                  {
                      "name": "payerState"
                  },
                  {
                      "name": "payerZip"
                  },
                  {
                      "name": "ipAddress"
                  }
              ],
              "acceptFtd": true,
              "successTnxFTDToTrusted": 4,
              "ipAddressTypes": []
          },
          "isAcquireServiceActive": false,
          "_id": "660d0fe09ee1ad90ffad2969",
          "integrationType": {
              "_id": "660d0f839ee1ad90ffad2962",
              "acquireName": "Fintech",
              "__v": 0
          },
          "acquirerName": "Fintech1",
          "tokenType": "N/A",
          "siteReferenceName": "N/A",
          "username": "9cf47e639c72b3135e7949af560bbc6b1eed561589b9af570efb0563f0434770",
          "pwd": "977697-hbnu9c-437897-yph397-753892-yorojp",
          "jwtUsername": "N/A",
          "jwtPassword": "N/A",
          "livestatus": "0",
          "notes": null
      },
      {
          "rules": {
              "ipAddressTypes": [],
              "cardCountryCode": [
                  {
                      "_id": "64c7441282c8e86f700b8e32",
                      "name": "Zambia",
                      "dial_code": "+260",
                      "code": "ZM"
                  }
              ],
              "merchantCountryCode": [],
              "isCountryCodeActive": true,
              "isMerchantCountryCodeActive": true,
              "isCardSchemeListActive": true,
              "isAllowedCurrenciesListActive": true,
              "isMandatoryFieldsListActive": true,
              "cardSchemeList": [
                  {
                      "name": "Diners"
                  },
                  {
                      "name": "Mastercard"
                  }
              ],
              "allowedCurrenciesList": [
                  {
                      "name": "USD"
                  }
              ],
              "mandatoryFieldsList": [],
              "acceptFtd": true,
              "successTnxFTDToTrusted": 4
          },
          "integrationType": {
              "_id": "664f142f1d8767fbb09b4fac",
              "acquireName": "Baers Crest",
              "__v": 0
          },
          "acquirerName": "BaersCrest1",
          "tokenType": "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuLLH+vLbxnVLcujPwR1D HEsmrj8A7hZekieNO5WLgKRxPtU+Z1kxyzvjtjcFYTg6kASuoBaP4nsmBbrrnjxy vB3brF9enIYCqfdJVyN4qHmc2vqrUCtcMCd0nPYwIHHEtETT8RVyRmWlaWHmlbLt 8kI0XYtGsYZ2M19WDTgguNedBC9RZPML1pugYTaUKadm2VvU3jacD1YTHymleDGC DAxjMtGWZf7sR0FjT/9DhhLp+YTKfGgt3YbF0FNcH10Ieerq8cEqkN83hRM6WXnQ orjdXLWiqkpY+qlimVLBd+jMsTFO6EJ4e9EFQ/mSXVsH4TuKAjmkqeUitkx1vT2u tQIDAQAB",
          "siteReferenceName": "N/A",
          "username": "nick@cryptonpay.com",
          "pwd": "EwZI%(m9wq$RN6kt",
          "jwtUsername": "N/A",
          "jwtPassword": "N/A",
          "livestatus": "0",
          "notes": null,
          "isAcquireServiceActive": false,
          "_id": "664f14901d8767fbb09b59d0"
      },
      {
          "rules": {
              "cardCountryCode": [],
              "merchantCountryCode": [],
              "isCountryCodeActive": true,
              "isMerchantCountryCodeActive": true,
              "isCardSchemeListActive": true,
              "isAllowedCurrenciesListActive": true,
              "isMandatoryFieldsListActive": true,
              "cardSchemeList": [
                  {
                      "name": "JCB"
                  }
              ],
              "allowedCurrenciesList": [
                  {
                      "name": "GBP"
                  },
                  {
                      "name": "USD"
                  }
              ],
              "mandatoryFieldsList": [],
              "acceptFtd": true,
              "successTnxFTDToTrusted": 0,
              "ipAddressTypes": []
          },
          "integrationType": {
              "_id": "66617cdc4f0a30b8586a3b78",
              "acquireName": "SwitchFin Payments",
              "__v": 0
          },
          "acquirerName": "Switch Fin 1",
          "tokenType": "N/A",
          "siteReferenceName": "N/A",
          "username": "N/A",
          "pwd": "cac5196b-61ab-4124-a241-a81b5ef4567a",
          "jwtUsername": "N/A",
          "jwtPassword": "ccfbcc1f19fffe2d3eda314e6ebe8cd8f0533b53",
          "livestatus": "LIVE",
          "notes": null,
          "isAcquireServiceActive": false,
          "_id": "6666a288378d6b3860a73938"
      },
      {
          "rules": {
              "ipAddressTypes": [],
              "cardCountryCode": [],
              "merchantCountryCode": [],
              "isCountryCodeActive": true,
              "isMerchantCountryCodeActive": true,
              "isCardSchemeListActive": true,
              "isAllowedCurrenciesListActive": true,
              "isMandatoryFieldsListActive": true,
              "cardSchemeList": [],
              "allowedCurrenciesList": [],
              "mandatoryFieldsList": [
                  {
                      "name": "merchantUserEmail"
                  },
                  {
                      "name": "ipAddress"
                  },
                  {
                      "name": "payerAddress"
                  },
                  {
                      "name": "payerState"
                  },
                  {
                      "name": "payerCountry"
                  },
                  {
                      "name": "payerCity"
                  },
                  {
                      "name": "payerZip"
                  },
                  {
                      "name": "payerPhone"
                  }
              ],
              "acceptFtd": true
          },
          "integrationType": {
              "_id": "66711af5ddc19351bf519665",
              "acquireName": "PaymentEmpire",
              "__v": 0
          },
          "acquirerName": "PaymentEmpire1",
          "tokenType": "3320e7a9-99db-4323-a69a-7e3f538bccca",
          "siteReferenceName": "N/A",
          "username": "N/A",
          "pwd": "N/A",
          "jwtUsername": "N/A",
          "jwtPassword": "N/A",
          "livestatus": "0",
          "notes": null,
          "isAcquireServiceActive": false,
          "_id": "66711b39ddc19351bf51a463"
      },
      {
          "rules": {
              "ipAddressTypes": [],
              "cardCountryCode": [],
              "merchantCountryCode": [],
              "isCountryCodeActive": true,
              "isMerchantCountryCodeActive": true,
              "isCardSchemeListActive": true,
              "isAllowedCurrenciesListActive": true,
              "isMandatoryFieldsListActive": true,
              "cardSchemeList": [],
              "allowedCurrenciesList": [
                  {
                      "name": "USD"
                  }
              ],
              "mandatoryFieldsList": [],
              "acceptFtd": true
          },
          "integrationType": {
              "_id": "66727be7954e9cfe933c03e4",
              "acquireName": "Trust Payments",
              "__v": 0
          },
          "acquirerName": "TrustPayment1",
          "tokenType": "58-4904f2f3c8150e544a6a0348b275bc613722d130d485bd2824f7f294f87da18e",
          "siteReferenceName": "test_coinspirit119357",
          "username": "test-webservices2",
          "pwd": "3!?f~N!!NnJ?",
          "jwtUsername": "jwt@coinsspirit.com",
          "jwtPassword": "N/A",
          "livestatus": "0",
          "notes": null,
          "isAcquireServiceActive": true,
          "_id": "66727c44c5b7a7daa4a029e6"
      },
      {
          "rules": {
              "ipAddressTypes": [],
              "cardCountryCode": [],
              "merchantCountryCode": [],
              "isCountryCodeActive": true,
              "isMerchantCountryCodeActive": true,
              "isCardSchemeListActive": true,
              "isAllowedCurrenciesListActive": true,
              "isMandatoryFieldsListActive": true,
              "cardSchemeList": [],
              "allowedCurrenciesList": [],
              "mandatoryFieldsList": [],
              "acceptFtd": true
          },
          "integrationType": {
              "_id": "66755e392e1539cd5cc70fb4",
              "acquireName": "Nevo4",
              "__v": 0
          },
          "acquirerName": "Nevo4_1",
          "tokenType": "N/A",
          "siteReferenceName": "N/A",
          "username": "F2ovBgPbRnYuGhX",
          "pwd": "0475cf99-cb65-45db-92eb-48abc2b85e39",
          "jwtUsername": "N/A",
          "jwtPassword": "N/A",
          "livestatus": "0",
          "notes": null,
          "isAcquireServiceActive": false,
          "_id": "66755e7d2e1539cd5cc70fd0"
      },
      {
          "rules": {
              "cardCountryCode": [],
              "merchantCountryCode": [],
              "isCountryCodeActive": true,
              "isMerchantCountryCodeActive": true,
              "isCardSchemeListActive": true,
              "isAllowedCurrenciesListActive": true,
              "isMandatoryFieldsListActive": true,
              "cardSchemeList": [],
              "allowedCurrenciesList": [],
              "ipAddressTypes": [],
              "mandatoryFieldsList": [],
              "acceptFtd": true
          },
          "integrationType": {
              "_id": "66c5c840c8b9e7ccd49a42d8",
              "acquireName": "Truevo",
              "__v": 0
          },
          "acquirerName": "Truevo1",
          "tokenType": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVU0VSSUQiOiIyNUFGNjk0MS00RTFBLTRCRjMtQUNFOS05MkMxNDk4MDUyRkEiLCJleHAiOjE5NTk2NzA2NTN9.qx-tf1u5wA_DZB7AureZ8MfkH0FOiWd-ikCxnKmj_ng",
          "siteReferenceName": "null",
          "username": "nick@alfortar.com",
          "pwd": "null",
          "jwtUsername": "null",
          "jwtPassword": "null",
          "livestatus": "0",
          "notes": null,
          "isAcquireServiceActive": false,
          "_id": "66c5c956c8b9e7ccd49a7721"
      }
  ],
  "__v": 3,
  "dailyTransactionsLimitForAUser": null,
  "maximumUSDAmountLimitForATransaction": null,
  "minimumUSDAmountLimitForATransaction": null,
  "monthlyTransactionsLimitForAUser": null,
  "totalDailyUSDAmountLimitForAUser": null,
  "weeklyTransactionsLimitForAUser": null,
  "totalWeeklyUSDAmountLimitForAUser": null,
  "totalMonthlyUSDAmountLimitForAUser": null,
  "totalDailyUSDAmountLimitForPlatform": null,
  "totalUniqueCards": 1,
  "totalUniqueCardsForUser": 2,
  "limitUserCardAfterTransectionFailed": null,
  "blockedCardList": [
      "123456******7890",
      "123456******7890",
      "111111******1111",
      "111111******1112",
      "211112******1221"
  ],
  "removeUnusedCardsAfterDays": null,
  "paymentProvider": "634fbc5a603d2b1171c89892",
  "ftdToTrustedCount": 3,
  "blockedEmailList": [
      "kanak23@yopmail.com",
      "olajide olatunji",
      "kanak211@yopmail.com",
      "kanak111@yopmail.com",
      "srinviasa.chevala@badbeat.com"
  ]
}

export const getPeriodListByAcquirer = [
    {
        "value": {
            "fromDate": "2024-09-01T00:00:00.000Z",
            "toDate": "2024-09-30T23:59:59.999Z"
        },
        "label": "September 2024"
    },
    {
        "value": {
            "fromDate": "2024-08-01T00:00:00.000Z",
            "toDate": "2024-08-31T23:59:59.999Z"
        },
        "label": "August 2024"
    },
    {
        "value": {
            "fromDate": "2024-07-01T00:00:00.000Z",
            "toDate": "2024-07-31T23:59:59.999Z"
        },
        "label": "July 2024"
    },
    {
        "value": {
            "fromDate": "2024-06-01T00:00:00.000Z",
            "toDate": "2024-06-30T23:59:59.999Z"
        },
        "label": "June 2024"
    },
    {
        "value": {
            "fromDate": "2024-05-01T00:00:00.000Z",
            "toDate": "2024-05-31T23:59:59.999Z"
        },
        "label": "May 2024"
    },
    {
        "value": {
            "fromDate": "2024-04-01T00:00:00.000Z",
            "toDate": "2024-04-30T23:59:59.999Z"
        },
        "label": "April 2024"
    },
    {
        "value": {
            "fromDate": "2024-04-01T00:00:00.000Z",
            "toDate": "2024-04-10T23:59:59.999Z"
        },
        "label": "April 2024"
    },
    {
        "value": {
            "fromDate": "2024-03-04T06:40:23.545Z",
            "toDate": "2024-03-30T23:59:59.999Z"
        },
        "label": "March 2024"
    },
    {
        "value": {
            "fromDate": "2024-02-04T06:37:03.849Z",
            "toDate": "2024-02-01T23:59:59.999Z"
        },
        "label": "February 2024"
    },
    {
        "value": {
            "fromDate": "2024-01-01T00:00:00.000Z",
            "toDate": "2024-01-30T23:59:59.999Z"
        },
        "label": "January 2024"
    }
]

export const getMaxtrafficStatusData = {
    "Period": {
        "fromDate": "2024-09-01T00:00:00.000Z",
        "toDate": "2024-09-30T23:59:59.999Z"
    },
    "Number of Transactions": {
        "value": 314,
        "leftValue": 3
    },
    "Value of Transactions": {
        "value": 962.0000000000001,
        "leftValue": 10
    },
    "Remaining Value to Max": {
        "value": 0,
        "leftValue": 10
    },
    "successful Transactions": {
        "value": 211,
        "leftValue": 314
    },
    "Value of Successful Transactions": {
        "value": 664.1099999999999,
        "leftValue": 962.0000000000001
    },
    "Transaction Success Rate": {
        "value": "67.20",
        "leftValue": 100
    },
    "Traffic Reach Percent": {
        "value": 10466.666666666668,
        "leftValue": 0
    }
}

export const getMaxTrafficData ={
    "_id": "660d3363c1e1fa1ef4ba2c69",
    "routingId": "0xc49f37142952a11577b9caec2da0d3f606eae0b88606fb1e8e8d796f5ed3d0a2",
    "acquirerName": "GoldenGatewayPayments1",
    "createdDate": "2024-04-03T10:45:55.074Z",
    "config": [
        {
            "maxValueAllowed": 10,
            "maxNumberAllowed": 3,
            "maxLimitPercentage": null,
            "currency": "USD",
            "_id": "66337f6bc04e0ebf98309041"
        }
    ],
    "__v": 9
}
export const successRoutingData ={
    "successRoutingResult": {
        "_id": "6672ad3b4d58fbb56b3e8a67",
        "routingId": "0xc49f37142952a11577b9caec2da0d3f606eae0b88606fb1e8e8d796f5ed3d0a2",
        "acquirerName": "GoldenGatewayPayments1",
        "createdDate": "2024-06-19T10:04:43.642Z",
        "lastUpdated": "2024-09-17T07:03:19.401Z",
        "status": [
            {
                "transactionCount": 1,
                "transactionRange": "0-50",
                "billingCountry": "India",
                "cardType": "Visa",
                "status": "SUCCESS",
                "rate": 100,
                "_id": "6672ad3b4d58fbb56b3e8a68"
            },
            {
                "transactionCount": 48,
                "transactionRange": "0-50",
                "binCountry": "US",
                "billingCountry": "India",
                "cardType": "visa",
                "status": "SUCCESS",
                "maskedPan": "40000000",
                "rate": 77.42,
                "_id": "6672c1a94d58fbb56b3f26e2"
            },
            {
                "transactionCount": 7,
                "transactionRange": "0-50",
                "binCountry": "PL",
                "billingCountry": "India",
                "cardType": "visa",
                "status": "FAILED",
                "maskedPan": "41111111",
                "rate": 100,
                "_id": "667d452e62b5ffebdd2df90e"
            },
            {
                "transactionCount": 14,
                "transactionRange": "0-50",
                "binCountry": "US",
                "billingCountry": "India",
                "cardType": "visa",
                "status": "FAILED",
                "maskedPan": "40000000",
                "rate": 22.58,
                "_id": "667ea1d7c074d06c35fb4421"
            },
            {
                "transactionCount": 4,
                "transactionRange": "0-50",
                "binCountry": "NOT REQUIRED",
                "billingCountry": "India",
                "cardType": "visa",
                "status": "SUCCESS",
                "maskedPan": "52000000",
                "rate": 100,
                "_id": "6682d194651f57332a0481e2"
            },
            {
                "transactionCount": 5,
                "transactionRange": "0-50",
                "binCountry": "US",
                "billingCountry": "India",
                "cardType": "Visa",
                "status": "SUCCESS",
                "maskedPan": "40000000",
                "rate": 100,
                "_id": "668e16e7c193bfb9d097a496"
            },
            {
                "transactionCount": 1,
                "transactionRange": "0-50",
                "binCountry": "US",
                "billingCountry": "India",
                "cardType": "Visa",
                "status": "SUCCESS",
                "rate": 100,
                "_id": "6690bc89732ea39eed707eb1"
            },
            {
                "transactionCount": 27,
                "transactionRange": "0-50",
                "binCountry": "US",
                "billingCountry": "India",
                "cardType": "visa",
                "status": "SUCCESS",
                "maskedPan": "400000******1091",
                "rate": 81.82,
                "_id": "66ac66849e05a096317fe3e3"
            },
            {
                "transactionCount": 7,
                "transactionRange": "0-50",
                "binCountry": "US",
                "billingCountry": "India",
                "cardType": "Visa",
                "status": "SUCCESS",
                "maskedPan": "",
                "rate": 100,
                "_id": "66aca6e7217df84add14e82d"
            },
            {
                "transactionCount": 2,
                "transactionRange": "0-50",
                "binCountry": "NOT REQUIRED",
                "billingCountry": "India",
                "cardType": "not required",
                "status": "SUCCESS",
                "maskedPan": "",
                "rate": 100,
                "_id": "66acb4af43d3513207dbd91c"
            }
        ]
    },
    "totalCount": 14
}
export const getLeastCostRouting ={
    "config": {
        "fixedCost": 12,
        "percentCost": 68
    },
    "_id": "663c74dba6dd2c5c904bf494",
    "routingId": "0xc49f37142952a11577b9caec2da0d3f606eae0b88606fb1e8e8d796f5ed3d0a2",
    "acquirerName": "GoldenGatewayPayments1",
    "createdDate": "2024-05-09T07:01:45.806Z",
    "lastUpdated": "2024-08-28T06:05:30.131Z",
    "__v": 0
}

export const getWieghtingByAcquirerData ={
    "_id": "6644445e7a3e4ffa3e2c1fed",
    "routingId": "0xc49f37142952a11577b9caec2da0d3f606eae0b88606fb1e8e8d796f5ed3d0a2",
    "acquirerName": "GoldenGatewayPayments1",
    "createdDate": "2024-05-15T05:13:02.976Z",
    "lastUpdated": "2024-09-17T07:03:19.416Z",
    "weightingPercentage": 10,
    "transactionCount": 202,
    "__v": 0
}