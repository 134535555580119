import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import DateRangePicker2 from "react-bootstrap-daterangepicker";
import { CPMultiSelect } from "../../../common/CPMultiSelect";

const dateImage = "/cryptonpay/crytoicons/date.png";
const selectedCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_filled.png";
const emptyCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_empty.png";

const DropdownItem = ({
  item,
  selectedItems,
  toggleSelection,
  image_base_url,
  selectedCheckBoxIcon,
  emptyCheckBoxIcon,
}) => {
  const isSelected = selectedItems.some((selected) =>
    typeof item === "object" ? selected?._id === item?._id : selected === item
  );

  return (
    <div className="currencyListItemWrap" onClick={() => toggleSelection(item)}>
      <div className="currencyListIconNameWrap">
        <div
          className="currencyList_Name"
          id="available-test"
          data-e2e={
            typeof item === "object"
              ? item?.name?.toLowerCase().replaceAll(" ", "-")
              : item.toLowerCase().replaceAll(" ", "-")
          }
        >
          {typeof item === "object" ? item?.name : item}
        </div>
      </div>
      <img
        src={`${image_base_url}${
          isSelected ? selectedCheckBoxIcon : emptyCheckBoxIcon
        }`}
        alt="checkbox"
        className="currencyListCheckBox"
        data-e2e={`${
          typeof item === "object"
            ? item?.name.toLowerCase().replaceAll(" ", "-")
            : item.toLowerCase().replaceAll(" ", "-")
        }-checkbox`}
      />
    </div>
  );
};

export default function CardDashboardFilter(props) {
  const { setFromDate, setToDate, fromDate, toDate } = props;
  const image_base_url = useSelector((state) => state.config.image_base_url);

  const handleCallback = (start, end) => {
    props.setFromDate(moment(start).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
    props.setToDate(moment(end).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
    props.setDateSelected(true);
  };

  const formatDate = (date) => moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

  const onChangeDate = (dateTimeRange) => {
    setFromDate(dateTimeRange ? formatDate(dateTimeRange[0]) : null);
    setToDate(dateTimeRange ? formatDate(dateTimeRange[1]) : null);
  };

  const [showDropdown, setShowDropdown] = useState({
    routingServices: false,
    merchant: false,
    acquirer: false,
    cardSchema: false,
    country: false,
    status: false,
    error: false,
    statusGroup : false,
  });

  const toggleDropdown = (key) => {
    setShowDropdown((prev) => {
      const newDropdownState = {};
      Object.keys(prev).forEach((k) => {
        newDropdownState[k] = k === key ? !prev[key] : false;
      });
      return newDropdownState;
    });
  };

  const closeReset = () => resetOpenPopup(false);

  const toggleSelection = (selectedItems, setSelectedItems, symbol) => {
    const newList = selectedItems.includes(symbol)
      ? selectedItems.filter((item) => item !== symbol)
      : [...selectedItems, symbol];
    setSelectedItems(newList);
  };

  const sortDropDownData = (data, field) => {
    if (field) {
      const filteredData = data.filter(
        (item) => item[field] !== undefined && item[field] !== null
      );
      return filteredData.sort((a, b) =>
        a[field].localeCompare(b[field], { sensitivity: "accent" })
      );
    } else {
      return data.sort((a, b) => a.localeCompare(b, { sensitivity: "accent" }));
    }
  };

  const filterFields = [
    {
      label: "Routing Service",
      key: "routingServices",
      items: props.routingServices,
      selectedItems: props.selectedRoutingServices,
      setSelectedItems: props.setSelectedRoutingServices,
    },
    {
      label: "Merchant",
      key: "merchant",
      items: props.merchants,
      selectedItems: props.selectedMerchants,
      setSelectedItems: props.setSelectedMerchants,
    },
    {
      label: "Acquirer",
      key: "acquirer",
      items: sortDropDownData(props.acquirers),
      selectedItems: props.selectedAcquirers,
      setSelectedItems: props.setSelectedAcquirers,
    },
    {
      label: "Card Schema",
      key: "cardSchema",
      items: sortDropDownData(props.cardSchemas),
      selectedItems: props.selectedCardSchemas,
      setSelectedItems: props.setSelectedCardSchemas,
    },
    {
      label: "Country",
      key: "country",
      items: sortDropDownData(props.countries),
      selectedItems: props.selectedCountries,
      setSelectedItems: props.setSelectedCountries,
    },
    {
      label: "Status",
      key: "status",
      items: sortDropDownData(props.statuses),
      selectedItems: props.selectedStatuses,
      setSelectedItems: props.setSelectedStatuses,
    },
    {
      label : "Status Group",
      key: "statusGroup",
      items: sortDropDownData(props.statusGroups),
      selectedItems: props.selectedStatusGroups,
      setSelectedItems: props.setSelectedStatusGroups,
    },
    {
      label: "Error",
      key: "error",
      items: sortDropDownData(props.errors),
      selectedItems: props.selectedErrors,
      setSelectedItems: props.setSelectedErrors,
    },
  ];

  return (
    <div className="card_filter">
      {filterFields.map(
        ({ label, key, items, selectedItems, setSelectedItems }) => (
          <div className="card_filter_list" key={key}>
            <div className="FilterModalInputGroup">
              <div className="FilterModalInputLabel">{label}</div>
              <div
                className="FilterModalInputDropdown"
                onClick={() => toggleDropdown(key)}
              >
                <div
                  className="FilterModalInputText"
                  data-e2e="status-drop-down"
                >
                  {selectedItems.length === items.length ||
                  selectedItems.length === 0
                    ? "All"
                    : `${selectedItems.length} Selected`}
                </div>
                <i className="fa fa-chevron-down" />
              </div>
              {showDropdown[key] && (
                <CPMultiSelect
                  setShowDropDown={() => toggleDropdown(key)}
                  handleDropdownClick={() => toggleDropdown(key)}
                  items={items}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  ItemComponent={({ item }) => (
                    <DropdownItem
                      item={item}
                      selectedItems={selectedItems}
                      toggleSelection={(symbol) =>
                        toggleSelection(selectedItems, setSelectedItems, symbol)
                      }
                      image_base_url={image_base_url}
                      selectedCheckBoxIcon={selectedCheckBoxIcon}
                      emptyCheckBoxIcon={emptyCheckBoxIcon}
                    />
                  )}
                />
              )}
            </div>
          </div>
        )
      )}
      <div className="card_filter_list_date">
        <div className="FilterModalInputGroup">
          <div className="FilterModalInputLabel">Date Range</div>
          <div className="transactionsFilter-date-ranger">
            <DateRangePicker2
              onCallback={handleCallback}
              initialSettings={{
                startDate: moment(fromDate),
                endDate: moment(toDate),
              }}
            >
              <div
                className="FilterModalInputDropdown"
                data-e2e="FilterModalInputDropdown"
              >
                <img
                  src={`${image_base_url}${dateImage}`}
                  alt="dateImage"
                  className="FilterModalInputDropdownDateImage"
                />
                <div className="FilterModalInputDropdownDateTextWrap">
                  <span data-e2e="start-date">{`${
                    props?.dateSelected
                      ? moment(fromDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
                          "D MMMM"
                        )
                      : "Start Date"
                  }`}</span>
                  <span className="text-black">{` To `}</span>
                  <span
                    style={{ marginRight: "15%" }}
                    data-e2e="end-date"
                  >{`${
                    props?.dateSelected
                      ? moment(toDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
                          "D MMMM"
                        )
                      : "End Date"
                  }`}</span>
                </div>
              </div>
            </DateRangePicker2>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={props.handleSave}
          className="bg-blue-600 text-white rounded-[8px] px-4 py-2 mt-[30px] md:mt-[70px]"
        >
          Save Filters
        </button>
      </div>
    </div>
  );
}
