export const validStatusValues = ["PrivateClient:TransactionCompleted", "PrivateClient:Rejected", "PrivateClient:ErrorEncountered"];

export const fiatCurrencies = [
  {
    icon: "/cryptonpay/crytoicons/euro.png",
    name: "Euro",
    symbol: "EUR",
  },
  {
    icon: "/cryptonpay/crytoicons/usa.png",
    name: "US Dollar",
    symbol: "USD",
  },
  {
    icon: "/cryptonpay/crytoicons/uk.png",
    name: "British Pound",
    symbol: "GBP",
  },
  {
    name: 'Australian dollar',
    symbol: "AUD",
    icon: "/common/crytoicons/aud.png"
  },
  {
    name: 'Canadian dollar',
    symbol: "CAD",
    icon: "/common/crytoicons/cad.png"
  },
  {
    name: 'dirham',
    symbol: "AED",
    icon: "/common/crytoicons/aed.png"
  },
  {
    icon: "/common/crytoicons/chf.png",
    name: "Swiss franc",
    symbol: "CHF",
  },
  {
    icon: "/common/crytoicons/try.png",
    name: "Turkish lira",
    symbol: "TRY",
  },
  {
    icon: "/common/crytoicons/hkd.png",
    name: "Hong Kong Dollar",
    symbol: "HKD",
  },
  {
    icon: "/common/crytoicons/sgd.png",
    name: "Singapore dollar",
    symbol: "SGD",
  },
  {
    icon: "/common/crytoicons/jpy.png",
    name: "Japanese Yen",
    symbol: "JPY",
  },
  {
    icon: "/common/crytoicons/cny.png",
    name: "Chinese Yuan",
    symbol: "CNY",
  },
  {
    icon: "/common/crytoicons/mxn.png",
    name: "Mexican Peso",
    symbol: "MXN",
  },
  {
    icon: "/common/crytoicons/thb.png",
    name: "Thai Baht",
    symbol: "THB",
  },
];

export const withdrawalStatuses = {
  WITHDRAWAL_SUBMITTED: "MerchantWithdrawalFlow:Approved",
  WITHDRAWAL_APPROVE: "MerchantWithdrawalFlow:ApprovedForProcessing",
  WITHDRAWAL_REJECT: "MerchantWithdrawalFlow:Rejected",
  WITHDRAWAL_CONFIRMED: "WalletManagementService:MerchantWithdrawalConfirmed",
  WITHDRAWAL_PROCESSING: "WalletManagementService:ProcessingMerchantWithdrawal",
  WITHDRAWAL_INSUFFICIENT_FUNDS_REJECT: "WalletManagementService:WithdrawalRejectedDueToInsufficientFunds",
  WITHDRAWAL_REJECTION_COMPLETE: "WalletManagementService:WithdrawalRejectionCompleted",
  WITHDRAWAL_COMPLETED: "WalletManagementService:WithdrawalCompleted",
  WITHDRAWAL_UNABLE_TO_SOURCE_WALLET: "WalletManagementService:UnableToSourceWithdrawalWallet",
  WITHDRAWAL_ERROR: "WalletManagementService:WithdrawalError",
  WITHDRAWAL_REJECTED_DUE_TO_BALANCE: "WalletManagementService:WithdrawalRejectedDueToMerchantBalance"
}

export const merchantTransactionStatuses = {
  DEPOSIT_PENDING_APPROVAL: "DepositMonitor:DepositPendingManualApproval",
  WITHDRAWAL_PENDING_APPROVAL: "MerchantWithdrawalFlow:PendingApproval",
  DEPOSIT_TIMED_OUT_MONITORING: "DepositMonitor:TimedOutMonitoringMemPool",
  DEPOSIT_REJECTED: "DepositMonitor:DepositRejected",
  WITHDRAWAL_REJECTION_COMPLETE: withdrawalStatuses.WITHDRAWAL_REJECTION_COMPLETE,
  WITHDRAWAL_INSUFFICIENT_FUNDS_REJECT: withdrawalStatuses.WITHDRAWAL_INSUFFICIENT_FUNDS_REJECT,
  WITHDRAWAL_REJECTED_DUE_TO_BALANCE: withdrawalStatuses.WITHDRAWAL_REJECTED_DUE_TO_BALANCE,
  WITHDRAWAL_ERROR: withdrawalStatuses.WITHDRAWAL_ERROR,
  DEPOSIT_WALLET_STATUS_AVAILABLE: "DepositMonitor:WalletStatusAvailable",
  DEPOSIT_WALLET_STATUS_LOCKED: "DepositMonitor:WalletStatusLocked",
  DEPOSIT_UPDATE_WALLET_STATUS: "DepositMonitor:UpdateWalletStatus",
  DEPOSIT_NOTIFY_MERCHANT: "DepositMonitor:NotfiyMerchant",
  DEPOSIT_MANUAL_APPROVE: "DepositMonitor:DepositManualApprove",
  DEPOSIT_KYT_FAIL: "DepositMonitor:KYTCheckFail",
  DEPOSIT_KYT_PASS: "DepositMonitor:KYTCheckPass",
  DEPOSIT_KYT_CHECK: "DepositMonitor:KYTCheck",
  DEPOSIT_CONFIRMED: "DepositMonitor:DepositConfirmed",
  DEPOSIT_GAS_TOO_LOW: "DepositMonitor:GasTooLow",
  DEPOSIT_EXCESS_TRANSACTION_EXECUTED: "DepositMonitor:ExcessTransactionExecuted",
  DEPOSIT_TRANSACTION_EXECUTED: "DepositMonitor:TransactionExecuted",
  DEPOSIT_TRANSACTION_EXECUTING: "DepositMonitor:TransactionExecuting",
  DEPOSIT_RECEIVED_TO_MEMPOOL: "DepositMonitor:DepositReceivedToMemPool",
  DEPOSIT_TRANSACTION_COMPLETE: "DepositMonitor:TransactionComplete",
  WITHDRAWAL_UNABLE_TO_SOURCE_WALLET: withdrawalStatuses.WITHDRAWAL_UNABLE_TO_SOURCE_WALLET,
  DEPOSIT_MANUAL_COMPLETE: "DepositMonitor:DepositManuallyCompleted",
  DEPOSIT_COMPLETED: "DepositMonitor:DepositCompleted",
  MERCHANT_BALANCE_UPDATED: "MerchantWallet:BalanceUpdated",
  WITHDRAWAL_COMPLETED: withdrawalStatuses.WITHDRAWAL_COMPLETED,
  WALLET_TRANSFER_COMPLETED: "WalletManagementService:TransferCompleted",
  DEPOSIT_WALLET_FLOW_COMPLETED: "DepositFlowViaWallet:DepositCompleted",
  AUTO_TRADE_COMPLETED: "MerchantWalletMonitor:ReceiveCompletedAutoTradeCompleted",
  MERCHANT_SWAP_COMPLETED: "MerchantSwapFlow:SwapCompleted",
  MERCHANT_SETTLEMENT_COMPLETED: "MerchantSettlementService:SettlementCompleted",
  MERCHANT_WITHDRAWAL_COMPLETED: "WalletManagementService:MerchantWithdrawalCompleted",
  MERCHANT_RECEIVE_COMPLETED: "MerchantWalletMonitor:ReceiveCompleted",
  MERCHANT_AUTO_TRADE_EXECUTED: "MerchantAutoTradeExececuted",
  MERCHANT_WALLET_BALANCE_UPDATE: "MerchantWalletBalanceUpdate",
  MERCHANT_COMPLETED: "MerchantWithdrawalFlow:Completed",
  CREATE_PAYMENT: "CreatePayment",
  DEPOSIT_FLOW_LAUNCHED: "DepositFlow:Launched",
  DEPOSIT_FLOW_AWAITING_DEPOSIT: "DepositFlow:AwaitingDeposit",
  DEPOSIT_MONITORING_MEMPOOL: "DepositMonitor:MonitoringMemPool",
  DEPOSIT_PENDING_MANUAL_APPROVING: "DepositMonitor:DepositPendingManualApproval",
  DEPOSIT_CANCELLED: "DepositFlow:Cancelled",
  DEPOSIT_ABANDONED: "DepositFlow:Abandoned"
}

const LITECOIN_BLOCKCHAIN = "Litecoin", BITCOIN_BLOCKCHAIN = "Bitcoin", ETHEREUM_BLOCKCHAIN = "Ethereum";
const RIPPLE_BLOCKCHAIN = "Ripple", TRON_BLOCKCHAIN = "Tron";
const BLOCKCHAIN_NAMES = { LITECOIN_BLOCKCHAIN, BITCOIN_BLOCKCHAIN, ETHEREUM_BLOCKCHAIN, RIPPLE_BLOCKCHAIN, TRON_BLOCKCHAIN };

const TRX = "TRX", BTC = "BTC", ETH = "ETH", XRP = "XRP", LTC = "LTC";
const DEFAULT_TOKENS = { TRX, BTC, ETH, XRP, LTC };

const BLOCKCHAIN_EXPLORER_URL = {
  "Ethereum": {
    mainnet: "https://etherscan.io/tx/",
    testnet: "https://sepolia.etherscan.io/tx/"
  },
  "Bitcoin": {
    mainnet: "https://www.blockchain.com/btc/tx/",
    testnet: "https://blockstream.info/testnet/tx/"
  },
  "Litecoin": {
    mainnet: "https://live.blockcypher.com/ltc/tx/",
    testnet: "https://blockexplorer.one/litecoin/testnet/tx/"
  },
  "Ripple": {
    mainnet: "https://xrpscan.com/tx/",
    testnet: "https://blockexplorer.one/xrp/testnet/tx/"
  },
  "Tron": {
    mainnet: "https://tronscan.org/#/transaction/",
    testnet: "https://shasta.tronscan.org/#/transaction/"
  }
}

const WALLETS_FILTER_BLOCKCHAIN_LIST = [
  'Ethereum',
  'Bitcoin',
  'Litecoin',
  'Ripple',
  'Tron'
];

const WALLETS_FILTER_NETWORK_LIST = [
  'mainnet',
  'testnet',
];

const WALLETS_FILTER_STATUS_ICONS_LIST = [
  'All',
  'Orange',
  'Red',
  'Green',
];

const WALLETS_FILTER_STATUS_LIST = [
  'In Use',
  'Available',
  'Locked',
  'Archived',
  'Full'
];

export const DEPOSIT_HASH_FILTER_NETWORK_LIST = [
  'mainnet',
  'testnet',
];
export const DEFAULT_SORTBY_FOR_DEPOSIT_HASH = "createdAt";
export const DEFAULT_ORDER_BY_FOR_DEPOSIT_HASH = "desc";
const constant = {
  BLOCKCHAIN_NAMES,
  DEFAULT_TOKENS,
  BLOCKCHAIN_EXPLORER_URL,
  WALLETS_FILTER_BLOCKCHAIN_LIST,
  WALLETS_FILTER_NETWORK_LIST,
  WALLETS_FILTER_STATUS_ICONS_LIST,
  WALLETS_FILTER_STATUS_LIST,
  AllIpAddressTypesData: ["ipv4", "ipv6"],
}
export const cardDashboardStatusGroup = [
  "Processing",
  "Success",
  "Rejected",
 ]

export default constant;