import React, { useState, useEffect } from "react";
import BlockCardList from "../BlockCardList";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";

const AMLRulesTab = (props) => {
  const [amlRulesData, setAMLRulesData] = useState(null);
  const listOfConfigurations = [
    {
      name: "dailyTransactionsLimitForAUser",
      label: "Daily transactions limit for a user",
      placeholder: "Enter transaction count",
    },
    {
      name: "weeklyTransactionsLimitForAUser",
      label: "Weekly transactions limit for a user",
      placeholder: "Enter transaction count",
    },
    {
      name: "monthlyTransactionsLimitForAUser",
      label: "Monthly transactions limit for a user",
      placeholder: "Enter transaction count",
    },
    {
      name: "maximumUSDAmountLimitForATransaction",
      label: "Maximum USD amount limit for a transaction",
      placeholder: "Enter maximum USDAmount",
    },
    {
      name: "minimumUSDAmountLimitForATransaction",
      label: "Minimum USD amount limit for a transaction",
      placeholder: "Enter minimum USDAmount",
    },
    {
      name: "totalDailyUSDAmountLimitForAUser",
      label: "Total daily USD amount limit for a user",
      placeholder: "Enter total USDAmount for a day",
    },
    {
      name: "totalDailyUSDAmountLimitForPlatform",
      label: "Total daily USD amount limit for platform",
      placeholder: "Enter total USDAmount for platform",
    },
    {
      name: "totalWeeklyUSDAmountLimitForAUser",
      label: "Total weekly USD amount limit for a user",
      placeholder: "Enter total USDAmount for a week",
    },
    {
      name: "totalMonthlyUSDAmountLimitForAUser",
      label: "Total monthly USD amount limit for a user",
      placeholder: "Enter total USDAmount for a month",
    },
    {
      name: "totalUniqueCardsForUser",
      label: "Total Unique Cards",
      placeholder: "Total  unique Cards",
    },
    {
      name: "limitUserCardAfterTransectionFailed",
      label: "Limit User Card After Failed Transection",
      placeholder: "Limit User Card After Failed Transection",
    },
    {
      name: "removeUnusedCardsAfterDays",
      label: "Remove Unused cards after Days of Inactivity",
      placeholder: "Remove Unused cards after Days of Inactivity",
    },
  ];
  const handleConfigChange = (e) => {
    setAMLRulesData({ ...amlRulesData, [e.name]: e.value.trim() });
  };
  useEffect(() => {
    if (props.acquirerServiceData) {
      let amlRulesDataObj = {
        dailyTransactionsLimitForAUser: props.acquirerServiceData
          .dailyTransactionsLimitForAUser
          ? props.acquirerServiceData.dailyTransactionsLimitForAUser
          : "",
        weeklyTransactionsLimitForAUser: props.acquirerServiceData
          .weeklyTransactionsLimitForAUser
          ? props.acquirerServiceData.weeklyTransactionsLimitForAUser
          : "",
        monthlyTransactionsLimitForAUser: props.acquirerServiceData
          .monthlyTransactionsLimitForAUser
          ? props.acquirerServiceData.monthlyTransactionsLimitForAUser
          : "",
        maximumUSDAmountLimitForATransaction: props.acquirerServiceData
          .maximumUSDAmountLimitForATransaction
          ? props.acquirerServiceData.maximumUSDAmountLimitForATransaction
          : "",
        minimumUSDAmountLimitForATransaction: props.acquirerServiceData
          .minimumUSDAmountLimitForATransaction
          ? props.acquirerServiceData.minimumUSDAmountLimitForATransaction
          : "",
        totalDailyUSDAmountLimitForAUser: props.acquirerServiceData
          .totalDailyUSDAmountLimitForAUser
          ? props.acquirerServiceData.totalDailyUSDAmountLimitForAUser
          : "",
        totalDailyUSDAmountLimitForPlatform: props.acquirerServiceData
          .totalDailyUSDAmountLimitForPlatform
          ? props.acquirerServiceData.totalDailyUSDAmountLimitForPlatform
          : "",
        totalWeeklyUSDAmountLimitForAUser: props.acquirerServiceData
          .totalWeeklyUSDAmountLimitForAUser
          ? props.acquirerServiceData.totalWeeklyUSDAmountLimitForAUser
          : "",
        totalMonthlyUSDAmountLimitForAUser: props.acquirerServiceData
          .totalMonthlyUSDAmountLimitForAUser
          ? props.acquirerServiceData.totalMonthlyUSDAmountLimitForAUser
          : "",
        totalUniqueCardsForUser: props.acquirerServiceData
          .totalUniqueCardsForUser
          ? props.acquirerServiceData.totalUniqueCardsForUser
          : "",
        limitUserCardAfterTransectionFailed: props.acquirerServiceData
          .limitUserCardAfterTransectionFailed
          ? props.acquirerServiceData.limitUserCardAfterTransectionFailed
          : "",
        removeUnusedCardsAfterDays: props.acquirerServiceData
          .removeUnusedCardsAfterDays
          ? props.acquirerServiceData.removeUnusedCardsAfterDays
          : "",
      };
      setAMLRulesData(amlRulesDataObj);
    }
  }, []);
  return (
    <div className="TabContainer">
      <div className="bg-[#F9FAFB] h-full max-w-[400px]">
        <h2 className="heading">AML Rules</h2>
        <form>
          {amlRulesData &&
            listOfConfigurations.map((config, i) => {
              return (
                <div key={i} className="form-group myAccoutOptModal_form_group">
                  <label
                    className="myAccoutOptModalLable"
                    htmlFor={config.name}
                  >
                    {config.label}
                  </label>
                  <input
                    type="text"
                    value={amlRulesData[config.name]}
                    className="form-control myAccoutOptModalLable_input_wrap"
                    placeholder={config.placeholder}
                    name={config.name}
                    onChange={(e) => {
                      if (!isNaN(e.target.value) && e.target.value >= 0) {
                        handleConfigChange(e.target);
                      }
                    }}
                  />
                </div>
              );
            })}
          <div>
            <button
              onClick={() => isViewPermissionValid?null: props?.updateCardRoutingService(amlRulesData)}
              className={`btnAddService ${isViewPermissionValid? "disabled": ""}`}
            >
              Update Configurations
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AMLRulesTab;
