import React, { useState, useEffect } from "react";
import CommonHeader from "../CommonPages/CommonHeader";
import { Table } from "reactstrap";
import Loading from "../../../common/Loading";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { Checkbox } from "@mui/material";
import { call } from "../../../../config/axios";
import {
  GET_MERCHANT_USERS,
  UPDATE_VIEW_ONLY_SETTING,
  CREATE_NEW_MERCHANT_USER,
  DELETE_MERCHANT_USER,
  GET_INDIVIDUAL_MERCHANT_USER_DATA,
  UPDATE_INDIVIDUAL_MERCHANT_USER_DATA,
} from "../../../../config/endpoints";
import localDb from "../../../../localDb";

const MerchantUsers = ({ merchantId }) => {
  const email = localDb.getVal("email");
  const baseUrl = useSelector((state) => state.config.api_url);
  const [loading, setLoading] = useState(false);
  const [viewPermissionOnly, setViewPermissionOnly] = useState(false);
  const [newMerchantUserName, setNewMerchantUserName] = useState("");
  const [newMerchantUserEmail, setNewMerchantUserEmail] = useState("");
  const [newMerchantUserNumber, setNewMerchantUserNumber] = useState("");
  const [merchantUserPassword, setMerchantUserPassword] = useState("");
  const [merchantUserConfirmPassword, setMerchantUserConfirmPassword] =
    useState("");
  const [viewOnlyForUser, setViewOnlyForUser] = useState();

  const [enabled, setEnabled] = useState(true);

  const [individualMerchantData, setIndividualMerchantData] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);

  const [err, setErr] = useState("");
  const defaultUserImage = "/homeb2c/user-default-pic.svg";
  const closeImage = "/cryptonpay/crytoicons/close.png";
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteEmail, setDeleteEmail] = useState("");
  const onClose = () => {
    setOpen(false);
    setOpenDeleteModal(false);
  };
  const closeEditModal = () => {
    setOpenEditModal(false);
    setIndividualMerchantData("");
    setNewMerchantUserName("");
    setNewMerchantUserEmail("");
    setMerchantUserPassword("");
    setMerchantUserConfirmPassword("");
    setViewOnlyForUser("");
  };
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  const [profileImage, setProfileImage] = useState();
  const [data, setData] = useState([]);
  const [userEmails, setUserEmails] = useState([]);
  const emailIds = [];
  const validationCheck = (merchantEmailId) => {
    const validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      merchantEmailId,
    );
    if (newMerchantUserName === "") {
      setErr("Please Input a User Name");
      return false;
    }
    if (!validEmail) {
      setErr("Please Input Correct Email");
      return false;
    }
    if (merchantUserPassword !== merchantUserConfirmPassword) {
      setErr("Please Input Correct password");
      return false;
    }
    return true;
  };
  const createNewUser = async () => {
    setLoading(true);
    if (!validationCheck(newMerchantUserEmail)) {
      setLoading(false);
      return;
    }
    try {
      const payload = {
        email: email,
        merchantId: merchantId,
        merchantUserName: newMerchantUserName,
        merchantUserEmail: newMerchantUserEmail,
        merchantUserPassword: merchantUserPassword,
        merchantUserPhoneNumber: newMerchantUserNumber,
        viewPermissionOnly: viewOnlyForUser,
        enabled: enabled,
      };
      const result = await call(
        {
          ...CREATE_NEW_MERCHANT_USER,
          url: baseUrl + CREATE_NEW_MERCHANT_USER.url,
        },
        payload,
      );
      if (result.status === 200) {
        setData([...data, result?.data]);
        setOpen(false);
        setLoading(false);
      }
      setLoading(false);
    } catch (err) {
      if (err?.response?.data?.error === true) {
        setErr(err?.response?.data?.message);
        setOpen(true);
        setLoading(false);
        return;
      }
      console.error(err, " in createNewUser ");
      setLoading(false);
    }
  };
  const updateMerchantUser = async (mId, mEmail) => {
    setLoading(true);
    try {
      const payload = {
        email: email,
      };
      if (newMerchantUserName) {
        payload.name = newMerchantUserName;
      }
      if (newMerchantUserEmail) {
        const validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          newMerchantUserEmail,
        );
        if (!validEmail) {
          setErr("Please enter a valid email.");
          setLoading(false);
          return;
        }
        payload.mail = newMerchantUserEmail;
      }
      if (newMerchantUserNumber) {
        payload.mobile = newMerchantUserNumber;
      }
      if (merchantUserPassword) {
        if (
          merchantUserConfirmPassword &&
          merchantUserPassword !== merchantUserConfirmPassword
        ) {
          setErr("Passwords do not match.");
        }
        payload.password = merchantUserPassword;
        payload.confirmPassword = merchantUserConfirmPassword;
      }
      payload.viewsOnly = viewOnlyForUser;
      const result = await call(
        {
          ...UPDATE_INDIVIDUAL_MERCHANT_USER_DATA,
          url: `${baseUrl}${UPDATE_INDIVIDUAL_MERCHANT_USER_DATA.url}/${individualMerchantData[0]?.merchantId}/${individualMerchantData[0]?.email}`,
        },
        payload,
      );
      if (result.status == 200) {
        setData("");
        await getMerchantUsersList();
        setOpenEditModal(false);
      }
      setLoading(false);
      setNewMerchantUserName("");
      setNewMerchantUserEmail("");
      setNewMerchantUserNumber("");
      setMerchantUserPassword("");
      setMerchantUserConfirmPassword("");
      setViewOnlyForUser("");
    } catch (err) {
      if (err?.response?.data?.error === true) {
        setErr(err?.response?.data?.message);
        setOpenEditModal(true);
        setLoading(false);
        return;
      }
      setLoading(false);
      setNewMerchantUserName("");
      setNewMerchantUserEmail("");
      setNewMerchantUserNumber("");
      setMerchantUserPassword("");
      setMerchantUserConfirmPassword("");
      setViewOnlyForUser("");
      console.error(err, "in updateMerchantUser");
    }
  };
  const deleteMerchantUser = async (user) => {
    const testArray = data;
    setOpenDeleteModal(true);
    setLoading(true);
    try {
      const payload = {
        email: email,
        merchantUserEmailId: user?.email,
        merchantId: merchantId,
      };
      const result = await call(
        {
          ...DELETE_MERCHANT_USER,
          url: baseUrl + DELETE_MERCHANT_USER.url,
        },
        payload,
      );
      if (result.status === 200) {
        testArray.splice(user?.key, 1);
        setData([...testArray]);
        setOpenDeleteModal(false);
        setLoading(false);
      }
      setOpenDeleteModal(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setOpenDeleteModal(false);
      console.error(err, "in deleteMerchantUser");
    }
  };

  const getMerchantUsersList = async () => {
    setProfileImage(imageBaseUrl + defaultUserImage);
    setLoading(true);
    try {
      const result = await call(
        {
          ...GET_MERCHANT_USERS,
          url: baseUrl + GET_MERCHANT_USERS.url,
        },
        { email: email, id: merchantId },
      );
      if (result.status === 200) {
        setData(result?.data);
      }
      setLoading(false);
    } catch (err) {
      console.error(err, "in getMerchantUsersList");
      setLoading(false);
    }
    setLoading(false);
  };

  const getIndividualMerchantUserData = async (
    mEmail,
    mId,
    mViewPermission,
    callback,
  ) => {
    setLoading(true);
    try {
      const result = await call(
        {
          ...GET_INDIVIDUAL_MERCHANT_USER_DATA,
          url: baseUrl + GET_INDIVIDUAL_MERCHANT_USER_DATA.url,
        },
        {
          email: email,
          merchantEmail: mEmail,
          merchantId: mId,
        },
      );

      if (result.status == 200) {
        setIndividualMerchantData(() => result?.data);
        setViewOnlyForUser(mViewPermission);
        setLoading(false);
        callback(true);
      }
    } catch (err) {
      console.error(err, "in getIndividualMerchantUserData");
      setLoading(false);
    }
  };

  const updateViewSetting = async (emailId, viewPermissionOnly) => {
    setLoading(true);
    try {
      const payload = {
        email: email,
        merchantUserEmailId: emailId,
        merchantId: merchantId,
        viewPermissionOnly: viewPermissionOnly,
      };
      const result = await call(
        {
          ...UPDATE_VIEW_ONLY_SETTING,
          url: baseUrl + UPDATE_VIEW_ONLY_SETTING.url,
        },
        payload,
      );
      setLoading(false);
    } catch (err) {
      console.error(err, "in updateViewSetting");
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMerchantUsersList();
  }, []);

  const inputs = [
    {
      key: "1",
      attributeName: "name",
      name: "Merchant User Name",
      method: setNewMerchantUserName,
      type: "text",
      disabled: false,
      placeholder: "Enter Merchant User Name",
    },
    {
      key: "2",
      attributeName: "email",
      name: "Merchant User Email",
      method: setNewMerchantUserEmail,
      type: "email",
      disabled: false,
      placeholder: "Enter Merchant User Email",
    },
    {
      key: "3",
      attributeName: "mobile",
      name: "Merchant User Phone Number",
      method: setNewMerchantUserNumber,
      type: "number",
      disabled: false,
      placeholder: "Enter Merchant User Phone Number",
    },
    {
      key: "4",
      attributeName: "password",
      name: "Set Password",
      method: setMerchantUserPassword,
      type: "text",
      disabled: false,
      placeholder: "Set Password",
    },
    {
      key: "5",
      attributeName: "confirm-password",
      name: "Confirm Password",
      method: setMerchantUserConfirmPassword,
      type: "password",
      disabled: merchantUserPassword ? false : true,
      placeholder: "Confirm Password",
    },
  ];
  return (
    <div className="pageContainer merchant_page">
      <CommonHeader name={"Merchant User List"} />
      <div className="payment-provider-add-btn">
        <button
          className={`theme-table-action add-country-btn `}
          onClick={() => setOpen(true)}
        >
          Create Merchant User
        </button>
      </div>
      <div className="TransactionTableMain">
        {data.length > 0 ? (
          <Table>
            <tbody className="TransactionTable_BODY merchant_table_text_align">
              <tr className="TransactionTable_BODY_Header">
                <th className="TransactionTable_heading">Name </th>
                <th className="TransactionTable_heading">Email </th>
                <th className="TransactionTable_heading">Phone Number </th>
                <th className="TransactionTable_heading">
                  View Permission Only{" "}
                </th>
                <th className="TransactionTable_heading">Actions </th>
              </tr>
              {data.map((Data, index) => {
                emailIds.push(Data?.email);
                return (
                  <SingleMerchantTableRow
                    data={Data}
                    index={index}
                    profileImage={profileImage}
                    setOpenDeleteModal={setOpenDeleteModal}
                    setViewPermissionOnly={setViewPermissionOnly}
                    updateViewSetting={updateViewSetting}
                    setDeleteEmail={setDeleteEmail}
                    getIndividualMerchantUserData={
                      getIndividualMerchantUserData
                    }
                    setOpenEditModal={setOpenEditModal}
                  />
                );
              })}
            </tbody>
            <Dialog
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth={"lg"}
              disableEnforceFocus={true}
              onClose={onClose}
            >
              <div className="filter_model_container">
                <div className="filter_model">
                  <div className="filter_model_heading_wrap">
                    <div className="filter_heading">New Merchant User</div>
                  </div>
                  <div className="filter_close_button" onClick={onClose}>
                    <img
                      src={`${imageBaseUrl}${closeImage}`}
                      alt="closeImage"
                      data-e2e="close-icon"
                    />
                  </div>
                </div>
                {inputs.map((input, index) => {
                  return <InputFields inputs={input} setErr={setErr} />;
                })}
                <div className="FilterModalInputGroup">
                  <div className="FilterModalInputLabel">
                    View-Only
                    <Checkbox
                      type="checkbox"
                      className="rangeInputRight"
                      placeholder="Enter Merchant User Phone number"
                      name="MerchantDetails"
                      defaultChecked={data?.viewPermissionOnly ? true : false}
                      onChange={(e) => {
                        setViewOnlyForUser(e.target.checked);
                        setErr("");
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3 text-center text-red-700">{err}</div>
                <div style={{ margin: "0" }} className="FilterModalSave">
                  <button onClick={() => createNewUser()}>Create User</button>
                </div>
              </div>
            </Dialog>
            <Dialog
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth={"lg"}
              disableEnforceFocus={true}
              open={openDeleteModal}
              onClose={onClose}
            >
              <div className="filter_model_container">
                <div className="filter_model mb-10">
                  <div className="filter_model_heading_wrap ">
                    <div className="filter_heading mx-10 ">
                      Are you sure you want to delete this User?
                    </div>
                  </div>
                  <div className="filter_close_button" onClick={onClose}>
                    <img
                      src={`${imageBaseUrl}${closeImage}`}
                      alt="closeImage"
                      data-e2e="close-icon"
                    />
                  </div>
                </div>
                <div className="FilterModalDelete ">
                  <button onClick={() => deleteMerchantUser(deleteEmail)}>
                    Yes
                  </button>
                </div>
              </div>
            </Dialog>
            <Dialog
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth={"lg"}
              disableEnforceFocus={true}
              open={openEditModal}
              onClose={closeEditModal}
            >
              <div className="filter_model_container">
                <div className="filter_model">
                  <div className="filter_model_heading_wrap">
                    <div className="filter_heading">Edit Merchant User</div>
                  </div>
                  <div className="filter_close_button" onClick={closeEditModal}>
                    <img
                      src={`${imageBaseUrl}${closeImage}`}
                      alt="closeImage"
                      data-e2e="close-icon"
                    />
                  </div>
                </div>
                {inputs.map(input => {
                  return (
                    <InputFields
                      key={input?.key}
                      inputs={input}
                      val={individualMerchantData[0]}
                      setErr={setErr}
                    />
                  );
                })}
                <div className="FilterModalInputGroup">
                  <div className="FilterModalInputLabel">
                    View-Only
                    <Checkbox
                      type="checkbox"
                      className="rangeInputRight"
                      name="MerchantDetails"
                      defaultChecked={
                        individualMerchantData[0]?.viewPermissionOnly
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setViewOnlyForUser(() => e.target.checked);
                        setErr("");
                      }}
                    />
                  </div>
                </div>
                <div className="mb-3 text-center text-red-700">{err}</div>
                <div style={{ margin: "0" }} className="FilterModalSave">
                  <button
                    onClick={() =>
                      updateMerchantUser(
                        individualMerchantData[0]?.merchantId,
                        individualMerchantData[0]?.email,
                      )
                    }
                  >
                    Apply Changes
                  </button>
                </div>
              </div>
            </Dialog>
          </Table>
        ) : (
          <div>No Data</div>
        )}
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default MerchantUsers;

const SingleMerchantTableRow = ({
  data,
  index,
  setOpenDeleteModal,
  updateViewSetting,
  setDeleteEmail,
  getIndividualMerchantUserData,
  setOpenEditModal,
}) => {
  const checked = false;
  const openDeleteModal = (email) => {
    setOpenDeleteModal(true);
    setDeleteEmail({ email: email, key: index });
  };
  return (
    <tr key={index} className="CP_TableRow housekeeping-table-row">
      <td className="transactionsTable_item">
        {(data?.name) || "N/A"}
      </td>
      <td className="transactionsTable_item">
        {(data?.email) || "N/A"}
      </td>
      <td className="transactionsTable_item">
        {(data?.mobile) || "N/A"}
      </td>
      <td className="transactionsTable_item">
        <Checkbox
          defaultChecked={data?.viewPermissionOnly ? true : false}
          onClick={(e) => updateViewSetting(data?.email, e.target.checked)}
        />
      </td>
      <td className="transactionsTable_item">
        <EditIcon
          fontSize="large"
          color="action"
          data-e2e="edit-button"
          className="cursor-pointer pr-3"
          onClick={() => {
            getIndividualMerchantUserData(
              data?.email,
              data?.merchantId,
              data?.viewPermissionOnly,
              setOpenEditModal,
            );
          }}
        />
        <i
          className="fa fa-trash deleteB2cUserIcon cursor-pointer"
          aria-hidden="true"
          onClick={() => openDeleteModal(data?.email)}
        ></i>
      </td>
    </tr>
  );
};
const InputFields = ({ inputs, val, setErr }) => {
  return (
    <div className="FilterModalInputGroup">
      <div className="FilterModalInputLabel">{inputs?.name}</div>
      <div className="MinMaxCryptoRangeWrap">
        <input
          className="rangeInput"
          placeholder={inputs?.placeholder}
          name="MerchantDetails"
          type={inputs?.type}
          defaultValue={
            inputs?.attributeName === "name"
              ? val?.name
              : inputs?.attributeName === "email"
                ? val?.email
                : inputs?.attributeName === "mobile"
                  ? val?.mobile
                  : ""
          }
          disabled={inputs?.disabled}
          onChange={(e) => {
            inputs?.method(e.target.value);
            setErr("");
          }}
        />
      </div>
    </div>
  );
};
