import React, { useState, useEffect } from "react";
import Loading from "../../../../../common/Loading";
import CustomTextFields from "../../../../../common/CustomTextFields";
import TabButton from "../../../../../common/TabButton";
import { Grid } from "@mui/material";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";

const MaxTrafficTab = ({ acquireService }) => {
  const [tabs, setTabs] = useState(0);
  const [loading, setLoading] = useState(false);
  const fieldNames = {
    maxValueAllowed: "Max Transaction Value",
    maxNumberAllowed: "Max No of Transactions",
    maxLimitPercentage: "Max Traffic %",
    currency: "Currency",
  };
  const initialValues = {
    maxValueAllowed: 0,
    maxNumberAllowed: 0,
    maxLimitPercentage: 0,
    currency: "USD",
  };
  const initialEditValue = {
    maxValueAllowed: false,
    maxNumberAllowed: false,
    maxLimitPercentage: false,
    currency: false,
  };
  const [values, setValues] = useState(initialValues);
  const [isEditable, setIsEditable] = useState(initialEditValue);

  useEffect(() => {
    getMaxtrafficByAcquirer();
  }, [tabs]);

  const handleChange = (field, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    // Need API call
  };

  const getMaxtrafficByAcquirer = async () => {
    // Need API call
    setValues(initialValues);
  };

  const toggleEditable = (field) => {
    setIsEditable((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleTabSwitch = (index) => {
    setTabs(index);
    setIsEditable(initialEditValue);
  };

  return (
    <div className="TabContainer">
      <div className="acquireServiceScroll">
        {acquireService?.routingService?.map((tab, index) => (
          <TabButton
            key={index}
            activeTab={tabs}
            handleTabSwitch={handleTabSwitch}
            tab={tab}
            index={index}
          />
        ))}
      </div>
      <div className="ml-3 flex pt-10">
        <Grid container spacing={2}>
          {acquireService?.routingService?.map((items, index) => {
            return (
              <div key={index}>
                {tabs === index && (
                  <CustomTextFields
                    initialValues={initialValues}
                    values={values}
                    isEditable={isEditable}
                    handleChange={handleChange}
                    toggleEditable={toggleEditable}
                    fieldNames={fieldNames}
                  />
                )}
              </div>
            );
          })}
        </Grid>
      </div>
      <div className="mt-4">
        <button
          className={`${isViewPermissionValid ? "disabled" : ""} rounded bg-blue-500 px-4 py-2 text-base font-bold text-white hover:bg-blue-700`}
          onClick={isViewPermissionValid ? null : handleSave}
          data-e2e="save-btn"
        >
          Save
        </button>
      </div>

      {loading && <Loading />}
    </div>
  );
};

export default MaxTrafficTab;
