import React, { useState } from "react";
import { useSelector } from "react-redux";

const closeImage = "/cryptonpay/crytoicons/close.png";
const selectedCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_filled.png";
const emptyCheckBoxIcon = "/cryptonpay/crytoicons/checkbox_empty.png";

function CreateAccountStageTwo(props) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [data, setData] = useState([]);
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);

  const [timeLocking, setTimeLocking] = useState(false);

  const [userDeposit, setUserDeposit] = useState(false);
  const [merchantAutoWithdrawalDropDown, setMerchantAutoWithdrawalDropDown] = useState(false)
  const [showCardServiceDropdown, setShowCardServiceDropdown] = useState(false)

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleProfileImage = (e) => {
    var fileInput = document.getElementById("merchantProfile");
    var filePath = fileInput.value;

    var allowedExtensions = /(\.jpg|\.jpeg)$/i;

    if (!allowedExtensions.exec(filePath)) {
      alert("Invalid file type");
      fileInput.value = "";
      return false;
    } else {
      let file = e.target.files[0]
      setData({ ...data, [e.target.name]: file });
    }
  };

  const toggleSelection = (symbol) => {
    if (props.selectedCryptos.includes(symbol)) {
      let newList = props.selectedCryptos.filter(
        (symbolObj) => symbolObj !== symbol
      );
      props.setSelectedCryptos(newList);
    } else {
      let newList = [...props.selectedCryptos];
      newList.push(symbol);
      props.setSelectedCryptos(newList);
    }
  };

  return (
    <div className="create_account_container">
      <div className="create_account_header">
        <div className="create_account_heading">Create Account (2/2)</div>
        <div
          className="filter_close_button"
          dat-e2e="filter_close_button"
          onClick={() => {
            props.handleCloseAddNew();
          }}
        >
          <img
            src={`${image_base_url}${closeImage}`}
            alt="closeImage"
            data-e2e="close-icon"
          />
        </div>
      </div>

      <div className="create_account_form">
        {props.data &&
          props.data.map((item, index) => {
            return (
              <div style={{ margin: "20px 0px" }} key={index}>
                {item.input_type === "input_box" && (
                  <div key={index}>
                    <label>
                      <b>{item.label}</b>
                    </label>
                    <input
                      className="create_account_input"
                      type={item.type}
                      id={item.id}
                      name={item.name}
                      value={(data && data[item.name]) || item.value || ""}
                      placeholder={item.placeholder}
                      data-e2e={item.id}
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </div>
                )}
                {item.input_type === "select" && (
                  <div className="form-group-cryptonpay" key={index}>
                    <div>
                      {item.isMulti ? (
                        <div>
                          <div className="FilterModalInputGroup">
                            <div
                              className="FilterModalInputLabel"
                              style={{ fontSize: "13px" }}
                            >
                              Crypto Currency
                            </div>
                            <div
                              className="FilterModalInputDropdown"
                              data-e2e="FilterModalInputDropdown"
                              onClick={() => {
                                setShowCurrencyDropdown(true);
                                setTimeLocking(false);
                                setMerchantAutoWithdrawalDropDown(false)
                                setUserDeposit(false)
                              }}
                            >
                              <div
                                className="FilterModalInputText"
                                data-e2e="FilterModalInputText"
                              >
                                {props.selectedCryptos.length ===
                                  props.cryptos.length ||
                                  props.selectedCryptos.length === 0
                                  ? "All"
                                  : `${props.selectedCryptos.length} Selected`}
                              </div>
                              <i
                                className="fa fa-chevron-down FilterModalInputDropIcon"
                                data-e2e="FilterModalInputDropIcon"
                              />
                            </div>
                            {showCurrencyDropdown && (
                              <div
                                className="FilterModalCurrencyDropdownlist"
                                data-e2e="FilterModalCurrencyDropdownlist"
                              >
                                <div
                                  className="FM_currencyDrop_All"
                                  data-e2e="FM_currencyDrop_All"
                                  onClick={() => {
                                    setShowCurrencyDropdown(false);
                                  }}
                                >
                                  <span
                                    className="FM_currencyDrop_AllSelector"
                                    data-e2e="FM_currencyDrop_AllSelector"
                                  >
                                    {props.selectedCryptos.length ===
                                      props.cryptos.length ||
                                      props.selectedCryptos.length === 0
                                      ? "All"
                                      : `${props.selectedCryptos.length} Selected`}
                                  </span>
                                  <span className="FilterModalInputDropUpIconWrap">
                                    <i
                                      data-e2e="allowableCryptoDropDownIcon"
                                      className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                    />
                                  </span>
                                </div>
                                {props.cryptos.map((crypto, index) => {
                                  let { name, symbol, _id, icon } = crypto;
                                  return (
                                    <div
                                      key={index}
                                      className="currencyListItemWrap"
                                      data-e2e="currencyListItemWrap"
                                      onClick={() => {
                                        toggleSelection(_id);
                                      }}
                                    >
                                      <div className="currencyListIconNameWrap">
                                        <img
                                          src={`${image_base_url}${icon}`}
                                          alt={symbol}
                                          className="currencyList_Icon"
                                          data-e2e={symbol}
                                        />
                                        <div
                                          className="currencyList_Name"
                                          data-e2e={name}
                                        >
                                          {name}
                                        </div>
                                      </div>
                                      <img
                                        data-e2e="checkBox"
                                        src={`${image_base_url}${props.selectedCryptos.includes(_id)
                                            ? selectedCheckBoxIcon
                                            : emptyCheckBoxIcon
                                          }`}
                                        alt="checkbox"
                                        className="currencyListCheckBox"
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                      {item.id === "time_period_locking" && (
                        <div className="FilterModalInputGroup">
                          <div
                            className="FilterModalInputLabel"
                            style={{ fontSize: "13px" }}
                          >
                            {item.label}
                          </div>
                          <div
                            className={!item.disableField?"FilterModalInputDropdown":"FilterModalInputDropdown FilterModalInputDropdownDisabled"}
                            data-e2e="FilterModalInputDropdown"
                            onClick={() => {
                              if(!item.disableField){
                                setTimeLocking(true);
                              }
                              setShowCurrencyDropdown(false);
                              setMerchantAutoWithdrawalDropDown(false)
                              setUserDeposit(false)
                            }}
                          >
                            <div
                              className="FilterModalInputText"
                              data-e2e="FilterModalInputText"
                            >
                              {(props.timeLockingPeriod &&
                                `${props.timeLockingPeriod} mins`) ||
                                "Select Time Period"}
                            </div>
                            <i
                              className="fa fa-chevron-down FilterModalInputDropIcon"
                              data-e2e="timeLockingPeriodDropDown"
                            />
                          </div>
                          {timeLocking && (
                            <div
                              className="FilterModalCurrencyDropdownlist"
                              data-e2e="FilterModalCurrencyDropdownlist"
                            >
                              <div
                                className="FM_currencyDrop_All"
                                data-e2e="FM_currencyDrop_All"
                                onClick={() => {
                                  setTimeLocking(false);
                                }}
                              >
                                <span
                                  className="FM_currencyDrop_AllSelector"
                                  data-e2e="FM_currencyDrop_AllSelector"
                                >
                                  {(props.timeLockingPeriod &&
                                    `${props.timeLockingPeriod} mins`) ||
                                    "Select Time Period"}
                                </span>
                                <span className="FilterModalInputDropUpIconWrap">
                                  <i
                                    data-e2e="allowableCryptoDropDownIcon"
                                    className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                  />
                                </span>
                              </div>
                              {item.options &&
                                item.options.map((ele, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="currencyListItemWrap"
                                      data-e2e={ele.name}
                                      name={ele.name}
                                      onClick={(e) => {
                                        props.handleTimePeriod(ele.value);
                                        setTimeLocking(false);
                                      }}
                                    >
                                      <div
                                        className="currencyListIconNameWrap"
                                        data-e2e={ele.name}
                                      >
                                        <div
                                          className="currencyList_Name"
                                          data-e2e={ele.label}
                                        >
                                          {ele.label}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      )}
                      {item.id === "cardAcquireRouting" && (
                        <div className="FilterModalInputGroup">
                          <div
                            className="FilterModalInputLabel"
                            style={{ fontSize: "13px" }}
                          >
                            {item.label}
                          </div>
                          <div
                            className="FilterModalInputDropdown"
                            data-e2e="FilterModalInputDropdown"
                            onClick={() => {
                              setShowCurrencyDropdown(false);
                              setTimeLocking(false);
                              setUserDeposit(false);
                              setMerchantAutoWithdrawalDropDown(false)
                              setShowCardServiceDropdown(true)
                            }}
                          >
                            <div
                              className="FilterModalInputText"
                              data-e2e="FilterModalInputText"
                            >
                              {props.cardAcquireName.label}
                            </div>
                            <i
                              className="fa fa-chevron-down FilterModalInputDropIcon"
                              data-e2e="timeLockingPeriodDropDown"
                            />
                          </div>
                          {showCardServiceDropdown && (
                            <div
                              className="FilterModalCurrencyDropdownlist"
                              data-e2e="FilterModalCurrencyDropdownlist"
                            >
                              <div
                                className="FM_currencyDrop_All"
                                data-e2e="FM_currencyDrop_All"
                                onClick={() => {
                                  setShowCardServiceDropdown(false);
                                }}
                              >
                                <span
                                  className="FM_currencyDrop_AllSelector"
                                  data-e2e="FM_currencyDrop_AllSelector"
                                >
                                  {props.cardAcquireName.label}
                                </span>
                                <span className="FilterModalInputDropUpIconWrap">
                                  <i
                                    data-e2e="allowableCryptoDropDownIcon"
                                    className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                  />
                                </span>
                              </div>
                              {item.options &&
                                item.options.map((ele, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="currencyListItemWrap"
                                      data-e2e={item.name}
                                      name={item.name}
                                      onClick={(e) => {
                                        props.setCardAcquireName(
                                          ele
                                        );
                                        setShowCardServiceDropdown(false);
                                      }}
                                    >
                                      <div
                                        className="currencyListIconNameWrap"
                                        data-e2e={item.name}
                                      >
                                        <div
                                          className="currencyList_Name"
                                          data-e2e={ele.label}
                                        >
                                          {ele.label}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      )}

                      {item.id === "merchantWithdrawalAutoApproval" && (
                        <div className="FilterModalInputGroup">
                          <div
                            className="FilterModalInputLabel"
                            style={{ fontSize: "13px" }}
                          >
                            {item.label}
                          </div>
                          <div
                            className="FilterModalInputDropdown"
                            data-e2e="FilterModalInputDropdown"
                            onClick={() => {
                              setShowCurrencyDropdown(false);
                              setTimeLocking(false);
                              setUserDeposit(false);
                              setMerchantAutoWithdrawalDropDown(true)
                            }}
                          >
                            <div
                              className="FilterModalInputText"
                              data-e2e="FilterModalInputText"
                            >
                              {props.merchantWithdrawalAutoApproval === true
                                ? "Enable"
                                : "Disable"}
                            </div>
                            <i
                              className="fa fa-chevron-down FilterModalInputDropIcon"
                              data-e2e="timeLockingPeriodDropDown"
                            />
                          </div>
                          {merchantAutoWithdrawalDropDown && (
                            <div
                              className="FilterModalCurrencyDropdownlist"
                              data-e2e="FilterModalCurrencyDropdownlist"
                            >
                              <div
                                className="FM_currencyDrop_All"
                                data-e2e="FM_currencyDrop_All"
                                onClick={() => {
                                  setMerchantAutoWithdrawalDropDown(false);
                                }}
                              >
                                <span
                                  className="FM_currencyDrop_AllSelector"
                                  data-e2e="FM_currencyDrop_AllSelector"
                                >
                                  {props.merchantWithdrawalAutoApproval === true
                                    ? "Enable"
                                    : "Disable"}
                                </span>
                                <span className="FilterModalInputDropUpIconWrap">
                                  <i
                                    data-e2e="allowableCryptoDropDownIcon"
                                    className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                  />
                                </span>
                              </div>
                              {item.options &&
                                item.options.map((ele, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="currencyListItemWrap"
                                      data-e2e={ele.name}
                                      name={ele.name}
                                      onClick={(e) => {
                                        props.handleMerchantAutoWithdrawal(
                                          ele.value
                                        );
                                        setMerchantAutoWithdrawalDropDown(false);
                                      }}
                                    >
                                      <div
                                        className="currencyListIconNameWrap"
                                        data-e2e={ele.name}
                                      >
                                        <div
                                          className="currencyList_Name"
                                          data-e2e={ele.label}
                                        >
                                          {ele.label}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      )}
                      {item.id === "userWalletDeposit" && (
                        <div className="FilterModalInputGroup">
                          <div
                            className="FilterModalInputLabel"
                            style={{ fontSize: "13px" }}
                          >
                            {item.label}
                          </div>
                          <div
                            className="FilterModalInputDropdown"
                            data-e2e="FilterModalInputDropdown"
                            onClick={() => {
                              setShowCurrencyDropdown(false);
                              setTimeLocking(false);
                              setUserDeposit(true);
                              setMerchantAutoWithdrawalDropDown(false)
                            }}
                          >
                            <div
                              className="FilterModalInputText"
                              data-e2e="FilterModalInputText"
                            >
                              {props.userWalletDeposit === true
                                ? "Enable"
                                : "Disable"}
                            </div>
                            <i
                              className="fa fa-chevron-down FilterModalInputDropIcon"
                              data-e2e="timeLockingPeriodDropDown"
                            />
                          </div>
                          {userDeposit && (
                            <div
                              className="FilterModalCurrencyDropdownlist"
                              data-e2e="FilterModalCurrencyDropdownlist"
                            >
                              <div
                                className="FM_currencyDrop_All"
                                data-e2e="FM_currencyDrop_All"
                                onClick={() => {
                                  setUserDeposit(false);
                                }}
                              >
                                <span
                                  className="FM_currencyDrop_AllSelector"
                                  data-e2e="FM_currencyDrop_AllSelector"
                                >
                                  {props.userWalletDeposit === true
                                    ? "Enable"
                                    : "Disable"}
                                </span>
                                <span className="FilterModalInputDropUpIconWrap">
                                  <i
                                    data-e2e="allowableCryptoDropDownIcon"
                                    className="fa fa-chevron-up FilterModalInputDropUpIcon"
                                  />
                                </span>
                              </div>
                              {item.options &&
                                item.options.map((ele, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="currencyListItemWrap"
                                      data-e2e={ele.name}
                                      name={ele.name}
                                      onClick={(e) => {
                                        props.handleUserWalletDeposit(
                                          ele.value
                                        );
                                        setUserDeposit(false);
                                      }}
                                    >
                                      <div
                                        className="currencyListIconNameWrap"
                                        data-e2e={ele.name}
                                      >
                                        <div
                                          className="currencyList_Name"
                                          data-e2e={ele.label}
                                        >
                                          {ele.label}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {props.errorMsg !== "" && props.errorField === item.name && (
                  <div style={{ color: "red" }}>{props.errorMsg}</div>
                )}
                {item.input_type === "file" && (
                  <div key={index}>
                    <label>
                      <b>{item.label}</b>
                    </label>
                    <input
                      className="create_account_input"
                      type="file"
                      data-max-size="5000000"
                      id={item.id}
                      name={item.name}
                      placeholder={item.placeholder}
                      data-e2e={item.id}
                      accept="image/jpeg"
                      onChange={(e) => handleProfileImage(e)}
                      required
                    />
                  </div>
                )}
                {item.input_type === "input_button" && (
                  <div
                    className="login_button_get_started"
                    key={index}
                    data-e2e="login_button_get_started"
                  >
                    <button
                      onClick={() => item.onClick(data)}
                      data-e2e={item.name}
                    >
                      {item.name}
                    </button>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default CreateAccountStageTwo;
