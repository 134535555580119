// Determines the number of recent days to display transactions when the table is first loaded
//* /merchant-transactions (TransactionsMerchant, TransactionTableMerchant)
//* /transactions, /merchantId transaction tab
export const NUMBER_RECENT_DAYS_FILTER = 3;


export function formatTransactionId(transactionId) {
	if (!transactionId) return "N/A";
	const transactionIdLength = transactionId.length;
	return `${transactionId.substring(0, 5)}...${transactionId.substring(transactionIdLength - 6, transactionIdLength)}`
}

export function formatSubstring(str, index, lengthLimit) {
	if (!str) return "N/A";
	const res = `${str.substring(0, index)}...${str.substring(str.length - index)}`
  if (lengthLimit && str.length > lengthLimit) return res;
  if (!lengthLimit) return res;
  return str;
}

export function formatNotes(notes) {
	return notes.reduce((accumulator, currentValue, index) => {
		if (index === 0) return currentValue.note;
		return accumulator + '; ' + currentValue.note;
	}, '');
}

export const customStylesTable = {
	headCells: {
		style: {
			fontSize: "12px",
			color: "#000",
			fontWeight: 700			
		},
	},
	cells: {
		style: {
			paddingTop: "3px",
			paddingBottom: "3px",
			fontSize: "14px",
		}
	}
}