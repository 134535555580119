import React, { useState, useEffect } from "react";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";

const AMLRulesTab = (props) => {
  const { acquirerServiceData } = props;
  const [amlRulesData, setAMLRulesData] = useState(null);
  const listOfConfigurations = [
    {
      name: "dailyTransactionsLimitForAUser",
      label: "Daily transactions limit for a user",
      placeholder: "Enter transaction count",
    },
    {
      name: "weeklyTransactionsLimitForAUser",
      label: "Weekly transactions limit for a user",
      placeholder: "Enter transaction count",
    },
    {
      name: "monthlyTransactionsLimitForAUser",
      label: "Monthly transactions limit for a user",
      placeholder: "Enter transaction count",
    },
    {
      name: "maximumUSDAmountLimitForATransaction",
      label: "Maximum USD amount limit for a transaction",
      placeholder: "Enter maximum USD Amount",
    },
    {
      name: "minimumUSDAmountLimitForATransaction",
      label: "Minimum USD amount limit for a transaction",
      placeholder: "Enter minimum USD Amount",
    },
    {
      name: "totalDailyUSDAmountLimitForAUser",
      label: "Total daily USD amount limit for a user",
      placeholder: "Enter total USD Amount for a day",
    },
    {
      name: "totalDailyUSDAmountLimitForPlatform",
      label: "Total daily USD amount limit for platform",
      placeholder: "Enter total USD Amount for platform",
    },
    {
      name: "totalWeeklyUSDAmountLimitForAUser",
      label: "Total weekly USD amount limit for a user",
      placeholder: "Enter total USD Amount for a week",
    },
    {
      name: "totalMonthlyUSDAmountLimitForAUser",
      label: "Total monthly USD amount limit for a user",
      placeholder: "Enter total USD Amount for a month",
    },
    {
      name: "totalUniqueCardsForUser",
      label: "Total Unique Cards",
      placeholder: "Total  unique Cards",
    },
    {
      name: "limitUserCardAfterTransactionFailed",
      label: "Limit User Card After Failed Transaction",
      placeholder: "Limit User Card After Failed Transaction",
    },
    {
      name: "removeUnusedCardsAfterDays",
      label: "Remove Unused cards after Days of Inactivity",
      placeholder: "Remove Unused cards after Days of Inactivity",
    },
  ];
  const handleConfigChange = (e) => {
    setAMLRulesData({ ...amlRulesData, [e.name]: e.value.trim() });
  };
  const updateCardRoutingService = async (amlRulesData) => {
    console.log("amlRulesDatai s", amlRulesData);
    // API call
  };
  useEffect(() => {
    if (acquirerServiceData) {
      let amlRulesDataObj = {
        dailyTransactionsLimitForAUser:
          acquirerServiceData?.dailyTransactionsLimitForAUser
            ? acquirerServiceData?.dailyTransactionsLimitForAUser
            : "",
        weeklyTransactionsLimitForAUser:
          acquirerServiceData?.weeklyTransactionsLimitForAUser
            ? acquirerServiceData?.weeklyTransactionsLimitForAUser
            : "",
        monthlyTransactionsLimitForAUser:
          acquirerServiceData?.monthlyTransactionsLimitForAUser
            ? acquirerServiceData?.monthlyTransactionsLimitForAUser
            : "",
        maximumUSDAmountLimitForATransaction:
          acquirerServiceData?.maximumUSDAmountLimitForATransaction
            ? acquirerServiceData?.maximumUSDAmountLimitForATransaction
            : "",
        minimumUSDAmountLimitForATransaction:
          acquirerServiceData?.minimumUSDAmountLimitForATransaction
            ? acquirerServiceData?.minimumUSDAmountLimitForATransaction
            : "",
        totalDailyUSDAmountLimitForAUser:
          acquirerServiceData?.totalDailyUSDAmountLimitForAUser
            ? acquirerServiceData?.totalDailyUSDAmountLimitForAUser
            : "",
        totalDailyUSDAmountLimitForPlatform:
          acquirerServiceData?.totalDailyUSDAmountLimitForPlatform
            ? acquirerServiceData?.totalDailyUSDAmountLimitForPlatform
            : "",
        totalWeeklyUSDAmountLimitForAUser:
          acquirerServiceData?.totalWeeklyUSDAmountLimitForAUser
            ? acquirerServiceData?.totalWeeklyUSDAmountLimitForAUser
            : "",
        totalMonthlyUSDAmountLimitForAUser:
          acquirerServiceData?.totalMonthlyUSDAmountLimitForAUser
            ? acquirerServiceData?.totalMonthlyUSDAmountLimitForAUser
            : "",
        totalUniqueCardsForUser: acquirerServiceData?.totalUniqueCardsForUser
          ? acquirerServiceData?.totalUniqueCardsForUser
          : "",
        limitUserCardAfterTransactionFailed:
          acquirerServiceData?.limitUserCardAfterTransactionFailed
            ? acquirerServiceData?.limitUserCardAfterTransactionFailed
            : "",
        removeUnusedCardsAfterDays:
          acquirerServiceData?.removeUnusedCardsAfterDays
            ? acquirerServiceData?.removeUnusedCardsAfterDays
            : "",
      };
      setAMLRulesData(amlRulesDataObj);
    }
  }, []);
  return (
    <div className="TabContainer">
      <div className="h-full max-w-[400px] bg-[#F9FAFB]">
        <h2 className="heading">AML Rules</h2>
        <form>
          {amlRulesData &&
            listOfConfigurations?.map((config, i) => {
              return (
                <div key={i} className="form-group myAccoutOptModal_form_group">
                  <label
                    className="myAccoutOptModalLable"
                    htmlFor={config?.name}
                  >
                    {config?.label}
                  </label>
                  <input
                    type="text"
                    value={amlRulesData[config?.name]}
                    className="form-control myAccoutOptModalLable_input_wrap"
                    placeholder={config?.placeholder}
                    name={config?.name}
                    onChange={(e) => {
                      handleConfigChange(e?.target);
                    }}
                  />
                </div>
              );
            })}
          <div>
            <button
              onClick={(e) => {
                e?.preventDefault();
                isViewPermissionValid
                  ? null
                  : updateCardRoutingService(amlRulesData);
              }}
              className={`btnAddService ${isViewPermissionValid ? "disabled" : ""}`}
            >
              Update Configurations
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AMLRulesTab;
