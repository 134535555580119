import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IOSSwitch from "./IOSSwitch";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import AddOrEditAcquirer from "./AddOrEditAcquirer";
import DeleteIntegration from "./DeleteIntegration";

const AcquirerTab = ({ acquireService, aquireNameData }) => {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const [addNew, setAddNew] = useState(false);
  const [editData, setEditData] = useState([]);
  const [editOpted, setEditOpted] = useState(false);
  const [openAddOrEditIcon, setOpenAddOrEditIcon] = useState(false);
  const [openDeleteIcon, setOpenDeleteIcon] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [currentAcquirerName, setCurrentAcquirerName] = useState("");
  const [deletingId, setDeletingId] = useState("");
  const handleCloseAddNew = () => {
    setCurrentAcquirerName("");
    setAddNew(false);
    setEditOpted(false);
  };
  const handleOpenAddNew = () => {
    setAddNew(true);
    setEditOpted(false);
    setOpenAddOrEditIcon(true);
  };
  const openEditDialog = (item) => {
    setCurrentAcquirerName(item);
    setEditOpted(true);
    setAddNew(true);
    setOpenAddOrEditIcon(true);
  };

  const openDeleteDialog = (item) => {
    setDeletingId(item?._id);
    setOpenDeleteIcon(true);
    setShowModel(true);
  };
  const handleCloseDeleteDialog = () => {
    setShowModel(false);
    setDeletingId("");
    setOpenDeleteIcon(false);
  };
  const handletoggleActiveAquirerService = async (payload) => {
    try {
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onClickSwitch = (e, item) => {
    const payload = {
      isAcquireServiceActive: !e.target.checked ? false : true,
      codeid: item._id,
      uniqueId: acquireService.uniqueId,
    };
    handletoggleActiveAquirerService(payload);
  };
  return (
    <div className="TabContainer">
      <div className="h-full bg-[#F9FAFB]">
        <h2 className="heading">Acquirers</h2>
        <div>
          {acquireService?.routingService.map((item, i) => {
            return (
              <div key={i} className=" ml-[10px] border-b">
                <div className="flex w-full max-w-[600px] px-2  py-4  ">
                  <div className="flex-1 ">{item?.acquirerName}</div>
                  <div className="flex-1">
                    <IOSSwitch
                      data-e2e={`${item?.acquirerName}-${item?.isAcquireServiceActive}`}
                      defaultChecked={item?.isAcquireServiceActive}
                      className={`${isViewPermissionValid ? "disabled" : ""}`}
                      onClick={(e) =>
                        isViewPermissionValid ? null : onClickSwitch(e, item)
                      }
                    />
                  </div>
                  <div className="flex min-w-[100px] max-w-[200px] items-center justify-between px-1 py-2">
                    <EditIcon
                      className={`text-blue-500 ${isViewPermissionValid ? "disabled" : ""}`}
                      fontSize="small"
                      onClick={() =>
                        isViewPermissionValid ? null : openEditDialog(item)
                      }
                    />

                    <DeleteIcon
                      className={`text-danger ${isViewPermissionValid ? "disabled" : ""}`}
                      onClick={() =>
                        isViewPermissionValid ? null : openDeleteDialog(item)
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <button
            onClick={() => (isViewPermissionValid ? null : handleOpenAddNew())}
            className={`btnAddService ${isViewPermissionValid ? "disabled" : ""}`}
          >
            Add Acquirer
          </button>
        </div>
      </div>
      {/* edit and add acquirer */}
      {openAddOrEditIcon && (
        <Dialog
          open={addNew}
          onClose={() => {
            handleCloseAddNew();
            setEditData([]);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AddOrEditAcquirer
            handleCloseAddNew={() => handleCloseAddNew()}
            editOpted={editOpted}
            currentAcquirerName={currentAcquirerName}
            setAddNew={setAddNew}
            setOpenAddOrEditIcon={setOpenAddOrEditIcon}
            aquireNameData={aquireNameData}
          />
        </Dialog>
      )}

      {/* delete acquirer */}
      {openDeleteIcon && (
        <DeleteIntegration
          imageBaseURL={image_base_url}
          deletingId={deletingId}
          msg=""
          onHide={() => handleCloseDeleteDialog()}
        />
      )}
    </div>
  );
};

export default AcquirerTab;
