const walletStatusMapping = {
	'DepositMonitor:DepositPendingManualApproval': 'witheld',
	'DepositMonitor:MonitoringMemPool': 'pending',
	'DepositFlow:AwaitingDeposit': 'pending',
	'DepositFlow:Launched': 'pending',
	'CreatePayment': 'pending',
	'DepositMonitor:WalletStatusAvailable': 'pending',
	'DepositMonitor:WalletStatusLocked': 'pending',
	'DepositMonitor:UpdateWalletStatus': 'pending',
	'DepositMonitor:NotfiyMerchant': 'pending',
	'DepositMonitor:DepositManualApprove': 'pending',
	'DepositMonitor:KYTCheckFail': 'pending',
	'DepositMonitor:KYTCheckPass': 'pending',
	'DepositMonitor:KYTCheck': 'pending',
	'DepositMonitor:DepositConfirmed': 'pending',
	'DepositMonitor:GasTooLow': 'pending',
	'DepositMonitor:ExcessTransactionExecuted': 'pending',
	'DepositMonitor:TransactionExecuted': 'pending',
	'DepositMonitor:TransactionExecuting': 'pending',
	'DepositMonitor:DepositReceivedToMemPool': 'pending',
	'DepositMonitor:TransactionComplete': 'pending',
	'DepositMonitor:DepositManuallyCompleted': 'success',
	'DepositMonitor:DepositCompleted': 'success',
	'MerchantWallet:BalanceUpdated': 'success',
	'WalletManagementService:WithdrawalCompleted': 'success',
	'WalletManagementService:TransferCompleted': 'success',
	'DepositFlowViaWallet:DepositCompleted': 'success',
	'Active': 'active',
	'Available': 'available',
	'Inactive': 'inactive',
	'Pending': 'pending',
	'Locked': 'locked',
	'Transfer Requested': 'transfer requested',
	'In Use': 'in use',
	'Failed': 'failed',
	'Inuse': 'inuse', //?: why two in use statuses?
};

const transactionStatusMapping = {
  'DepositMonitor:DepositPendingManualApproval': 'required approval',
  'WithdrawalFlow:PendingApproval': 'required approval',
  'DepositMonitor:WalletStatusAvailable': 'processing',
  'DepositMonitor:WalletStatusLocked': 'processing',
  'DepositMonitor:UpdateWalletStatus': 'processing',
  'DepositMonitor:NotfiyMerchant': 'processing',
  'DepositMonitor:DepositManualApprove': 'processing',
  'DepositMonitor:KYTCheckFail': 'processing',
  'DepositMonitor:KYTCheckPass': 'processing',
  'DepositMonitor:KYTCheck': 'processing',
  'DepositMonitor:DepositConfirmed': 'processing',
  'DepositMonitor:GasTooLow': 'processing',
  'DepositMonitor:ExcessTransactionExecuted': 'processing',
  'DepositMonitor:TransactionExecuted': 'processing',
  'DepositMonitor:TransactionExecuting': 'processing',
  'DepositMonitor:DepositReceivedToMemPool': 'processing',
  'DepositMonitor:TransactionComplete': 'processing',
  'DepositMonitor:DepositRejected': 'rejected',
  'DepositMonitor:DepositFailed': 'rejected',
  'DepositMonitor:RefundFailed': 'rejected',
  'WalletManagementService:WithdrawalRejectionCompleted': 'rejected',
  'DepositMonitor:DepositManuallyCompleted': 'success',
  'DepositMonitor:DepositCompleted': 'success',
  'MerchantWallet:BalanceUpdated': 'success',
  'WalletManagementService:WithdrawalCompleted': 'success',
  'WalletManagementService:TransferCompleted': 'success',
  'DepositFlowViaWallet:DepositCompleted': 'success',
  'DepositMonitor:DepositRefunded': 'success',
  'CreatePayment': 'pending',
  'DepositFlow:Launched': 'pending',
  'DepositFlow:AwaitingDeposit': 'pending',
  'DepositMonitor:MonitoringMemPool': 'pending',
};

const clientTransactionStatusMapping = {
	'PrivateClient:TransactionCompleted': 'success',
  'PrivateClient:CreateTransaction': 'pending',
  'PrivateClient:Rejected': 'rejected',
  'PrivateClient:ClientBalanceUpdated': 'processing',
  'PrivateClient:DepositDetail': 'processing',
  'PrivateClient:FXDetail': 'processing',
  'PrivateClient:PaymentOutDetail': 'processing',
  'PrivateClient:TransferDetail': 'processing',
  'PrivateClient:WithdrawalDetail': 'processing',
	'PrivateClient:ErrorEncountered': 'error',
	'PrivateClient:RequestForApproval': 'required approval'
}

export const getWalletStatus = (status) => walletStatusMapping[status] || "failed";
export const getTransactionStatus = (status) => transactionStatusMapping[status] || "processing";
export const getClientTransactionStatus = (status) => clientTransactionStatusMapping[status] || "processing";