import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import {
  GET_SINGLE_TRANSFER_WALLET,
  GET_TRANSFER_WALLETS,
  SAVE_TRANSACTION_FROM_ADMIN,
  UPDATE_WALLET_STATUS,
  VIEW_SINGLE_MERCHANT_WALLET,
  GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
} from '../../../../config/endpoints';
import { call, logoutAfterError } from '../../../../config/axios';
import localDb from '../../../../localDb';
import { withStyles } from 'tss-react/mui';
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import WarningIcon from "@mui/icons-material/Warning";

const email = localDb.getVal('email');

const WalletTable = (props) => {
  const [loading, setLoading] = useState(false);
	const { orderBy, sortBy } = props;
	function SortIconElement (props) {
		const isActive = props.isActive; 
		return (
			<i className={`fa fa-chevron-${isActive && orderBy === "desc" ? 'up' : 'down' } ${isActive ? 'active' : ''}`} />
		);
	};

  return (
		<div>
			<div className="theme-table">
				<Table responsive>
					<tbody>
						<tr>
							<th className="theme-table-hover" onClick={() => {props.changeOrder('blockchain');}}>
								<p className="theme-table-text">
									Blockchain
									<SortIconElement isActive={sortBy === 'blockchain'} />
								</p>
							</th>
							<th className="theme-table-hover" onClick={() => {props.changeOrder('cryptoId.cryptoId');}}>
								<p className="theme-table-text">
									Crypto
									<SortIconElement isActive={sortBy === 'cryptoId.cryptoId'} />
								</p>
							</th>
							<th className="theme-table-hover" onClick={() => {props.changeOrder('network');}}>
								<p className="theme-table-text">
									Network
									<SortIconElement isActive={sortBy === 'network'} />
								</p>
							</th>
							<th className="theme-table-hover" onClick={() => {props.changeOrder('address.key');}}>
								<p className="theme-table-text">
									Wallet Address
									<SortIconElement isActive={sortBy === 'address.key'} />
								</p>
							</th>
							<th className="theme-table-hover" onClick={() => {props.changeOrder('address.seedIndex');}}>
								<p className="theme-table-text">
									Seed Index
									<SortIconElement isActive={sortBy === 'address.seedIndex'} />
								</p>
							</th>
							<th className="theme-table-hover" onClick={() => {props.changeOrder('status.updated');}}>
								<p className="theme-table-text">
									Last Used
									<SortIconElement isActive={sortBy === 'status.updated'} />
								</p>
							</th>
							<th className="theme-table-hover" onClick={() => {props.changeOrder('balance.balanceAmountUSDApprox');}}>
								<p className="theme-table-text">
									Approx USD Balance
									<SortIconElement isActive={sortBy === 'balance.balanceAmountUSDApprox'} />
								</p>
							</th>
							<th className="theme-table-hover" onClick={() => {props.changeOrder('status.code');}}>
								<p className="theme-table-text">
									Status
									<SortIconElement isActive={sortBy === 'status.code'} />
								</p>
							</th>
							<th className="theme-table-hover" onClick={() => {props.changeOrder('merchantDetails.name');}}>
								<p className="theme-table-text">
									Merchant Name
									<SortIconElement isActive={sortBy === 'merchantDetails.name'} />
								</p>
							</th>
						</tr>
						{props && props.data && props.data.length > 0 ? (
							props.data.map((TData, i) => {
								return (
									<SingleTransactionTableRow
										data={TData}
										key={i}
										loading={loading}
										setLoading={setLoading}
										getWallets={() => {
											props.getWallets();
										}}
									/>
								);
							})
						) : (
							<tr data-e2e="No_Transactions_Found">
								<td colSpan="10">
									<p className="theme-table-empty">No Data Found</p>
								</td>
							</tr>
						)}
					</tbody>
				</Table>
			</div>
			{props.showMoreButton && 
				<div className="theme-btn-block center show-btn">
					<div className="theme-btn" data-e2e="showMore" onClick={() => {props.handleShowMore();}}>
						<p>Show more</p>
					</div>
				</div>
			}
		</div>
  );
};

export default WalletTable;

const SingleTransactionTableRow = (props) => {
  let { data } = props;
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const api_url = useSelector((state) => state.config.api_url);
  const [selectedTransferWallet, setSelectedTransferWallet] =
    useState('Select');
  const [walletIdForTransferFunds, setWalletIdForTransferFunds] =
    useState(null);
  const [showModal, toggleShowModal] = useState(false);
  const [alertModal, toggleAlertModal] = useState(false);
  const [loadingExchange, setLodingExchange] = useState(false);
  const [showSuccess, toggleShowSuccess] = useState(false);
  const [selectedWalletForTransfer, setSelectedWallet] = useState(null);
  const [selectedToken, setSelectedToken] = useState("Select");
  const [selectedTokenDetails, setSelectedTokenDetails] = useState(null);
  const [cryptoAmount, setCryptoAmount] = useState(0);
  const [cryptoEquUSDAmount, setCryptoEquUSDAmount] = useState(0);
  const [showError, toggleShowError] = useState(false);
  const [limitError, setLimitError] = useState("");
  const [transferWallets, setTransferWallets] = useState([]);
  const [singleTransferWallets, setSingleTransferWallets] = useState(null);
  const view_transaction_url = useSelector((state) => state.config.api_url);
  const payment_api_url = useSelector((state) => state.config.payment_api_url);

  const getStatusComponent = (status) => {
    switch (status) {      
      case 'DepositMonitor:DepositPendingManualApproval':
        return <div className="theme-table-status witheld" data-e2e="withHeld">WITHELD</div>;
      case 'DepositMonitor:MonitoringMemPool':
      case 'DepositFlow:AwaitingDeposit':
      case 'DepositFlow:Launched':
      case 'CreatePayment':
      case 'DepositMonitor:WalletStatusAvailable':
      case 'DepositMonitor:WalletStatusLocked':
      case 'DepositMonitor:UpdateWalletStatus':
      case 'DepositMonitor:NotfiyMerchant':
      case 'DepositMonitor:DepositManualApprove':
      case 'DepositMonitor:KYTCheckFail':
      case 'DepositMonitor:KYTCheckPass':
      case 'DepositMonitor:KYTCheck':
      case 'DepositMonitor:DepositConfirmed':
      case 'DepositMonitor:GasTooLow':
      case 'DepositMonitor:ExcessTransactionExecuted':
      case 'DepositMonitor:TransactionExecuted':
      case 'DepositMonitor:TransactionExecuting':
      case 'DepositMonitor:DepositReceivedToMemPool':
      case 'DepositMonitor:TransactionComplete':
        return <div className="theme-table-status pending" data-e2e="pending">PENDING</div>;
      case 'DepositMonitor:DepositManuallyCompleted':
      case 'DepositMonitor:DepositCompleted':
      case 'MerchantWallet:BalanceUpdated':
      case 'WalletManagementService:WithdrawalCompleted':
      case 'WalletManagementService:TransferCompleted':
      case "DepositFlowViaWallet:DepositCompleted":
        return <div className="theme-table-status active" data-e2e="success">SUCCESS</div>;
      case 'Active':
        return <div className="theme-table-status active" data-e2e="active">ACTIVE</div>;
      case 'Available':
        return (
          <div className="theme-table-status active" data-e2e="available">
            AVAILABLE
          </div>
        );
      case 'Inactive':
        return <div className="theme-table-status inactive" data-e2e="inActive">INACTIVE</div>;
      case 'Pending':
        return <div className="theme-table-status pending" data-e2e="pending">PENDING</div>;
      case 'Inactive':
        return <div className="theme-table-status active" data-e2e="success">SUCCESS</div>;
      case 'Locked':
        return (
          <div className="theme-table-status witheld" data-e2e="locked">
            LOCKED
          </div>
        );
      case 'Transfer Requested':
        return <div className="theme-table-status witheld" data-e2e="TRANSFER RQUESTED">TRANSFER RQUESTED</div>;
      case 'In Use':
        return (
          <div className="theme-table-status inactive" data-e2e="IN USE">
            IN USE
          </div>
        );
      case 'Failed':
        return (
          <div className="theme-table-status inactive" data-e2e="Failed">
            FAILED
          </div>
        );
      case 'Inuse':
        return (
          <div className="theme-table-status failed" data-e2e="inUse">
            INUSE
          </div>
        );
      default:
        return (
          <div className="theme-table-status inactive" data-e2e="inUse">
            IN USE
          </div>
        );
    }
  };

  const getTransferWallets = async (blockchain, network) => {
    props.setLoading(true);
    await call(
      {
        ...GET_TRANSFER_WALLETS,
        url: api_url + GET_TRANSFER_WALLETS.url,
      },
      { email: localStorage.getItem('email'), blockchain, network }
    )
      .then((res) => {
        props.setLoading(false);
        if (res.status === 200) {
          setTransferWallets(res.data.transferWallets);
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  };

  const createTransaction = async (data) => {
    let payload = {
      email: localStorage.getItem('email'),
      type: 'Transfer',
      network: data.network,
      createdBy: localStorage.getItem('email'),
      fromWalletId: data._id,
      fromWalletaddress: data.address.key,
      toWalletId: singleTransferWallets._id,
      toWalletAddress: singleTransferWallets.address,
      toWalletName: singleTransferWallets.name,
      cryptoId: selectedToken,
      cryptoAmount: cryptoAmount,
      usdAmount: cryptoEquUSDAmount,
      status: 'WalletTransferFlow:TransferRequested',
      message: "Transfer created by: " + localStorage.getItem('email'),
    };
    props.setLoading(true);
    await call(
      {
        ...SAVE_TRANSACTION_FROM_ADMIN,
        url: api_url + SAVE_TRANSACTION_FROM_ADMIN.url,
      },
      payload
    )
      .then((res) => {
        props.setLoading(false);
        if (res.status === 200) {
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err.message, 'err');
        logoutAfterError(err)

      });
  };

  const transferFunds = async () => {
    if (walletIdForTransferFunds && walletIdForTransferFunds !== '') {
      props.setLoading(true);

      await call(
        {
          ...UPDATE_WALLET_STATUS,
          url: api_url + UPDATE_WALLET_STATUS.url,
        },
        {
          email: localStorage.getItem('email'),
          status: 'Transfer Requested',
          message: `${localStorage.getItem('email')}`,
          walletId: walletIdForTransferFunds,
        }
      )
        .then((res) => {
          props.setLoading(false);
          if (res.status === 200) {
            toggleShowSuccess(true);
            setTimeout(()=>{
              toggleShowSuccess(false);
            }, 2000)
            props.getWallets();
          }
        })
        .catch((err) => {
          toggleShowError(true);
          setTimeout(()=>{
            toggleShowError(false);
          }, 2000)
          props.setLoading(false);
          console.log(err.message, 'err');
          logoutAfterError(err)
        });
    } else {
      toggleShowError(true);
      setTimeout(()=>{
        toggleShowError(false);
      }, 2000)
    }
  };

  const getSingleTransferWallet = async (id) => {
    if (id === "Select") {
      setSelectedTransferWallet(id)
      setSingleTransferWallets(null)
    } else {
      await call(
        {
          ...GET_SINGLE_TRANSFER_WALLET,
          url: api_url + GET_SINGLE_TRANSFER_WALLET.url,
        },
        {
          email: localStorage.getItem('email'),
          id: id,
        }
      )
        .then((res) => {
          props.setLoading(false);
          if (res.status === 200) {
            setSingleTransferWallets(res.data);
            setSelectedTransferWallet(res.data._id);
          }
        })
        .catch((err) => {
          props.setLoading(false);
          console.log(err.message, 'err');
          logoutAfterError(err)
        });
    }
  };

  const viewSingleWalletDetails = async (id) => {
    await call(
      {
        ...VIEW_SINGLE_MERCHANT_WALLET,
        url: view_transaction_url + VIEW_SINGLE_MERCHANT_WALLET.url,
      },
      { email: email, walletId: id }
    )
      .then((res) => {
        if (res.status === 200) {
          setSelectedWallet(res.data.wallets);
          props.setLoading(false);
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err.message, 'err');
        logoutAfterError(err)
      });
  };
  const getSingleCryptoExchangedRate = (amount, cryptoCurrency, fiatCurrency) => {
    return new Promise(async (resolve, reject) => {
      const baseUrl = window.location.hostname.includes("localhost") ? payment_api_url : view_transaction_url
      setLodingExchange(true)
      await call(
        {
          ...GET_CRYPTO_AMOUNT_EXCHANGE_RATE,
          url: baseUrl + GET_CRYPTO_AMOUNT_EXCHANGE_RATE.url,
        },
        { email: email, currencyAmount: amount, fromCurrency: cryptoCurrency, toCurrency: fiatCurrency }
      )
        .then((res) => {
          if (res.status === 200) {
            props.setLoading(false);
            setLodingExchange(false)
            resolve(res.data);
          }
        })
        .catch((err) => {
          props.setLoading(false);
          setLodingExchange(false)
          console.log(err.message, 'err');
          logoutAfterError(err)
        });
    })
  };

  useEffect(() => {
    if (selectedTokenDetails) {
      onCryptoAmountChange(selectedTokenDetails.balanceAmount);
    }
  }, [selectedTokenDetails])

  const handleTransfer = async (id, blockchain, network) => {
    await getTransferWallets(blockchain, network);
    await viewSingleWalletDetails(id);
    toggleShowModal(true);
    setWalletIdForTransferFunds(id);
  };

  const validateAmount = (value) => {
    if (value > selectedTokenDetails.balanceAmount) {
      setLimitError("Amount must be less than equal to " + selectedTokenDetails.balanceAmount + " " + selectedToken);
    } else if (value <= 0) {
      setLimitError("Amount must be greater than zero");
    } else {
      setLimitError("");
    }
  }

  const onCryptoAmountChange = async (value) => {
    if (value) {
      setCryptoAmount(value);
    } else if (value === 0) {
      setCryptoAmount(0);
    } else {
      setCryptoAmount(null);
    }
    const result = await getSingleCryptoExchangedRate(value, selectedToken, "USD");
    if (value && ReadableStreamDefaultController) {
      // const usdEquAmt = parseFloat(value / result.cryptoAmounts[selectedToken]).toFixed(8)
      const usdEquAmt = parseFloat(result).toFixed(8)
      // validateAmount(value)
      setCryptoEquUSDAmount(usdEquAmt);
    } else {
      // validateAmount(0)
      setCryptoEquUSDAmount(0);
    }
  };
  const onCryptoEquUSDAmountChange = async (value) => {
    if (value) {
      setCryptoEquUSDAmount(value);
    } else if (value === 0) {
      setCryptoEquUSDAmount(0);
    } else {
      setCryptoEquUSDAmount(undefined);
    }
    const result = await getSingleCryptoExchangedRate(value, "USD", selectedToken);
    if (value && result) {
      setCryptoAmount(parseFloat(result).toFixed(8))
      // validateAmount(result.cryptoAmounts[selectedToken])
    } else {
      setCryptoAmount(0);
      // validateAmount(0)
    }
  };

  return (
    <tr>
			<td>
				<div className="theme-table-row-td">
					<p className="theme-table-text">{(data && data.blockchain) || 'N/A'}</p>
				</div>
			</td>
			<td>
				<div className="theme-table-row-td">
					<p className="theme-table-text">{(data.cryptoId.cryptoId && data.cryptoId.cryptoId) || "N/A"}</p>
				</div>
			</td>
			<td>
				<div className="theme-table-row-td">
					<p className="theme-table-text">{(data.network && data.network) || 'N/A'}</p>
				</div>
			</td>
			<td>
				<div className="theme-table-row-td" data-e2e-wallet={data && data.address && data.address.key && data.address.key && data.address.key.substring(0, 5)}>
				{data.housekeeper ? (
            data.housekeeper.filter((housekeeperobj) =>
              data.balance.some(
                (balanceobj) =>
                  housekeeperobj.crypto === balanceobj.cryptoId &&
                  housekeeperobj.status === "Unmatched" &&
                  (Math.abs(balanceobj.balanceAmount - housekeeperobj.value) /
                    balanceobj.balanceAmount) *
                    100 <=
                    5
              )
            ).length > 0 ? (
              <HtmlTooltip
                title={<ToolTipBalance data={data} />}
                placement="top"
              >
                <WarningIcon
                  style={{
                    color: "orange",
                    fontSize: "18px",
                    marginRight: "6px",
                  }}
                />
              </HtmlTooltip>
            ) : data.housekeeper.filter((obj) => obj.status === "Unmatched")
                .length > 0 ? (
              <HtmlTooltip
                title={<ToolTipBalance data={data} />}
                placement="top"
              >
                <WarningIcon
                  style={{ color: "red", fontSize: "18px", marginRight: "6px" }}
                />
              </HtmlTooltip>
            ) : (
              <HtmlTooltip
                title={<ToolTipBalance data={data} />}
                placement="top"
              >
                <CheckBoxIcon
                  style={{ color: "green", fontSize: "18px", marginRight: "6px" }}
                />
              </HtmlTooltip>
            )
          ) : (
            " "
          )}
					<Tooltip title={data.address.key && data.address.key} placement="top">
						<Link className="theme-table-text" to={`/merchant-wallets/${data._id}`} data-e2e="wallet" data-e2e-full-addres={data.address.key && data.address.key}>
							{data.address.key
								? data.address.key.substring(0, 5) +
								'.....' +
								data.address.key.substring(
									data.address.key.length - 6,
									data.address.key.length
								)
								: 'N/A'}
						</Link>
					</Tooltip>
				</div>
			</td>
			<td>
				<div className="theme-table-row-td">
					<p className="theme-table-text">{(data.address && data.address.seedIndex) || '0'}</p>
				</div>
			</td>
			<td>
				<div className="theme-table-row-td">
					<p className="theme-table-text">
					{data.status && data.status.updated
          ? moment(data.status.updated).format('DD/MM/YYYY - HH:mm')
          : 'N/A'}
					</p>
				</div>
			</td>
			<td>
				<div className="theme-table-row-td" data-e2e="amount" data-e2e-amount={(data.balanceAmountUSDApprox && parseFloat(data.balanceAmountUSDApprox).toFixed(4)) || '0.0000'}>
					<p className="theme-table-text">{(data.balanceAmountUSDApprox && parseFloat(data.balanceAmountUSDApprox).toFixed(4)) || '0.0000'}</p>
				</div>
			</td>
			<td>
				<div className="theme-table-row-td">
				{data.status && data.status.code
          ? getStatusComponent(data.status.code)
          : 'N/A'}
				</div>
			</td>
			<td>
				<div className="theme-table-row-td">
					<p className="theme-table-text">{data.merchantDetails ? data.merchantDetails.name : null}</p>
				</div>
			</td>
			{/* <td>
				<div className="theme-table-row-td">
					<div>
						{data.status && data.status.code === 'Locked' ? (
							<button type="button"
								className="theme-table-action"
								onClick={() => handleTransfer(data._id, data.blockchain, data.network)}
								data-e2e={(data.blockchain && data.blockchain === "Ethereum") && ((data.balanceAmountUSDApprox && data.balanceAmountUSDApprox) > 0) ? true : false}
								data-e2e-wallet-address={(data && data.address && data.address.key) || null}
							>
								TRANSFER
							</button>
						) : null}
						{showModal ? (
							<div className="merchant_dailog_screen">
								<div>
									<Modal
										show={true}
										className="ClearAlertModal transferModal transferFundsModal"
										onHide={() => {
											toggleShowModal(false);
										}}
									>
										<Modal.Body className="ClearAlertModalBody transferModalBody">
											<div className="transferModalTitleWrap">
												<div className="transferModalTitle" data-e2e="TRANSFER FUNDS">Transfer Funds</div>
												<img
													className="closeIcon"
													alt="closeIcon"
													src={`${image_base_url}${closeImage}`}
													onClick={() => {
														toggleShowModal(false);
													}}
												/>
											</div>
											<div className="form-group myAccoutOptModal_form_group transferModalGroup">
												<label
													className="myAccoutOptModalLable"
													htmlFor="exampleFormControlSelect1"
												>
													Please select an approved destination wallet
												</label>
												<select
													value={selectedTransferWallet}
													className="form-control inputdropdown"
													id="exampleFormControlSelect1"
													name="selectedTransferWallet"
													data-e2e="choose-transfer-wallet"
													onChange={(e) => {
														e.preventDefault();
														getSingleTransferWallet(e.target.value);
													}}
												>
													<option value={'Select'}>
														Choose Transfer Wallet
													</option>
													{transferWallets &&
														transferWallets.length > 0 &&
														transferWallets.map((data, index) => {
															return (
																<option key={index} value={data._id} data-e2e={data.name.toLowerCase().replaceAll(' ', '-')}>
																	{data.name}
																</option>
															);
														})}
												</select>
												<div className="dropdownIcon">
													<i className="fa fa-chevron-down" />
												</div>
											</div>
											{singleTransferWallets && <div className="form-group myAccoutOptModal_form_group transferModalGroup">
												<label
													className="myAccoutOptModalLable"
													htmlFor="exampleFormControlSelect1"
												>
													Destination Wallet Address:
												</label>
												<div className="WalletAddress">{singleTransferWallets.address}</div>
											</div>}
											<div className="form-group myAccoutOptModal_form_group transferModalGroup">
												<label
													className="myAccoutOptModalLable"
													htmlFor="exampleFormControlSelect1"
												>
													CryptoCurrency
												</label>
												<select
													value={selectedToken}
													className="form-control inputdropdown"
													id="exampleFormControlSelect1"
													name="selectedTransferWallet"
													data-e2e="selected-transfer-wallet"
													onChange={(e) => {
														e.preventDefault();
														setSelectedToken(e.target.value);
														if (e.target.value !== "Select") {
															let cryptoObj = {}
															selectedWalletForTransfer.balance.map((data) => {
																if (e.target.value === data.cryptoId) {
																	cryptoObj = data;
																}
															})
															setSelectedTokenDetails(cryptoObj);
															setLimitError("")
														} else {
															setCryptoAmount(0)
															setCryptoEquUSDAmount(0)
														}
													}}
												>
													<option value={'Select'}>
														Select CryptoCurrency
													</option>
													{selectedWalletForTransfer && selectedWalletForTransfer.balance &&
														selectedWalletForTransfer.balance.length > 0 &&
														selectedWalletForTransfer.balance.map((data, index) => {
															return (
																<option key={index} value={data.cryptoId} data-e2e={data.cryptoId.toLowerCase().replaceAll(' ', '-')}>
																	{data.cryptoId}
																</option>
															);
														})}
												</select>
												<div className="dropdownIcon">
													<i className="fa fa-chevron-down" />
												</div>
											</div>
											{selectedToken && selectedToken !== "Select" && <div className="form-group myAccoutOptModal_form_group transferModalGroup">
												<label
													className="myAccoutOptModalLable"
													htmlFor="exampleFormControlSelect1"
												>
													<span>Amount</span>
													{loadingExchange ? <span style={{ fontSize: "12px", fontWeight: "500", marginLeft: "20px" }}>Loading Exchange Value...</span> : null}
												</label>
												<div className="amountExchageWrap">
													<input
														type="number"
														value={cryptoAmount}
														className="form-control inputdropdown"
														id="cryptoAmount"
														data-e2e="crypto-amount"
														placeholder="amount in crypto"
														name="cryptoAmount"
														onChange={(e) => {
															onCryptoAmountChange(e.target.value);
														}}
													/>
													<div className="exchangeIcon"><i className="fa fa-exchange"></i></div>
													<input
														type="number"
														value={cryptoEquUSDAmount}
														className="form-control inputdropdown"
														id="cryptoEquUSDAmount"
														placeholder="amount in USD"
														name="cryptoEquUSDAmount"
														onChange={(e) => {
															onCryptoEquUSDAmountChange(e.target.value);
														}}
													/>
												</div>
											</div>}

											{limitError !== "" && <div className="limitErrorText">{limitError}</div>}
											<div
												className={`ClearAlertModalClearBtn transferFundsBtn ${selectedTransferWallet &&
													selectedTransferWallet !== 'Select' &&
													selectedToken !== 'Select' &&
													limitError === ""
													? ''
													: 'transferFundsBtnDisable'
													}`}
												onClick={() => {
													if (
														selectedTransferWallet &&
														selectedTransferWallet !== 'Select' &&
														selectedToken !== 'Select' &&
														limitError === ""
													) {
														toggleAlertModal(true);
													}
												}}
												data-e2e="transfer-fund-btn"
											>
												TRANSFER FUNDS
											</div>
										</Modal.Body>
									</Modal>
								</div>
							</div>
						) : (
							<style>{`\
								body {
									overflow: auto !important;
								}
						`}</style>
						)}
						{
							<div className="merchant_dailog_screen">
								<Dialog
									open={alertModal}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									maxWidth={'lg'}
								>
									<div>
										<Modal
											show={true}
											className="ClearAlertModal transferModal"
											onHide={() => {
												toggleAlertModal(false);
											}}
										>
											<Modal.Body className="ClearAlertModalBody transferModalBody">
												<div className="ClearAlertModalTitle">Are you sure?</div>
												<div className="ClearAlertModalDescription">
													Are you sure you want to transfer funds to selected
													wallet?
												</div>
												<div
													className={`ClearAlertModalClearBtn transferFundsBtn`}
													onClick={() => {
														if (
															selectedTransferWallet &&
															selectedTransferWallet !== 'Select'
														) {
															transferFunds();
															createTransaction(data);
															toggleAlertModal(false);
															toggleShowModal(false);
														}
													}}
													data-e2e="transfer-btn"
												>
													Transfer
												</div>
												<div
													className="ClearAlertModalGoBackBtn"
													onClick={() => {
														toggleAlertModal(false);
													}}
													data-e2e="go-back-btn"
												>
													Go Back
												</div>
											</Modal.Body>
										</Modal>
									</div>
								</Dialog>
							</div>
						}
						{showSuccess && (
							<div className="successModal">
								<div className="successModalWrap">
									<img
										className="successModalIcon"
										alt="successIcon"
										src={`${image_base_url}${successIcon}`}
									/>
									<div className="successModalMsg">
										Transfer request has been raised successfully!
									</div>
									<img
										data-e2e="close-icon"
										className="closeIcon"
										alt="closeIcon"
										src={`${image_base_url}${closeImage}`}
										onClick={() => {
											toggleShowSuccess(false);
										}}
									/>
								</div>
							</div>
						)}
						{showError && (
							<div className="successModal">
								<div className="successModalWrap">
									<img
										className="successModalIcon"
										alt="errorIcon"
										src={`${image_base_url}${deleteIcon}`}
									/>
									<div className="successModalMsg">
										Something when wrong, transfer failed!{' '}
									</div>
									<img
										className="closeIcon"
										alt="closeIcon"
										src={`${image_base_url}${closeImage}`}
										onClick={() => {
											toggleShowError(false);
										}}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</td> */}
     
    </tr>
  );
};

// HTMl Tooltip
const HtmlTooltip = withStyles(Tooltip, (theme) => ({
	tooltip: {
	  backgroundColor: "#fafafa",
	  color: "#000000",
	  maxWidth: 800,
	  border: "1px solid #dadde9",
	  boxShadow: "10px",
	},
  }));


//content of tooltip
const ToolTipBalance = ({ data }) => {
	return (
	  <div className="wallettooltipcontainer">
		<div className="wallettooltipheading">Wallet Balance </div>
		{data.balance.map((balance, i) => {
		  return (
			<div className="wallettooltiptabletext" key={i}>
			  <div className="wallettooltippadding">{balance.cryptoId}</div>
			  <div className="wallettooltippadding">
				{balance.balanceAmount.toFixed(6)} ($
				{balance.balanceAmountUSDApprox} USD){" "}
			  </div>
			</div>
		  );
		})}
  
		<div className="wallettooltipheading"> Blockchain balance </div>
		{data.housekeeper.map((balance, i) => {
		  return (
			<div className="wallettooltiptabletext" key={i}>
			  <div className="wallettooltippadding">{balance.crypto}</div>
			  <div className="wallettooltippadding">
				{balance.value.toFixed(6)}{" "}
			  </div>
			</div>
		  );
		})}
	  </div>
	);
  };  