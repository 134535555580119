import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import IOSSwitch from "./IOSSwitch";
import TabButton from "../../../../../common/TabButton";
import Loading from "../../../../../common/Loading";
import { isViewPermissionValid } from "../../../../../common/CommonUtils";

const SplitTrafficTab = ({ acquireService }) => {
  const [tabs, setTabs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [acceptFTDValue, setAcceptFTDValue] = useState(false);

  useEffect(() => {
    if (acquireService) {
      getSplitTraffic();
    }
  }, [tabs, acquireService]);

  const handleSave = async () => {
    // need API call
  };

  const getSplitTraffic = async () => {
    // need API call
  };

  const handleTabSwitch = (index) => {
    setTabs(index);
  };

  const onClickFTD = (e) => {
    setAcceptFTDValue(e?.target?.checked);
  };

  if (!acquireService) {
    return <div className="w-full text-center">No Data</div>;
  }

  return (
    <div className="TabContainer">
      <div className="acquireServiceScroll">
        {acquireService?.routingService?.map((tab, index) => (
          <TabButton
            key={index}
            activeTab={tabs}
            handleTabSwitch={handleTabSwitch}
            tab={tab}
            index={index}
          />
        ))}
      </div>
      <div className="ml-3 flex pt-10">
        <Grid container spacing={2}>
          {acquireService?.routingService?.map((items, index) => (
            <div key={index}>
              {tabs === index && (
                <div className="mx-2 my-2 flex py-2">
                  <div className="mr-16 justify-start">Accept FTD</div>
                  <IOSSwitch
                    data-e2e="accept-ftd"
                    checked={acceptFTDValue}
                    onClick={isViewPermissionValid ? null : onClickFTD}
                    className={`ml-20 ${isViewPermissionValid ? "disabled" : ""}`}
                  />
                </div>
              )}
            </div>
          ))}
        </Grid>
      </div>
      <div className="mt-4">
        <button
          className={`splitTrafficButton ${isViewPermissionValid ? "disabled" : ""}`}
          onClick={isViewPermissionValid ? null : handleSave}
          data-e2e="save-btn"
        >
          Save
        </button>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default SplitTrafficTab;
