const axios = require('axios');
const { default: localDb } = require('../localDb');

axios.interceptors.response.use(
  async (response) => {
    const viewPermission = localDb.getVal('viewPermission');
    const newToken = response.headers['x-access-token'];
    if (newToken) {
      localDb.setVal('token', newToken);
    }
    response.headers['x-view-permission'] = viewPermission;
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

exports.call = async (config = { method: 'GET' }, data = {}, params = {}, isDownloadCsv = false) => {
  let url = config.url;
  const token = localDb.getVal('token');
  const viewPermission = localDb.getVal('viewPermission');
  data = {
    ...data,
    adminRole: localStorage.getItem('adminRole') ? localStorage.getItem('adminRole') : null,
  }
  if (config.method === 'GET' && data && Object.keys(data).length > 0) {
    url += '?';
    Object.entries(data).forEach(([key, value]) => {
      url += `${key}=${value}&`;
    });
  }
  if (params && Object.keys(params).length > 0) {
    url += '?';
    Object.entries(params).forEach(([key, value]) => {
      url += `${key}=${value}&`;
    });
  }
  if (url.substr(url.length - 1, url.length) === '&') {
    url = url.slice(0, url.length - 1);
  }
  const options = {
    method: config.method,
    url,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token || '',
      'x-view-permission': viewPermission || '',
    },
    mode: 'cors',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    timeout: isDownloadCsv ? 300000 : 60000,
  };
  if (['POST', 'PUT', 'DELETE'].indexOf(config.method) > -1) {
    // options.data = JSON.stringify(data);
    options.data = data
  }
  return axios(options);
};

let alertDisplayed = false;

exports.logoutAfterError = (err) => {
  if (err.response && err.response.status === 403) {
    const errorMessage = err?.response?.data?.message || "Session Expired please Log In again"
    if(alertDisplayed === false){
      // Display the alert message if it hasn't been displayed yet
      alert(errorMessage)
      alertDisplayed = true; // Set the flag to true to indicate that the alert has been displayed
    }
    // Clear local storage
    localStorage.clear()
    window.location.href = '/login'
  }
  if (err.response && err.response.status === 406 && err.response.data.contentRestricted) {
    alert("This content is forbidden!")
    window.location.href = '/merchants'
  }
}
