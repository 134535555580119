import React, { useState } from "react";
import { useSelector } from "react-redux";
import HeaderAndFooterLayout from "../../../../Layouts/HeaderAndFooter/HeaderAndFooterLayout";
import CommonHeader from "../../../CommonPages/CommonHeader";
import { integrationData, AcquirerData } from "./data";
const integrationIcon = "/cryptonpay/crytoicons/integration.png";
const acquirersIcon = "/cryptonpay/crytoicons/acquirers.png";
const rulesIcon = "/cryptonpay/crytoicons/rules.png";
const splitTrafficIcon = "/cryptonpay/crytoicons/status.png";
const trafficIcon = "/cryptonpay/crytoicons/traffic.png";
const loadIcon = "/cryptonpay/crytoicons/load.png";
const routingIcon = "/cryptonpay/crytoicons/routing.png";
const statusIcon = "/cryptonpay/crytoicons/status.png";

import IntegrationType from "./IntegrationType";
import Sidebar from "./Sidebar";
import AcquirerTab from "./AcquirerTab";
import RulesTab from "./RulesTab";
import SplitTrafficTab from "./SplitTrafficTab";
import AMLRulesTab from "./AMLRulesTab";
import MaxTrafficTab from "./MaxTrafficTab";
import StatusTab from "./StatusTab";
import SuccessRoutingTab from "./SuccessRoutingTab";
import LeastCostRoutingTab from "./LeastCostRoutingTab";
import WeightingTab from "./WeightingTab";


const AcquirerConfig = (props) => {
  const [integrationType, setIntegrationType] = useState(integrationData);
  const [cardAquirer, setCardAquirer] = useState(AcquirerData);
  const imageBaseURL = useSelector((state) => state?.config?.image_base_url);
  const [value, setValue] = useState(1);

  const tabNames = [
    {
      name: "Integration",
      image: imageBaseURL + integrationIcon,
      value: 1,
    },
    {
      name: "Acquirer",
      image: imageBaseURL + acquirersIcon,
      value: 2,
    },
    {
      name: "Rules",
      image: imageBaseURL + rulesIcon,
      value: 3,
    },
    {
      name: "Split Traffic",
      image: imageBaseURL + splitTrafficIcon,
      value: 4,
    },
    {
      name: " AML Rules",
      image: imageBaseURL + trafficIcon,
      value: 5,
    },
  ];
  const tabWithSubTabsNames = [
    {
      name: "Balance",
      image: imageBaseURL + loadIcon,
      subTabs: [
        {
          name: "Maximum Traffic",
          image: imageBaseURL + trafficIcon,
          value: 6,
        },
        {
          name: "Status",
          image: imageBaseURL + statusIcon,
          value: 7,
        },
        {
          name: "Success Routing",
          image: imageBaseURL + routingIcon,
          value: 8,
        },
        {
          name: "LeastCost Routing",
          image: imageBaseURL + routingIcon,
          value: 9,
        },
        {
          name: "Weighting",
          image: imageBaseURL + routingIcon,
          value: 10,
        },
      ],
    },
  ];
  const handletoggleActiveCountryCode = async (payload) => {
    let data = {
      ...payload,
      email: localDb.getVal("email"),
    };

    try {
      await call(
        {
          ...TOGGLE_AQUIRER_SERVICE,
          url: baseURL + TOGGLE_AQUIRER_SERVICE.url,
        },
        data,
      );
    } catch (error) {
      logoutAfterError(error);
      console.error("Error:", error);
    }
  };
  const onSelectTab = (val) => {
    setValue(val);
  };

  return (
    <HeaderAndFooterLayout>
      <CommonHeader name="Global Acquirer Configuration" backButton={true} />
      <div className="cardAcquirerRouting container">
        <div className="flex flex-col md:flex-row">
          <div className="mb-4 mt-12 md:mb-0 md:mt-0">
            <Sidebar
              tabNames={tabNames}
              tabWithSubTabsNames={tabWithSubTabsNames}
              onSelectTab={onSelectTab}
            />
          </div>
          {value === 1 ? (
            <IntegrationType integrationType={integrationType} />
          ) : null}
          {value === 2 ? (
            <AcquirerTab
              acquireService={cardAquirer}
              aquireNameData={integrationType}
            />
          ) : null}
          {value === 3 ? <RulesTab acquireService={cardAquirer} /> : null}
          {value === 4 ? (
            <SplitTrafficTab acquireService={cardAquirer} />
          ) : null}
          {value === 5 ? (
            <AMLRulesTab acquirerServiceData={cardAquirer} />
          ) : null}
          {value === 6 ? (
            <MaxTrafficTab
              acquireService={cardAquirer}
              handletoggleActiveCountryCode={handletoggleActiveCountryCode}
            />
          ) : null}
          {value === 7 ? <StatusTab acquireService={cardAquirer} /> : null}
          {value === 8 ? (
            <SuccessRoutingTab acquireService={cardAquirer} />
          ) : null}
          {value === 9 ? (
            <LeastCostRoutingTab
              acquireService={cardAquirer}
              handletoggleActiveCountryCode={handletoggleActiveCountryCode}
            />
          ) : null}
          {value === 10 ? (
            <WeightingTab
              acquireService={cardAquirer}
              handletoggleActiveCountryCode={handletoggleActiveCountryCode}
            />
          ) : null}
        </div>
      </div>
    </HeaderAndFooterLayout>
  );
};

export default AcquirerConfig;
