const statusMapping = {
    "DepositMonitor:DepositManuallyCompleted": { text: "Deposit Successful", color: "#13FF34" },
    "DepositMonitor:DepositCompleted": { text: "Deposit Successful", color: "#13FF34" },
    "WalletManagementService:WithdrawalCompleted": { text: "Deposit Successful", color: "#13FF34" },
    "DepositFlow:AwaitingDeposit": { text: "Awaiting Deposit", color: "#FFA900" },
    "DepositFlow:Launched": { text: "Awaiting Deposit", color: "#FFA900" },
    "CreatePayment": { text: "Awaiting Deposit", color: "#FFA900" },
    "DepositMonitor:MonitoringMemPool": { text: "Awaiting Deposit", color: "#FFA900" },
    "DepositMonitor:WalletStatusAvailable": { text: "Processing your deposit...", color: "#FFBE3E" },
    "DepositMonitor:WalletStatusLocked": { text: "Processing your deposit...", color: "#FFBE3E" },
    "DepositMonitor:UpdateWalletStatus": { text: "Processing your deposit...", color: "#FFBE3E" },
    "DepositMonitor:NotfiyMerchant": { text: "Processing your deposit...", color: "#FFBE3E" },
    "DepositMonitor:DepositManualApprove": { text: "Processing your deposit...", color: "#FFBE3E" },
    "DepositMonitor:KYTCheckPass": { text: "Processing your deposit...", color: "#FFBE3E" },
    "DepositMonitor:KYTCheckFail": { text: "Processing your deposit...", color: "#FFBE3E" },
    "DepositMonitor:KYTCheck": { text: "Processing your deposit...", color: "#FFBE3E" },
    "DepositMonitor:DepositConfirmed": { text: "Processing your deposit...", color: "#FFBE3E" },
    "DepositMonitor:TransactionComplete": { text: "Processing your deposit...", color: "#FFBE3E" },
    "DepositMonitor:GasTooLow": { text: "Processing your deposit...", color: "#FFBE3E" },
    "DepositMonitor:ExcessTransactionExecuted": { text: "Processing your deposit...", color: "#FFBE3E" },
    "DepositMonitor:TransactionExecuted": { text: "Processing your deposit...", color: "#FFBE3E" },
    "DepositMonitor:TransactionExecuting": { text: "Processing your deposit...", color: "#FFBE3E" },
    "DepositMonitor:DepositReceivedToMemPool": { text: "Processing your deposit...", color: "#FFBE3E" },
    "DepositMonitor:DepositPendingManualApproval": { text: "Require Approval", color: "#FFBE3E" },
    "WithdrawalFlow:PendingApproval": { text: "Require Approval", color: "#FFBE3E" },
    "WithdrawalFlow:Rejected": { text: "Rejected", color: "#a72d2d" },
    "DepositMonitor:DepositRejected": { text: "Rejected", color: "#a72d2d" },
    "DepositFlow:Cancelled": { text: "Cancelled", color: "#a72d2d" },
    "DepositFlow:Abandoned": { text: "Abandoned", color: "#a72d2d" },
    "DepositMonitor:DepositFailed": { text: "Rejected", color: "#a72d2d" },
    "DepositMonitor:RefundFailed": { text: "Rejected", color: "#a72d2d" },
    "WalletManagementService:WithdrawalRejectionCompleted": { text: "Rejected", color: "#a72d2d" }
  };
  
  export const getStatusText = (status) => {
    const mapping = statusMapping[status] || { text: "Processing your deposit" };
    return mapping.text;
  };
  
  export const getStatusColor = (status) => {
    const mapping = statusMapping[status] || { color: "#FFBE3E" };
    return mapping.color;
  };
  