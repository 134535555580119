import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { call } from "../../../../config/axios";
import {
  GET_CARD_ACQUIRE_ROUTING,
  GET_PAYMENT_PROVIDER,
  UPDATE_PAYMENT_PROVIDER,
  DELETE_PAYMENT_PROVIDER_DOMAIN,
} from "../../../../config/endpoints";
import { Modal } from "react-bootstrap";
import localDb from "../../../../localDb";
import Loading from "../../../common/Loading";
import EditDomain from "./editDomain";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from "@mui/icons-material/Close";
const closeImage = "/cryptonpay/crytoicons/close.png";
const deleteIcon = "/cryptonpay/crytoicons/deleteicon.png";

const UpdatePaymentProvider = (props) => {
  const base_url = useSelector((state) => state.config.api_url);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const email = localDb.getVal("email");
  const [loading, setLoading] = useState(false);
  const [acquireRoutings, setAcquireRoutings] = useState([]);
  const [selectedAcquireRouting, setSelectedAcquireRouting] = useState(null);
  const [showStatusDropDown, setShowStatusDropDown] = useState(false);
  const [addNewDomainDialog, setAddNewDomainDialog] = useState(false);
  const [editDomainDialog, setEditDomainDialog] = useState(false);
  const[domainToDelete, setDomainToDelete] = useState(null);
  const [selectedEditDomain, setSelectedEditDomain] = useState(null);
  const [cardDropDown, setCardDropDown] = useState(false);
  const [domains, setDomains] = useState([]);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    password: "",
    confirm_password: "",
    domainNameUrl: "",
    // redirectUrl: "",
    // font: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    postcode: "",
    phoneNo: "",
    card: "Disabled",
  });

  useEffect(() => {
    getPaymentProviderDetails();
  }, []);

  const getAcquireRouting = async (routingId) => {
    setLoading(true);
    try {
      const payload = {
        adminRole: localDb.getVal("adminRole"),
        email: email,
      };
  
      const res = await call({
        ...GET_CARD_ACQUIRE_ROUTING,
        url: base_url + GET_CARD_ACQUIRE_ROUTING.url,
      }, payload);
  
      if (res.data) {
        setAcquireRoutings(res.data);
        res.data.forEach((routing) => {
          if (routing._id === routingId) {
            setSelectedAcquireRouting(routing);
          }
        });
      setLoading(false);
      }
    } catch (err) {
      console.error(err.message, "err");
    }
    setLoading(false);
  };
  

  const handleUpdate = () => {
    let payload = {
      adminRole: localDb.getVal("adminRole"),
      paymentProviderId: props.paymentProviderId,
      email: email,
      providerEmail: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      state: data.state,
      country: data.country,
      postcode: data.postcode,
      phoneNo: data.phoneNo,
      domainNameUrl: data.domainNameUrl,
      routingServiceId:
        selectedAcquireRouting &&
        selectedAcquireRouting._id &&
        selectedAcquireRouting._id,
      card: data.card === "Enabled" ? true : false,
    };
    updateProvider(payload);
  };

  const getPaymentProviderDetails = async () => {
    setLoading(true);
    try {
      const response = await call({
        ...GET_PAYMENT_PROVIDER,
        url: base_url + GET_PAYMENT_PROVIDER.url,
      }, {
        adminRole: localDb.getVal("adminRole"),
        email: email,
        paymentProviderId: props.paymentProviderId,
      });
  
      const data = response.data;
  
      if (response.status === 200 && data) {
        setData({
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          state: data.state,
          country: data.country,
          postcode: data.postcode,
          phoneNo: data.phoneNo,
          domainNameUrl: data.domainNameUrl,
          logo: data.logo,
          backgroundImage: data.backgroundImage,
          routingServiceId: data.cardAcquireRouting,
          card: data.card ? "Enabled" : "Disabled",
        });
  
        await getAcquireRouting(data.cardAcquireRouting);
  
        if (data.domains && data.domains.length > 0) {
          setDomains(data.domains);
        }
  
        setLoading(false);
      } else {
        alert("Payment Provider Details Not Found!");
      }
    } catch (err) {
      setLoading(false);
      console.log(err.message, "err");
    }
  };
  const updateProvider = async (payload) => {
    setLoading(true);
    try {
      const res = await call({
        ...UPDATE_PAYMENT_PROVIDER,
        url: base_url + UPDATE_PAYMENT_PROVIDER.url,
      }, payload);
  
      if (res.status === 200) {
        props.handleClose();
        props.getProviders();
        props.setAlertMsg("Payment Provider updated successfully.");
        props.setUpdateSuccessAlert(true);
        setLoading(false);
        setTimeout(() => {
          props.setUpdateSuccessAlert(false);
        }, 3000);
      }
    } catch (err) {
      setLoading(false);
      console.log(err.message, "err");
    }
  };
  

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleDomainDelete = async () => {
    const payload = {
      adminRole: localDb.getVal("adminRole"),
      email: email,
      domainNameUrl: domainToDelete && domainToDelete.domainNameUrl,
      paymentProviderId: props.paymentProviderId,
    };
  
    setLoading(true);
  
    try {
      const res = await call({
        ...DELETE_PAYMENT_PROVIDER_DOMAIN,
        url: base_url + DELETE_PAYMENT_PROVIDER_DOMAIN.url,
      }, payload);
  
      if (res.status === 200) {
        setLoading(false);
        await getPaymentProviderDetails();
        setDomainToDelete(null);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  

  let disabled =
    data.email.length < 5 ||
    data.firstName.length < 3 ||
    data.firstName.length < 3;
  return (
    <div>
      <div className="create_account_container">
        <div className="create_account_header">
          <div className="create_account_heading">{`Update Provider`}</div>
          <div
            className="filter_close_button"
            onClick={() => {
              props.handleClose();
            }}
          >
            <img src={`${image_base_url}${closeImage}`} alt="closeImage" />
          </div>
        </div>

        <div className="create_account_form">
          <div className="d-flex justify-content-space-between">
            <div>
              <label>
                <b>{"First Name"}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="firstName"
                value={data["firstName"]}
                placeholder="First name."
                onChange={(e) => handleChange(e)}
                required
              />
            </div>

            <div className="pl-3">
              <label>
                <b>{"Last Name"}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="lastName"
                value={data["lastName"]}
                placeholder="Last name"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
          </div>

          <div className="mt-4">
            <label>
              <b>{"Email"}</b>
            </label>
            <input
              className="create_account_input"
              type="email"
              name="email"
              value={data["email"]}
              placeholder="Email"
              onChange={(e) => handleChange(e)}
              required
            />
          </div>

          <div className="mt-4">
            <label>
              <b>{"Domain Name Url"}</b>
            </label>
            <input
              className="create_account_input"
              type="text"
              name="domainNameUrl"
              value={data["domainNameUrl"]}
              placeholder="Domain Name Url"
              onChange={(e) => handleChange(e)}
              style={{ borderRadius: "25px" }}
            />
          </div>
          <div className="FilterModalInputGroup mt-4">
            <label className="w-100">
              <b>{"Acquire Routing Service"}</b>
            </label>
            <div
              className="FilterModalInputDropdown"
              onClick={() => {
                setShowStatusDropDown(true);
              }}
            >
              <div className="FilterModalInputText">
                {selectedAcquireRouting
                  ? selectedAcquireRouting.acquireRoutingServiceName
                  : ""}
              </div>
              <i
                className="fa fa-chevron-down FilterModalInputDropIcon"
                data-e2e="dropDownExpand"
              />
            </div>
            {showStatusDropDown && (
              <div
                className="FilterModalCurrencyDropdownlist"
                data-e2e="FilterModalCurrencyDropdownlist"
              >
                <div
                  className="FM_currencyDrop_All"
                  onClick={() => {
                    setShowStatusDropDown(false);
                  }}
                >
                  <span
                    className="FM_currencyDrop_AllSelector"
                    data-e2e="status"
                  ></span>
                  <span className="FilterModalInputDropUpIconWrap">
                    <i
                      data-e2e="dropDown"
                      className="fa fa-chevron-up FilterModalInputDropUpIcon"
                    />
                  </span>
                </div>
                {acquireRoutings &&
                  acquireRoutings.length > 0 &&
                  acquireRoutings.map((ele, index) => {
                    return (
                      <div
                        key={index}
                        className="currencyListItemWrap"
                        name="blockchain"
                        data-e2e={ele.acquireRoutingServiceName}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowStatusDropDown(false);
                          setSelectedAcquireRouting(ele);
                        }}
                      >
                        <div className="currencyListIconNameWrap">
                          <div
                            className="currencyList_Name"
                            name="acquireRoutingServiceName"
                            data-e2e={ele.acquireRoutingServiceName}
                          >
                            {ele.acquireRoutingServiceName}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div className="mt-4">
            <label>
              <b>{"Phone"}</b>
            </label>
            <input
              className="create_account_input"
              type="text"
              name="phoneNo"
              value={data["phoneNo"]}
              placeholder="Phone"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="FilterModalInputGroup mt-4">
            <label className="w-100">
              <b>Card</b>
            </label>
            <div
              className="FilterModalInputDropdown"
              onClick={() => {
                setCardDropDown(true);
              }}
            >
              <div className="FilterModalInputText">{data.card}</div>
              <i className="fa fa-chevron-down FilterModalInputDropIcon" />
            </div>
            {cardDropDown && (
              <div
                className="FilterModalCurrencyDropdownlist"
                data-e2e="FilterModalCurrencyDropdownlist"
              >
                <div
                  className="FM_currencyDrop_All"
                  onClick={() => {
                    setCardDropDown(false);
                  }}
                >
                  <span
                    className="FM_currencyDrop_AllSelector"
                    data-e2e="status"
                  >
                    {data.card}
                  </span>
                  <span className="FilterModalInputDropUpIconWrap">
                    <i
                      data-e2e="dropDown"
                      className="fa fa-chevron-up FilterModalInputDropUpIcon"
                    />
                  </span>
                </div>
                {["Enabled", "Disabled"].map((status, index) => {
                  return (
                    <div
                      key={index}
                      className="currencyListItemWrap"
                      name="blockchain"
                      onClick={(e) => {
                        e.preventDefault();
                        setData({ ...data, card: status });
                        setCardDropDown(false);
                      }}
                    >
                      <div className="currencyListIconNameWrap">
                        <div
                          className="currencyList_Name"
                          name="acquireRoutingServiceName"
                        >
                          {status}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="d-flex justify-content-space-between mt-4">
            <div>
              <label>
                <b>{"Address 1"}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="address1"
                value={data["address1"]}
                placeholder="Address 1"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>

            <div className="pl-3">
              <label>
                <b>{"Address 1"}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="address2"
                value={data["address2"]}
                placeholder="Address2"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
          </div>

          <div className="d-flex justify-content-space-between mt-4">
            <div>
              <label>
                <b>{"State"}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="state"
                value={data["state"]}
                placeholder="State"
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="pl-3">
              <label>
                <b>{"City"}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="city"
                value={data["city"]}
                placeholder="City"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>

          <div className="d-flex justify-content-space-between mt-4">
            <div>
              <label>
                <b>{"Country"}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="country"
                value={data["country"]}
                placeholder="Country"
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="pl-3">
              <label>
                <b>{"Post code"}</b>
              </label>
              <input
                className="create_account_input"
                type="text"
                name="postcode"
                value={data["postcode"]}
                placeholder="Postcode"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="multiDomainContainer">
            <div className="FilterModalInputLabel">All Domains</div>
            <ul className="my-1 pl-0 mr-5">
              {domains.map((domain, i) => {
                return (
                  <div key={i} className="domainListItem">
                    <li className="text-md font-md  my-2" key={domain._id}>
                      {domain.domainNameUrl}
                    </li>
                    <div className="table_item merchant_table_font text-black">
                    <EditIcon
                        fontSize="large"
                        color="action"
                        className="edit-icon-color cursor-pointer pr-3"
                        onClick={() => {
                            setEditDomainDialog(true);
                            setSelectedEditDomain(domain);
                          }}
                    />
                    <span
                        className="delete-icon-color cursor-pointer"
                    >
                        <CloseIcon
                        onClick={() => {
                          setDomainToDelete(domain)
                          }}
                        fontSize="small"
                        />
                    </span>
                    </div>
                  </div>
                );
              })}
            </ul>
            <button
              className="AddNewDomainBtn"
              onClick={() => setAddNewDomainDialog(true)}
            >
              Add New Domain
            </button>
            <Dialog
              open={addNewDomainDialog || editDomainDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <EditDomain
                setAlertMsg={props.setAlertMsg}
                setDomains={setDomains}
                setUpdateSuccessAlert={props.setUpdateSuccessAlert}
                paymentProviderId={props.paymentProviderId}
                handleClose={() => {
                  setAddNewDomainDialog(false);
                  setEditDomainDialog(false);
                  setSelectedEditDomain(null);
                }}
                dialogtitle={
                  selectedEditDomain !== null ? "Edit Domain" : "Add Domain"
                } // don't change name here check component before doing so
                domainData={
                  selectedEditDomain !== null ? selectedEditDomain : null
                }
              />
            </Dialog>
          </div>

          <div className="login_button_get_started">
            <button
              className={disabled ? "bg-secondary" : ""}
              disabled={disabled}
              onClick={() => handleUpdate()}
            >
              {`Update`}
            </button>
          </div>
        </div>
      </div>
      <DeletingAlert
        deletingId={
          domainToDelete
        }
        onHide={() => {
          setDomainToDelete(null)
        
        }}
        image_base_url={image_base_url}
        msg="Are you sure, you want to delete this domain?"
        onDeleteClickHandler={() => {
          handleDomainDelete()
        }}
      />
      {loading && <Loading />}
    </div>
  );
};

export default UpdatePaymentProvider;

const DeletingAlert = (props) => {
  return (
    <div className="merchant_dailog_screen">
      <Dialog
        open={props.deletingId ? true : false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
      >
        <div>
          <Modal
            show={true}
            className="ClearAlertModal deleteAlertModal ClearAlertModals"
            onHide={() => {
              props.onHide();
            }}
          >
            <Modal.Body className="ClearAlertModalBody">
              <div className="ClearAlertModalIconWrap">
                <img
                  src={`${props.image_base_url}${deleteIcon}`}
                  alt="resetIcon"
                  className="ClearAlertModalIcon"
                />
              </div>
              <div className="ClearAlertModalTitle">Are you sure?</div>
              <div className="ClearAlertModalDescription">{props.msg}</div>
              <div
                className="ClearAlertModalClearBtn ClearAlertModalDeleteBtn"
                style={{ background: "#E02020" }}
                onClick={() => {
                  props.onDeleteClickHandler();
                }}
              >
                Delete
              </div>
              <div
                className="ClearAlertModalGoBackBtn"
                onClick={() => {
                  props.onHide();
                }}
              >
                Go Back
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Dialog>
    </div>
  );
};